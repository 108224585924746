/*TableResult*/
.table-result{
    margin-bottom: 34px;
    .table-result-title{
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #345777;
        line-height: 28px;
        height: 28px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right-down{
            padding-left: 32px;
            background: url("../../assets/downloadIcon.png") left center no-repeat;
            background-size: 24px 24px;
            height: 24px;
            line-height: 24px;
            margin-right: 34px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #1890FF;
            cursor: pointer;
        }
    }
    .table-result-main{
        background: #FFFFFF;
        box-shadow: 0 3px 10px 1px rgba(0,0,0,0.10);
        border-radius: 6px;
        padding: 30px 32px;
    }
}