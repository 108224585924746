@mixin font-base($color:#333333, $size:16px, $lineHeight:24px, $weight:400) {
    font: {
        size: $size;
        family: SourceHanSansCN-Regular,
            SourceHanSansCN;
        weight: $weight;
    }

    color:$color;
    line-height:$lineHeight;
}

@mixin style-base {
    h3 {
        margin: 0;
        padding: 0;
        @include font-base;
    }
}

@mixin title {
    color: #6D6D6D;
    margin-left: 16px;
    margin-bottom: 16px;
}

@mixin model {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 24px 16px;
    margin-bottom: 32px;
}

@mixin table-base {
    table {
        width: 100%;

        thead {
            th {
                @include font-base($size:14px, $lineHeight:21px, $color:#32323D, $weight:600);
                height: 50px;
                background: #F0F4FA;
                text-align: center;
            }
        }

        tbody {
            td {
                @include font-base($size:14px, $lineHeight:21px, $color:#32323D);
                border-bottom: 1px solid #F1F1F3;
                text-align: center;
                height: 49px;

                &.empty {
                    border: none
                }
            }
        }
    }
}

.framework {
    @include style-base();
    @include font-base;

    .framework-leader {
        h3 {
            @include title();
        }

        .framework-leader-tb {
            @include model();
            padding-bottom: 10px;

            &>div {
                @include table-base();

                overflow: auto;

                table {
                    th {
                        min-width: 150px;
                    }
                }
            }
        }
    }

    .framework-stockholder {
        h3 {
            @include title();
        }

        .framework-stockholder-tb {
            @include model();

            padding-bottom: 10px;

            &>div {
                @include table-base();

                overflow: auto;

                table {
                    th {
                        min-width: 150px;
                    }
                }
            }
        }
    }

    .framework-action {
        h3 {
            @include title();
        }

        .framework-action-tb {
            @include model();

            padding-bottom: 10px;

            &>div {
                @include table-base();

                overflow: auto;

                table {
                    th {
                        min-width: 150px;
                    }
                }
            }
        }
    }

    .framework-subcompany {
        h3 {
            @include title();
        }

        .framework-subcompany-tb {
            @include model();

            padding-bottom: 10px;

            &>div {
                @include table-base();

                overflow: auto;

                table {
                    th {
                        min-width: 150px;
                    }
                }
            }
        }
    }

    .framework-investment {
        h3 {
            @include title();
        }

        .framework-investment-tb {
            @include model();

            padding-bottom: 10px;

            &>div {
                @include table-base();

                overflow: auto;

                table {
                    th {
                        min-width: 150px;
                    }
                }
            }
        }
    }
}