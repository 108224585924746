$btnColor: #5b6bae;
$btnHover: #7882aa;

.public-header-title-drawer {
    z-index: 1000;

    .drawer-logo {
        height: 60px;
        border-bottom: 1px solid rgba(237, 237, 237, 1);
        display: flex;
        align-items: center;

        .header-left {
            color: $btnColor;
            cursor: pointer;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

            &:hover {
                color: $btnHover;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
        }
        .center-search-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 33px;
            height: 30px;
            line-height: 30px;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #333333;

            .logo-click {
                margin-right: 14px;
                display: block;
                width: 101px;
                height: 27px;
                background: url("../../assets/phbs.png") left center no-repeat;
                background-size: 101px 27px;
                cursor: pointer;
            }
        }

        // .center-search-logo {
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     // padding-left: 14px;
        //     margin-left: 39.3px;
        //     height: 30px;
        //     // line-height: 30px;
        //     font-family: PingFangSC-Regular;
        //     font-size: 16px;
        //     color: #333333;

        //     // .logo-click {
        //     //     margin-right: 14px;
        //     //     display: block;
        //     //     width: 101px;
        //     //     height: 27px;
        //     //     background: url("../../assets/phbs.png") left center no-repeat;
        //     //     background-size: 101px 27px;
        //     //     cursor: pointer;
        //     // }
        //     .logo-click {
        //         display: block;
        //         margin-right: 8.3px;
        //         // width: 101px;
        //         // height: 27px;
        //         // background: url("../../assets/phbs.png") left center no-repeat;
        //         // background-size: 101px 27px;
        //         cursor: pointer;

        //         .logo-click-icon {
        //             font-size: 24px;
        //             margin-right: 8.3px;
        //             color: #1E6CBA;
        //         }
        //     }
        // }
    }

    .ant-drawer-content-wrapper {

        .ant-drawer-body {
            background-color: #fff;
            padding: 0 10px 0 10px;

            .ant-spin-nested-loading {
                padding: 24px 12px 0 12px;

                .drawer-head {
                    margin-bottom: 18px;

                    .drawer-head-title {
                        font-family: PingFangSC-Regular, PingFangSC;
                        font-weight: 400;
                        color: rgba(51, 51, 51, 1);
                    }

                    .drawer-head-btn {
                        float: right;

                        .btn {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFangSC;
                            font-weight: 400;
                            color: rgba(41, 127, 255, 1);
                            text-decoration: underline;
                        }
                    }
                }

                .drawer-content {
                    font-family: PingFangSC-Regular;
                    font-size: 12px;
                    color: #999999;

                    ul {
                        .drawer-content-item {
                            cursor: pointer;
                            padding-bottom: 18px;

                            .item-title {
                                display: inline-block;
                                width: 303px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            &.curr-page-color {
                                color: #3083FF;
                            }

                            &:hover {
                                color: #3083FF;
                            }

                            .item-icon {
                                padding: 0 5px;
                                font-size: 12px;
                                float: right;
                            }
                        }
                    }
                }
            }

        }
    }
}