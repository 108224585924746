$btnColor: #5b6bae;
$btnHover: #7882aa;

.file-manage {
    min-width: 980px;
    padding: 10px 10px 0;

    .file-manage-btn {
        //margin-bottom: 20px;
        display: flex;
        //margin-left: 11px;
        justify-content: space-between;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: rgba(0, 0, 0, 0.8);
        line-height: 30px;
        margin-bottom: 10px;

        .upload-txt {
            width: 88px;
            padding: 0 10px;
            height: 30px;
            font-size: 12px;
            //line-height: 30px;
            cursor: pointer;
            background: $btnColor;
            color: #fff;
            border-radius: 4px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

            &:hover {
                background: $btnHover;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

        }

        .add-btn-suspend {
            position: fixed;
            z-index: 80;
            top: 64px;
        }
        .add-btn-button {
            height: 30px;
            padding: 0 15px;
            font-size: 12px;
            &[disabled] {
                border-color: rgba(0, 0, 0, 0.3)!important;
                color: rgba(0, 0, 0, 0.3)!important;
                background: transparent!important;
                &:hover{
                    border-color: rgba(0, 0, 0, 0.3);
                    color: rgba(0, 0, 0, 0.3);
                    background: transparent;
                }
            }
        }

        .edit-btn{
            margin: 0 14px;
            >button {
                height: 30px;
                width: 88px;
                border-radius: 4px;
                border: 1px solid #5B6BAE;
                color: #5B6BAE;
                background: transparent;
                &:hover{
                    background: $btnHover;
                    color: #fff;
                    border-color: $btnHover;
                }
                &[disabled] {
                    border-color: rgba(0, 0, 0, 0.3);
                    color: rgba(0, 0, 0, 0.3);
                    background: transparent;
                    &:hover{
                        border-color: rgba(0, 0, 0, 0.3);
                        color: rgba(0, 0, 0, 0.3);
                        background: transparent;
                    }
                }
            }
        }
        .upload-btn{

        }
    }

    .file-manege-main {
        padding: 0 10px 20px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 0 10px 1px rgba(241, 241, 241, 1);
        border-radius: 6px 0 0 0;
        margin-bottom: 40px;
    }

    .file-manage-table {
        .table-action-cell {
            cursor: auto;
            display: flex;
            //justify-content: center;
            //align-items: center;
            flex-wrap: wrap;
        }

        .table-file-action {
            color: #5B6BAE;
            &:not(:first-child) {
                margin-left: 8px;
            }
            &.disabled{
                color: rgba(0, 0, 0, 0.25);
                cursor: not-allowed;
            }
        }

        .ant-radio-group {
            display: flex;

            .ant-radio-wrapper {
                display: flex;
                align-items: center;
                margin-right: 0;
            }

            .ant-radio {
                &.ant-radio-checked {
                    .ant-radio-inner {
                        //border-color: $btnColor;
                        border-color: #fff;
                        background: $btnColor;

                        &:after {
                            background: #fff;
                            width: 4px;
                            height: 4px;
                            top: 3px;
                            left: 3px;
                        }
                    }
                }

                .ant-radio-inner {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .ant-progress {
            >div {
                display: flex;
                align-items: center;
                height: 100%;
            }
        }

        .ant-progress-outer {
            margin-right: calc(-3em - 8px);
            padding-right: calc(3em + 8px);

            .ant-progress-inner {
                border: 1px solid #E6E6E6;
                background: #E6E6E6;
                display: flex;
                align-items: center;
            }
        }

        .ant-progress-text {
            width: 3em;
            color: #333;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        .progress-msg {
            flex-shrink: 0;
        }

        .ant-progress-status-exception .ant-progress-bg {
            background-color: rgb(255, 58, 58) !important;
        }

        .ant-progress-status-exception {
            .ant-progress-text {
                color: rgb(255, 58, 58);
            }
        }
    }
    .table-tag-content{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -8px;
        .table-tag-item{
            display: block;
            padding: 0 8px;
            line-height: 23px;
            background: #EFF2FF;
            border-radius: 2px;
            border: 1px solid #C8D2FB;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: #5B6BAE;
            margin-bottom: 8px;
            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }
}
.upload-modal-confirm{
    .ant-modal-content{
        width: 616px;
    }
    .ant-modal-body{
        background: #FFFFFF;
        box-shadow: 0px 0px 8px 2px rgba(222, 222, 222, 0.5);
        border-radius: 8px;
        padding: 24px 64px;
    }
    .upload-modal-content{
        padding-bottom: 34px;
        .modal-title{
            height: 24px;
            font-size: 18px;
            font-family: MicrosoftYaHei;
            color: rgba(0, 0, 0, 0.8);
            line-height: 24px;
            margin-bottom: 40px;
        }
        .modal-form{

        }
        .file-modal-item{
            display: flex;
            margin-bottom: 16px;
            &.tags-item{
                margin-bottom: 0;
                margin-top: 24px;
                .file-modal-label{
                    line-height: 24px;
                }
            }
        }
        .file-modal-label{
            width: 56px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: rgba(0, 0, 0, 0.6);
            line-height: 30px;
            margin-right: 16px;
            flex-shrink: 0;
        }
        .file-modal-value{
            display: flex;
            width: calc(100% - 72px);
        }
        .tag-delete-icon{
            margin-left: 3px;
            font-size: 8px;
            color: #C8D2FB;
        }
        .file-modal-input{
            height: 30px;
            flex: 1;
            border-radius: 4px;
            &:hover,&:focus {
                border-color: #5B6BAE;
            }
        }
        .file-modal-type{
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: rgba(0, 0, 0, 0.6);
            line-height: 30px;
            margin-left: 16px;
        }
        .modal-tags-list{
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            width: 100%;
        }
        .modal-tags-item{
            //display: flex;
            //flex-wrap: wrap;
            max-width: 100%;
            //align-items: center;
            padding: 0 8px;
            //height: 24px;
            line-height: 22px;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: #5B6BAE;
            background: #EFF2FF;
            border-radius: 2px;
            border: 1px solid #C8D2FB;
            margin-bottom: 8px;
            margin-right: 8px;
            &.add-item {
                margin-right: 0;
                border-radius: 2px;
                border: 1px dashed #D9D9D9;
                background: #fff;
                color: #4A4A4A;
                width: 61px;
                cursor: pointer;
                &.add-item-input {
                    padding: 0;
                    border: none;
                }
                &:hover{
                    color: #5B6BAE;
                    border-color: #5B6BAE;
                }
            }
        }
        .add-tag-input {
            min-width: 61px;
            height: 24px;
            padding: 0 4px;
            font-size: 12px;
            &:hover,&:focus {
                border-color: #5B6BAE;
            }
        }
        .add-tag-icon {

        }
        .add-icon{
            margin-right: 4px;
        }
    }
    .ant-modal-confirm-btns{
        margin-top: 30px;
        .ant-btn{
            width: 88px;
            height: 30px;
            border-color: #5B6BAE;
            &:hover{
                border-color: #5B6BAE;
                color: #5B6BAE;
            }
            &.ant-btn-primary{
                background: #5B6BAE;
                border-color: #5B6BAE;
                color: #fff;
                margin-left: 16px;
                &:hover{
                    background: $btnHover;
                    border-color: $btnHover;
                }
            }
        }
    }
    .upload-modal-scroll{
        max-height: 560px;
    }
}

#root .file-manage-table .ant-table-body .ant-table-thead tr th {
    padding: 15px 10px;
}
#root .file-manage-table .ant-table-body .ant-table-tbody tr td {
    padding: 10px 10px;
}
