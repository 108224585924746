$btnColor: #5b6bae;
$btnHover: #7882aa;

.finc-sublibrary-page {
  padding: 16px 16px 16px 6px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;

  .finc-sublibrary-head {
    display: flex;
    justify-content: space-between;

    .finc-sublibrary-filter {
      display: flex;

      .finc-sublibrary-filter-item {
        & > span {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: rgba(0, 0, 0, 0.8);
          margin-right: 10px;
        }

        &.finc-sublibrary-filter-time {
          margin-left: 20px;
        }
      }
    }
  }

  .finance-list {
    padding: 8px 0 15px;

    .table-outline-name {
      width: 130px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .finance-table {
      background-color: #ffffff;
      padding: 10px;

      .ant-table-body {
        .ant-table-thead {
          tr {
            th {
              font-family: PingFang-SC-Medium;
              font-size: 16px;
              color: #333333;
              background: #fff;
              border: none;
            }
          }
        }

        .ant-table-tbody {
          tr {
            td {
              border-bottom: none;
              font-family: PingFangSC-Regular;
              font-size: 14px !important;
              color: #333333 !important;
            }

            &:hover {
              td {
                background: $btnColor !important;
                color: #fff !important;

                div,
                span {
                  color: #fff !important;
                }
              }
            }

            &.single-row {
              td {
                background: #edf5ff !important;
              }

              &:hover {
                td {
                  background: $btnColor !important;
                  color: #fff !important;
                }
              }
            }
          }
        }
      }

      .ant-pagination {
        float: right !important;
      }
    }
  }

  .list-detail {
    .finc-sublibrary-card {
      height: 220px;
      background: #fff;
      margin: 20px 0;
      padding: 30px;
      box-sizing: border-box;
      display: flex;

      .card-img {
        width: 121px;
        height: 121px;
        border-radius: 4px;
        border: 1px solid #d9d9d9;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .card-info {
        margin-left: 40px;
        line-height: 30px;
        font-size: 12px;
        .card-title {
          font-weight: bold;
          font-size: 14px;
        }
      }
    }

    .finc-sublibrary-tabs {
      .ant-tabs-nav-wrap {
        background: #f5f7fa;
        border: 1px solid #e2e2e2;

        .ant-tabs-tab {
          border: none;
          background: #f5f7fa;

          &.ant-tabs-tab-active {
            background-color: #fff;
          }
        }
      }

      .choose-year {
        font-size: 12px;

        .year {
          margin-right: 24px;
          cursor: pointer;
          &.active {
            color: #3f7ef3;
          }
        }

        &::after {
          content: '';
          display: block;
          background-color: #5b6bae;
          width: 123px;
          height: 3px;
          margin-top: 14px;
          margin-bottom: 14px;
        }
      }
    }
  }
}
