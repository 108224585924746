.search-rightlist {
  position: relative;
  width: 490px;
  height: 670px;
  #col-l {
    width: 490px;
    height: 670px;
    position: absolute;
    left: 0;
    top: 35px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: 3px 3px 3px #ededed;
    z-index: 99;
    display: flex;
    flex-direction: column;
    .cut {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    /* 聊天区域 */
    .col-l-t {
      flex: 1;
      overflow: auto;
      .content {
        padding: 14px;
        .bubble {
          position: relative;
          padding-bottom: 50px;
        }
      }
    }
    /* 发送区域 */
    .col-l-b {
      height: 58px;
      .faq-input-content {
        border-radius: 10px;
        background-color: #fff;
        position: relative;
        .input {
          border-style: none;
          font-size: 16px;
          width: 96%;
          overflow: hidden;
          line-height: 20px;
          margin: 10px;
          border-radius: 5px;
          resize: none;
          .ant-input {
            height: 40px;
            line-height: 40px;
            border-radius: 8px;
            background-color: #f3f4f6;
          }
          .send-img {
            cursor: pointer;
          }
        }
        .mbtn {
          line-height: 35px;
          width: 280px;
          position: absolute;
          left: 70px;
          border-style: none;
          font-size: 16px;
          color: #dfdfdf;
          bottom: 10px;
          border-radius: 4px;
          cursor: pointer;
          background: #3151ff;
          &:hover {
            color: #dfdfdf;
          }
        }
        .cite {
          background-color: #fff;
          padding: 0px 15px 0 8px;
          border-radius: 5px;
          font-size: 12px;
          border: 1px solid #f1f1f1;
          position: absolute;
          top: -20px;
          left: 10px;
          span {
            display: inline-block;
          }
          .close-btn {
            font-style: normal;
            font-size: 12px;
            background-color: #ced3de;
            position: absolute;
            right: -7px;
            border-radius: 100%;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            color: #fff;
            cursor: pointer;
          }
        }
      }
      .input-count {
        color: #666;
        padding-left: 5px;
      }
    }
  }

  .clearfix {
    zoom: 1;
    display: block;
  }
  .msg {
    margin-top: 20px;
    position: relative;
    overflow: hidden;
    .avatar {
      float: left;
      margin-right: 6px;
    }
    .robot-name {
      font-size: 10px;
      line-height: 15px;
    }
    .article {
      float: left;
      display: inline-block;
      zoom: 1;
      padding: 12px;
      // border-radius: 12px;
      border-radius: 0px 8px 8px 8px;
      border: 1px solid #f1f1f1;
      min-width: 50px;
      min-height: 20px;
      background: #fff;
      max-width: 87%;
      margin-top: 8px;
      // margin-left: 10px;
      font-size: 14px;
      .btn-container {
        margin-top: 12px;
      }
      .chat-btn {
        width: 60px;
        text-align: center;
        background: #5b6baf;
        border-radius: 4px;
        display: inline-block;
        padding: 5px 8px;
        margin: 0 10px;
        cursor: pointer;
        color: #fff;
      }
    }
    &.fr {
      float: none;
    }
    &.fr .article {
      margin-top: auto;
      margin-bottom: 8px;
      color: #242527;
      background: #e5e7eb;
      border-radius: 8px 0px 8px 8px;
      float: right;
      margin-right: 10px;
    }
    &.fr .quote {
      visibility: hidden;
      bottom: 12px;
      right: 17px;
      font-size: 12px;
      position: absolute;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &.fr:hover .article {
      padding-right: 40px;
    }
    &.fr:hover .quote {
      visibility: visible;
    }
  }
  .robot {
    color: #161717;
    .time {
      float: left;
      margin-left: 34px;
      margin-top: 4px;
      color: #90959d;
      line-height: 15px;
      font-size: 10px;
    }
    .article {
      width: 332px;
      line-height: 21px;
    }
    .init-question {
      .title {
        line-height: 29px;
      }
      .question {
        line-height: 29px;
        margin: 0;
        color: #5b73df;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .add-chat {
    .time {
      text-align: right;
      color: #90959d;
      line-height: 15px;
      font-size: 10px;
      margin-right: 10px;
    }
  }
  .ana-result {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .ana-head {
      width: 100%;
      margin-bottom: 10px;
      font-size: 16px;
      .snote {
        font-size: 16px;
      }
      .sbnt {
        background-color: #c7cede;
        font-size: 16px;
        border-radius: 3px;
        padding: 5px 8px;
        cursor: pointer;
      }
    }
    .ana-item {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 8px 0 8px 8px;
      padding: 12px 18px;
      border: 1px solid #f1f1f1;
    }
    .ana-content {
      width: 100%;
      font-size: 16px;
      display: flex;
      .ana-list {
        font-size: 16px;
        margin-right: 30px;
        .tag {
          font-size: 14px;
          line-height: 28px;
          margin-top: 10px;
          display: block;
          text-align: center;
          height: 28px;
        }
        .tag-value {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 12px;
          border: 4px solid #ccc;
          width: 65px;
          height: 65px;
          border-radius: 100%;
          padding: 5px;
          background-color: #e2e6f1;
          &.entity {
            border-color: #3151ff;
          }
          &.attr {
            border-color: #f9945f;
          }
          &.attribute {
            border-color: #f9945f;
          }
        }
      }
    }
  }
  .loading-wrapper {
    text-align: center;
    @keyframes dot {
      50% {
        background-color: #9880ff;
        transform: scale(1);
      }
    }
    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
      transform: scale(0);
      margin: 15px 8px 8px;
      display: inline-block;
      animation: dot 1.5s infinite;
      &:nth-child(2) {
        animation-delay: 0.5s;
      }
      &:nth-child(3) {
        animation-delay: 1s;
      }
    }
  }
  .ana-detail {
    text-align: right;
    color: #3151ff;
    cursor: pointer;
    margin-top: 9px;
    font-size: 13px;
  }
  .ant-table-wrapper {
    margin-top: 10px;
    .ant-table {
      .ant-table-body {
        margin: 0px;
        .ant-table-thead {
          tr th {
            border: 1px solid #f1f1f1 !important;
            padding: 8px !important;
            text-align: center;
          }
        }
        .ant-table-tbody {
          tr td {
            border: 1px solid #f1f1f1;
            padding: 8px !important;
            text-align: center;
          }
        }
      }
    }
  }
}
