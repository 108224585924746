@mixin font-css($type:contentType, $color:#32323D) {
    @if $type==contentType {
        font: {
            size: 14px;
            family: SourceHanSansCN-Regular,
                SourceHanSansCN;
            weight: 400;
        }
    }

    @else if $type==titleType {
        font: {
            size: 14px;
            family: SourceHanSansCN-Medium,
                SourceHanSansCN;
            weight: bold;
        }
    }

    color: $color;
    line-height:21px;
}

@mixin ckblist-item {
    .ant-checkbox {
        font-size: 12px;

        .ant-checkbox-inner {
            width: 12px;
            height: 12px;
            border-color: #5B6BAE;
        }

        &.ant-checkbox-checked {
            .ant-checkbox-inner {

                background-color: #5B6BAE;
            }
        }

        &.ant-checkbox-indeterminate {
            .ant-checkbox-inner::after {
                width: 6px;
                height: 6px;
                background-color: #5B6BAE;
            }
        }
    }
}

@mixin table-head-height {
    height: 65px;
}

@mixin table-content-height {
    height: 50px;
}

@mixin table-width {
    min-width: 100px;
}

.listtb-table-model {

    ul,
    li {
        margin: 0;
        padding: 0;
    }

    .table-model-tb {
        display: flex;
        margin-bottom: 30px;

        .ckblist-item {
            @include ckblist-item;

            &.fixed-head-ckb {

                .ant-checkbox+span {
                    @include font-css(titleType);
                }
            }

            &.fixed-content-ckb {
                padding-left: 10px;

                .ant-checkbox+span {
                    @include font-css(contentType);
                }
            }
        }

        .table-head {
            background-color: #F8F8F8;

            &>ul {
                background-color: #F8F8F8;
                display: flex;
                height: 60px;

                li {
                    @include font-css(titleType);
                    flex-shrink: 0;
                    line-height: 62px;
                    border-top: 1px solid #F1F1F3;
                    border-left: 1px solid #F1F1F3;

                    &:last-child {
                        border-right: 1px solid #F1F1F3;
                    }
                }
            }

            &.table-sliped-head {
                overflow: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            &.table-fixed-head {

                li:nth-child(1) {
                    padding-left: 10px;
                }

                li:first-child {
                    border-right: none;
                }
            }

            &.fixed {
                position: absolute;
                z-index: 99;
            }
        }

        .table-content {
            ul.table-row {
                display: flex;
                min-height: 50px;

                &.hover {
                    background-color: #E1E7FF;
                }

                li {
                    @include font-css(contentType);
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    border-top: 1px solid #F1F1F3;
                    border-left: 1px solid #F1F1F3;

                    &:last-child {
                        border-right: 1px solid #F1F1F3;
                    }

                }

                &:last-child {
                    li {
                        border-bottom: 1px solid #F1F1F3;
                    }
                }
            }

            &.table-sliped-content {
                overflow: auto hidden;
            }
        }

        .table-sliped {
            position: relative;
            overflow: hidden;

            .sliped-arrow {
                width: 30px;
                height: 60px;
                background: rgba(216, 216, 216, 0.8);
                position: absolute;
                text-align: center;
                line-height: 60px;
                cursor: pointer;

                &.arrow-left {
                    left: 0;
                }

                &.arrow-right {
                    right: 0;
                }

                .sliped-icon {
                    font-size: 20px;
                    color: #FFFFFF;
                }

                &.fixed {
                    position: absolute;
                    z-index: 100;
                }
            }
        }
    }
}