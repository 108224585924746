.examples-display {
    margin-top: 32px;

    .examples-btngroup {
        text-align: right;
        margin-bottom: 19px;

        .examples-btn {
            width: 104px;
            height: 32px;
            border-radius: 2px;
            margin-right: 24px;

            &:last-child {
                margin-right: 0;
            }

            &.update {
                background: none;
                border: 1px solid rgba(91, 107, 174, 1);
                color: rgba(91, 107, 174, 1);

                &:hover {
                    border: 1px solid rgba(91, 107, 174, .7);
                    color: rgba(91, 107, 174, .7);
                }
            }
        }
    }

    .examples-display-table {
        .ant-table-thead {
            tr {
                th {
                    margin: 0 !important;
                    text-align: center;
                    background: rgba(240, 244, 250, 1) !important;
                }
            }

            tr:first-child {
                th {
                    padding: 5px 0 0 !important;

                    span {
                        font-size: 14px;
                        font-family: MicrosoftYaHei;
                        color: rgba(51, 51, 51, 1);
                    }
                }
            }

            tr:last-child {
                th {
                    padding: 0 0 5px !important;

                    span {
                        font-size: 12px;
                        font-family: ArialMT;
                        color: rgba(51, 51, 51, 1);
                    }
                }
            }
        }

        .ant-table-tbody {
            td {
                font-size: 12px !important;
                font-family: MicrosoftYaHei !important;
                color: rgba(0, 0, 0, 0.6) !important;

                .examples-digest {
                    text-overflow: ellipsis;
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 500px;
                    margin: 0 auto;

                    &.link {
                        max-width: 350px;
                    }
                }
            }

            .plan-tools {
                color: rgba(101, 116, 179, 1) !important;
                margin-right: 16px;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}

.example-modal {
    width: 600px !important;

    .example-modal-digest {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: rgba(0, 0, 0, 0.8);
        line-height: 21px;
    }

    .example-modal-form {
        .example-modal-item {
            .ant-form-item-label {
                &>label {
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: rgba(0, 0, 0, 0.5);
                }
            }

            &:last-child {
                margin-bottom: 0;

                .ant-form-item-label {
                    &>label {
                        margin-top: -6px;
                        display: block;
                    }
                }
            }
        }
    }

    .ant-modal-footer {
        border: none;
        padding-right: 24px;
        padding-bottom: 24px;

        .example-modal-btn {
            min-width: 64px;
            height: 32px;
            border-radius: 2px;

            &.cancel {
                background: none;
                border: 1px solid rgba(184, 184, 184, 1);
                margin-right: 16px;
                color: rgba(51, 51, 51, 1);

                &:hover {
                    background: rgba($color: #000, $alpha: .05);
                    color: rgba(51, 51, 51, .7);
                }
            }

            &.copy {
                background: rgba(91, 107, 174, 1);
                border: none;
                color: rgba(216, 216, 216, 1);

                &:hover {
                    background: rgba(91, 107, 174, .8);
                }
            }

        }

    }

}