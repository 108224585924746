.markmap-node {
  cursor: pointer;
  /*&.relation-node{
      cursor: alias;
  }*/
}

.cursor-status .markmap-node {
  cursor: copy;
  //cursor: url("downloadIcon.png"),auto;
}

.markmap-node-circle {
  fill: #fff;
  stroke-width: 1.5px;
}

.markmap-node-text {
  //fill:  #000;
  font: 10px sans-serif;
}

.markmap-link {
  fill: none;
}

@keyframes fillColor {
  0% {
    fill: #fff;
  }
  50% {
    fill: #929292;
  }
  100% {
    fill: #fff;
  }
}

#mindmap {
  .markmap-node-circle.circle-node-loading {
    //fill: red;
    animation: fillColor .8s infinite;
  }
}


.result-search__graph__left {
  position: relative;
}

.mindmap {
  position: relative;
}

.result-search__mind {
  .ant-empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .d3-force-hover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    border-style: solid;
    white-space: nowrap;
    z-index: 99;
    transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    background-color: rgba(50, 50, 50, 0.7);
    border-width: 0px;
    border-color: rgb(51, 51, 51);
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font: 14px / 21px "Microsoft YaHei";
    padding: 5px;
  }
}