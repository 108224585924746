.lesson-plan {
    margin: 24px 24px 0;
    padding: 16px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 1px rgba(237, 237, 237, 1);
    border-radius: 4px;

    .lesson-plan-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 7px;
        margin-bottom: 24px;

        .plan-head-title {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: rgba(0, 0, 0, 0.8);
            font-weight: 600;
            margin: 0;
            padding: 0;
        }

        .plan-head-btngroup {
            display: flex;

            .plan-head-btn {
                width: 104px;
                height: 30px;
                font-size: 12px;
                font-family: MicrosoftYaHei;
                border-radius: 2px;

                &.import {
                    border: 1px solid rgba(91, 107, 174, 1);
                    color: rgba(91, 107, 174, 1);
                    margin-right: 16px;

                    &:hover {
                        border: 1px solid rgba(91, 107, 174, .7);
                        color: rgba(91, 107, 174, .7);
                    }
                }
            }
        }
    }

    .lesson-plan-content {
        .plan-table {
            .ant-table-thead {
                tr {
                    th {
                        margin: 0 !important;
                        text-align: center;
                        background: rgba(240, 244, 250, 1) !important;
                        font-weight: 600;
                    }
                }

                tr:first-child {
                    th {
                        padding: 5px 0 0 !important;

                        span {
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: rgba(51, 51, 51, 1);
                        }
                    }
                }

                tr:last-child {
                    th {
                        padding: 0 0 5px !important;

                        span {
                            font-size: 12px;
                            font-family: ArialMT;
                            color: rgba(51, 51, 51, 1);
                        }
                    }
                }
            }

            .ant-table-tbody {
                td {
                    font-size: 12px !important;
                    font-family: MicrosoftYaHei !important;
                    color: rgba(0, 0, 0, 0.6) !important;
                }

                .plan-tools {
                    color: rgba(101, 116, 179, 1) !important;
                    margin-right: 16px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}