.card{
    min-width: 960px;
    >ul{
        height: 33px;
        display: flex;
        margin-bottom: 0;
        align-items: center;
        li{
            width: 96px;
            height: 33px;
            line-height: 33px;
            text-align: center;
            margin-right: 30px;
            background: #E9EBED;
            box-shadow: 2px -2px 10px 1px rgba(136,136,136,0.05);
            border-radius: 6px 6px 0 0;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #333333;
            overflow: hidden;
            cursor: pointer;
            &.select-card-list{
                background: #FFFFFF;
                box-shadow: 2px -2px 10px 1px rgba(0,0,0,0.05);
                position: relative;
                &:before{
                    display: block;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #1890FF;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}