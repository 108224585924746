.profit-cost-spend{
    width: 100%;
    .total-view-box{
        padding: 30px;
        min-width: 800px;
        background: #FFFFFF;
        box-shadow: 0 3px 10px 1px rgba(0,0,0,0.10);
        border-radius: 0 6px 6px 6px;
        margin-bottom: 34px;
        position: relative;
    }
    .total-title{
        height: 32px;
        line-height: 32px;
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .company-name{

    }
    .company-allName{
        display: inline-block;
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #333333;
    }
    .company-engName{
        display: inline-block;
        margin-left: 10px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0,0,0,0.65);
    }
    .search-time{

    }
    .search-time-label{
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0,0,0,0.85);
    }
    .graph-content{

    }
    .graph-top-card{
        >span{
            display: block;
            height: 33px;
            line-height: 33px;
            width: 96px;
            background: #1890FF;
            border-radius: 6px 6px 0 0;
            text-align: center;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
    .graph-content-box{
        min-height: 400px;
        padding: 60px 18px 30px;
        background: #FFFFFF;
        border: 1px solid #488BFF;
        box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
        border-radius: 0 3px 3px 3px;
    }


    .total-view-table{

    }
}