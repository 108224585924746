.business-detail {
  padding: 16px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  .business-detail-content {
    .business-block {
      margin-top: 20px;
      h4 {
        color: #5b6bae;
        font-size: 18px;
        font-weight: 500;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .basic-info-content {
      display: flex;
      padding: 30px;
      background-color: #fff;
      .ant-avatar {
        flex-shrink: 0;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        width: 80px;
        height: 80px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .basic-info-text {
        margin-left: 30px;
        .ant-descriptions-title {
          color: #333;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 5px;
        }
        .ant-descriptions-row {
          display: flex;
          justify-content: flex-start;
        }
        .ant-descriptions-row > td {
          color: #333;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0px;
          padding-bottom: 4px;
          min-width: 300px;
        }
      }
    }
    .ant-table-body {
      .ant-table-thead {
        tr {
          th {
            font-family: PingFang-SC-Medium;
            font-size: 14px !important;
            color: #333333;
            background: #f5f7fa !important;
            border-right: 1px solid #e2e2e2 !important;
            border-bottom: 1px solid #e2e2e2 !important;
            padding: 10px 16px !important;
            text-align: center;
          }
        }
      }
      .ant-table-tbody {
        background-color: #fff !important;
        tr {
          td {
            border-bottom: 1px solid #e2e2e2 !important;
            font-family: PingFangSC-Regular;
            font-size: 14px !important;
            color: #333333 !important;
            padding: 8px 16px !important;
            white-space: pre-line;
            word-wrap: break-word;
          }

          &:hover {
            td {
              color: #535561 !important;
              background: unset !important;
              div,
              span {
                color: #535561 !important;
              }
            }
          }

          &.single-row {
            td {
              background: unset !important;
            }

            &:hover {
              td {
                background: unset !important;
              }
            }
          }
        }
      }
    }
    .more-text:before {
      content: '... ';
      display: inline-block;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
    }
    .more-text:after {
      color: #0084ff;
      content: '更多';
      display: inline-block;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
    }
  }
}
