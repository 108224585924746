body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.w10 {
  width: 10px;
}
.w20 {
  width: 20px;
}
.w30 {
  width: 30px;
}
.w40 {
  width: 40px !important;
}
.w50 {
  width: 50px !important;
}
.w60 {
  width: 60px !important;
}
.w65 {
  width: 65px !important;
}
.w70 {
  width: 70px !important;
}
.w80 {
  width: 80px !important;
}
.w90 {
  width: 90px;
}
.w100 {
  width: 100px !important;
}
.w110 {
  width: 110px !important;
}
.w120 {
  width: 120px !important;
}
.w130 {
  width: 130px !important;
}
.w150 {
  width: 150px !important;
}
.w160 {
  width: 160px;
}
.w180 {
  width: 180px;
}
.w200 {
  width: 200px !important;
}
.w210 {
  width: 210px !important;
}
.w230 {
  width: 230px;
}
.w240 {
  width: 240px;
}
.w250 {
  width: 250px !important;
}
.w270 {
  width: 270px;
}
.w300 {
  width: 300px !important;
}
.w350 {
  width: 350px;
}
.w340 {
  width: 340px;
}
.w400 {
  width: 400px !important;
}
.w450 {
  width: 450px !important;
}
.w500 {
  width: 500px;
}
.w600 {
  width: 600px !important;
}
.w700 {
  width: 700px;
}
.w780 {
  width: 780px;
}
.w800 {
  width: 800px;
}
.m0 {
  margin: 0 !important;
}
.m10 {
  margin: 10px;
}
.m15 {
  margin: 15px !important;
}
.m30 {
  margin: 30px;
}
.mt0 {
  margin-top: 0px !important;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt100 {
  margin-top: 100px;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px;
}
.mb100 {
  margin-bottom: 100px;
}
.ml5 {
  margin-left: 5px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml30 {
  margin-left: 30px;
}
.ml50 {
  margin-left: 50px;
}
.ml100 {
  margin-left: 100px !important;
}
.ml200 {
  margin-left: 200px !important;
}
.mr2 {
  margin-right: 2px !important;
}
.mr3 {
  margin-right: 3px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px !important;
}
.mr50 {
  margin-right: 50px !important;
}
.mr100 {
  margin-right: 100px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p30 {
  padding: 30px;
}
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pt50 {
  padding-top: 50px;
}
.pt100 {
  padding-top: 100px;
}
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb30 {
  padding-bottom: 30px;
}
.pb50 {
  padding-bottom: 50px;
}
.pb100 {
  padding-bottom: 100px;
}
.pl5 {
  padding-left: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pl15 {
  padding-left: 15px;
}
.pl20 {
  padding-left: 20px;
}
.pl30 {
  padding-left: 30px;
}
.pl50 {
  padding-left: 50px;
}
.pl100 {
  padding-left: 100px;
}
.pr5 {
  padding-right: 5px;
}
.pr10 {
  padding-right: 10px;
}
.pr15 {
  padding-right: 15px;
}
.pr20 {
  padding-right: 20px;
}
.pr30 {
  padding-right: 30px;
}
.pr50 {
  padding-right: 50px;
}
.pr100 {
  padding-right: 100px;
}
.relative{
	position: relative;
}

.modal-wrapper .ant-modal{
    top: 64px;
}
