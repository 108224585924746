.company-info{
    display: flex;
    padding: 10px 0 0 10px;
    .left-menu{
        width: 140px;
        flex-shrink: 0;
        margin-right: 20px;
    }
    .ant-anchor{
        padding: 32px 0;
        .ant-anchor-ink{
            display: none;
        }
        .left-menu-item{
            padding: 0;
            text-align: center;
            height: 20px;
            line-height: 20px;
            font-size:14px;
            font-family:PingFangSC-Regular,PingFangSC;
            font-weight:400;
            color:rgba(51,51,51,1);
            margin-bottom: 29px;
            &:last-child{
                margin-bottom: 0;
            }
            >a{
                &:hover{
                    text-decoration: none;
                    color: #5B6BAE;
                }
                &:active,&:focus{
                    text-decoration: none;
                }
            }
            &.ant-anchor-link-active{
                >a{
                    color: #5B6BAE;
                }
            }
        }
    }
    .right-info{
        flex: 1;
        min-width: 1000px;
        .ant-empty{
            padding: 20px 0;
        }
    }
    .info-item{
        margin-bottom: 30px;
    }
    .info-item-title{
        height:20px;
        font-size:14px;
        font-family:PingFangSC-Regular,PingFangSC;
        font-weight:400;
        color:rgba(51,51,51,1);
        line-height:20px;
        margin-top: 0;
        margin-bottom: 16px;
    }
    .info-item-main{
        //min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 1px rgba(237,237,237,1);
    }
    .info-item-list{
        display: flex;
        width: 100%;
        padding: 0 20px;
        .info-item-list__table{
            width: calc(50% - 1px);
            margin-right: 2px;
            //margin-bottom: 40px;
            &:last-child{
                margin-right: 0;
            }
            tr {
                td {
                    font-size:12px;
                    font-family:PingFangSC-Regular,PingFangSC;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    line-height: 17px;
                    padding: 12px 10px 11px;
                    &.table-label{
                        border-right: 1px solid #fff;
                        width: 116px;
                        >span{
                            display: block;
                            align-items: center;
                            max-width: 100px;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                            min-height: 17px;
                            width: 100%;
                        }
                    }
                    &.table-value {
                        >span{
                            display: block;
                            width: 100%;
                            min-height: 17px;
                        }
                    }
                }
                &.double-row{
                    td{
                        background: #F0F4FA;
                    }
                }
            }
        }
    }
}