.example-list {
    min-height: calc(100vh - 109px);
    margin: 24px;
    padding: 16px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 1px rgba(249, 249, 249, 1);
    border-radius: 4px;

    display: flex;
    justify-content: space-between;

    .example-btn {
        min-width: 80px;
        padding: 0 11px;
        height: 28px;
        border-radius: 2px;

        &.panel-inner {
            height: 24px;
        }

        &.back {
            border: 1px solid rgba(102, 118, 183, 1);
            color: rgba(102, 118, 183, 1);
            margin-right: 16px;

            &:hover {
                border: 1px solid rgba(102, 118, 183, .7);
                color: rgba(102, 118, 183, .7);
            }
        }
    }

    .example-left {
        flex: 1;
        min-height: calc(100vh - 140px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .example-left-panel {
            .example-left-panel-icon {
                color: rgba(204, 204, 204, 1);
            }

            .ant-collapse {
                background: none;

                .ant-collapse-item {
                    border: 0;
                    margin-bottom: 23px;

                    .example-left-panel-chk {
                        margin-right: 16px;

                        .ant-checkbox {
                            .ant-checkbox-inner {
                                width: 12px;
                                height: 12px;
                                border-radius: 1px;
                                border-color: rgba(91, 107, 174, 1);
                            }

                            &.ant-checkbox-checked {
                                .ant-checkbox-inner {
                                    background-color: rgba(91, 107, 174, 1);

                                    &::after {
                                        width: 4px;
                                    }
                                }
                            }

                            &.ant-checkbox-indeterminate {
                                .ant-checkbox-inner::after {
                                    background-color: rgba(91, 107, 174, 1);
                                }
                            }
                        }
                    }

                    .ant-collapse-header {
                        padding: 0 40px 0 16px;
                    }

                    .ant-collapse-content {
                        background: rgba(248, 249, 255, 1);
                        border-radius: 4px;
                        margin-top: 14px;

                        .ant-collapse-content-box {
                            padding: 16px 34px 0 16px;
                        }
                    }

                    .example-left-panel-head {
                        display: flex;
                        align-items: center;

                        .example-left-panel-chk {
                            padding-top: 1px;
                        }

                        .example-left-panel-title {
                            padding: 0;
                            margin: 0;
                            margin-right: 16px;
                            font-size: 16px;
                            font-family: MicrosoftYaHei;
                            color: rgba(0, 0, 0, 0.8);
                        }
                    }
                }
            }

            .example-left-panel-list {
                margin: 0;
                padding: 0;

                &>li {
                    margin: 0;
                    padding: 0;
                    display: flex;
                    margin-bottom: 32px;

                    .example-left-panel-content {
                        font-size: 12px;
                        font-family: MicrosoftYaHei;
                        color: rgba(0, 0, 0, 0.8);

                        &>h5 {
                            font-size: 16px;
                            margin: 0;
                            padding: 0;
                            margin-bottom: 8px;
                        }

                        &>p {
                            .example-left-panel-time {
                                font-size: 12px;
                                color: rgba(0, 0, 0, 0.4);
                                margin-right: 5px;
                            }
                        }
                    }
                }


            }
        }

        .example-btngroup {
            position: sticky;
            bottom: 0;
            background-color: #fff;
            padding: 10px 0 24px 0;
        }
    }

    .example-right {
        width: 30%;
        margin-left: 28px;
        height: calc(100vh - 140px);
        display: flex;
        flex-direction: column;

        position: sticky;
        top: 100px;

        &>h5 {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: rgba(0, 0, 0, 0.8);
            margin: 4px 0 16px 16px;
            padding: 0;
        }

        .examole-right-inputroot {
            background: rgba(248, 249, 255, 1);
            border-radius: 4px;
            flex: 1;
            overflow: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;

            &>textarea {
                border: 0;
                padding: 16px;
                background: rgba(248, 249, 255, 1);

                &:focus {
                    box-shadow: none
                }
            }

            .example-right-btngroup {
                margin: 24px;
            }
        }
    }
}