.financial-right-main{
    width: 100%;
    .top-box{
        width: 100%;
        position: relative;
        overflow: hidden;
        min-width: 1050px;
        background: #FFFFFF;
        box-shadow: 0 3px 10px 1px rgba(0,0,0,0.10);
        border-radius: 0 6px 6px 6px;
        padding: 30px;
        .search-main__data-table__title-line{
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;
        }
        .search-main__data-table__left-title{
            height: 32px;
            display: flex;
            align-items: center;
            margin: 0;
        }
        .search-main__data-table__left-title-CHN{
            display: block;
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            font-family: PingFangSC-Medium;
            font-size: 20px;
            color: #333333;
        }
        .search-main__data-table__left-title-ENG{
            display: block;
            height: 30px;
            line-height: 30px;
            /*max-width: 200px;
            margin-right: 15px;*/
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0,0,0,0.65);
            margin-left: 10px;
        }
        .search-main__data-table__right-time{
            height: 32px;
            display: flex;
            align-items: center;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0,0,0,0.85);
        }
        .search-main__data-table__right-time-select{
            margin-left: 30px;
        }
    }
    /*下方图标样式*/
    .profit-info-graph{
        width: 100%;
        overflow: hidden;
        .graph-list{
            height: 33px;
            line-height: 33px;
            display: flex;
            margin-bottom: 0;
            >li{
                width: 96px;
                height: 33px;
                line-height: 33px;
                background: #FFFFFF;
                border: 1px solid #1890FF;
                border-bottom: none;
                border-radius: 6px 6px 0 0;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #333333;
                overflow: hidden;
                margin-right: 30px;
                text-align: center;
                cursor: pointer;
                &.current-list-card{
                    background: #1890FF;
                    color: #fff;
                }
            }
        }
        .graph-main{
            background: #FFFFFF;
            border: 1px solid #488BFF;
            box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
            border-radius: 0 3px 3px 3px;
            padding: 30px 18px 37px;
            min-height: 413px;
        }
    }
}