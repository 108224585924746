@mixin font-base($color:#333333, $size:16px, $lineHeight:24px, $weight:400) {
    font: {
        size: $size;
        family: SourceHanSansCN-Regular,
            SourceHanSansCN;
        weight: $weight;
    }

    color:$color;
    line-height:$lineHeight;
}

@mixin style-base {
    h3 {
        margin: 0;
        padding: 0;
        @include font-base;
    }
}

@mixin title {
    color: #6D6D6D;
    margin-left: 16px;
    margin-bottom: 16px;
}

@mixin model {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 24px 16px;
    margin-bottom: 32px;
}

@mixin table-base {
    table {
        width: 100%;

        thead {
            th {
                @include font-base($size:14px, $lineHeight:21px, $color:#32323D, $weight:600);
                height: 50px;
                background: #F0F4FA;
                text-align: center;
            }
        }

        tbody {
            td {
                @include font-base($size:14px, $lineHeight:21px, $color:#32323D);
                border-bottom: 1px solid #F1F1F3;
                text-align: center;
                height: 49px;
                padding: 0 5px;

                &.empty {
                    border: none
                }
            }
        }
    }
}

.supplychain {
    @include style-base;
    @include font-base;

    .chain-treemap {
        .node {
            cursor: pointer;
        }

        .node circle {
            fill: #fff;
            stroke: steelblue;
            stroke-width: 1.5px;
        }

        .node text {
            font: 10px sans-serif;
        }

        .link {
            fill: none;
            stroke: #ccc;
            stroke-width: 1.5px;
        }
    }

    .chain-analyse {
        h3 {
            @include title();
        }

        .chain-analyse-tb {
            @include model();
            padding-bottom: 10px;

            &>div {
                overflow: auto hidden;
            }

            table {
                width: 100%;
                overflow: auto;

                thead {
                    th {
                        @include font-base($size:14px, $lineHeight:21px, $color:#32323D, $weight:600);
                        height: 50px;
                        background: #F0F4FA;
                        border: 1px solid #C8D3FF;
                        text-align: center;
                    }
                }

                tbody {
                    @mixin table-title($height:41px) {
                        height: $height;
                        background: #F0F4FA;
                        border: 1px solid #C8D3FF;
                    }

                    tr {
                        &.row-title {
                            @include table-title($height:50px);

                            td {
                                @include font-base($size:14px, $lineHeight:50px, $color:#32323D, $weight:600);
                                text-align: center;
                                border: 1px solid #C8D3FF;
                                background: #F0F4FA;
                            }
                        }

                        &.table-last {
                            td {
                                border-bottom: none;
                            }

                            &:last-child {
                                border-bottom: 1px solid #F1F1F3;
                            }
                        }

                        td {
                            @include font-base($size:14px, $lineHeight:21px, $color:#32323D);
                            border-bottom: 1px solid #F1F1F3;
                            text-align: center;
                            padding: 0 5px;

                            &.col-title {
                                @include table-title();
                            }

                            &.empty {
                                border: none
                            }
                        }
                    }
                }
            }
        }
    }

    .chain-following {

        h3 {
            @include title();
        }

        .chain-following-tb {
            @include model();

            padding-bottom: 10px;

            &>div {
                @include table-base();

                overflow: auto;

                table {
                    th {
                        min-width: 150px;
                    }
                }
            }
        }
    }

    .chain-belong {

        h3 {
            @include title();
        }

        .chain-belong-tb {
            @include model();

            padding-bottom: 10px;

            &>div {
                @include table-base();

                overflow: auto;

                table {
                    th {
                        min-width: 150px;
                    }
                }
            }
        }
    }

    .chain-loan {

        h3 {
            @include title();
        }

        .chain-loan-tb {
            @include model();
            padding-bottom: 10px;

            &>div {
                @include table-base();

                overflow: auto;

                table {
                    th {
                        min-width: 150px;
                    }
                }
            }
        }
    }
}