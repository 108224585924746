.finance-detail {
  padding: 16px 16px 16px 16px;
  display: flex;
  flex-direction: column;

  .finance-detail-content {
    display: flex;
    margin-top: 15px;

    .detail-article {
      height: calc(100vh - 130px);
      overflow: auto;
    }

    .detail-ingredient {
      margin-left: 20px;
      min-width: 566px;
    }
  }

}

.finance-detail-html {
  background-color: #fff;
  padding: 10px;

  .detail-html {
    margin: 0;
    padding: 0;

    p {
      font-size: 15px;
      line-height: 28px;
      color: #595959;
      font-family: 微软雅黑
    }

    pre,
    code {
      font-size: 14px;
      font-family: Roboto, 'Courier New', Consolas, Inconsolata, Courier, monospace;
    }

    code {
      margin: 0 3px;
      padding: 0 6px;
      white-space: pre-wrap;
      background-color: #F8F8F8;
      border-radius: 2px;
      display: inline;
    }

    pre {
      font-size: 15px;
      line-height: 20px;
    }

    pre code {
      white-space: pre;
      overflow: auto;
      border-radius: 3px;
      padding: 5px10px;
      display: block !important;
    }

    strong,
    b {
      color: #BF360C;
    }

    em,
    i {
      color: #009688;
    }

    big {
      font-size: 22px;
      color: #009688;
      font-weight: bold;
      vertical-align: middle;
      border-bottom: 1px solid #eee;
    }

    small {
      font-size: 12px;
      line-height: 22px;
    }

    hr {
      border-bottom: 0.05em dotted #eee;
      margin: 10px auto;
    }

    p {
      margin: 15px 5px !important;
    }

    table,
    pre,
    dl,
    blockquote,
    q,
    ul,
    ol {
      margin: 10px 5px;
    }

    ul,
    ol {
      padding-left: 10px;
    }

    li {
      margin: 5px;
    }

    li p {
      margin: 5px 0 !important;
    }

    ul ul,
    ul ol,
    ol ul,
    ol ol {
      margin: 0;
      padding-left: 10px;
    }

    ol ol,
    ul ol {
      list-style-type: lower-roman;
    }

    ul ul ol,
    ul ol ol,
    ol ul ol,
    ol ol ol {
      list-style-type: lower-alpha;
    }

    dl {
      padding: 0;
    }

    dl dt {
      font-size: 1em;
      font-weight: bold;
      font-style: italic;
    }

    dl dd {
      margin: 0 0 10px;
      padding: 0 10px;
    }

    blockquote,
    q {
      border-left: 3px solid #009688;
      padding: 0 10px;
      color: #777;
      quotes: none;
    }

    blockquote::before,
    blockquote::after,
    q::before,
    q::after {
      content: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 20px 0 10px;
      padding: 0;
      font-weight: bold;
      color: #009688;
    }

    h1 {
      font-size: 24px;
      border-bottom: 1px solid #ddd;
    }

    h2 {
      font-size: 22px;
      border-bottom: 1px solid #eee;
    }

    h3 {
      font-size: 18px;
      text-align: center;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 16px;
    }

    h6 {
      font-size: 16px;
      color: #777;
    }

    table {
      padding: 0;
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 1em;
      font: inherit;
      border: 0;
    }

    tbody {
      margin: 0;
      padding: 0;
      border: 0;
    }

    table tr {
      border: 0;
      border-top: 1px solid #CCC;
      background-color: white;
      margin: 0;
      padding: 0;
    }

    table tr:nth-child(2n) {
      background-color: #F8F8F8;
    }

    table tr th,
    table tr td {
      font-size: 16px;
      border: 1px solid #CCC;
      margin: 0;
      padding: 5px10px;
    }

    table tr th {
      font-weight: bold;
      background-color: #F0F0F0;
    }
  }
}

.finance-detail-knowledge {
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 1px #EDEDED;

  .detailklg-head-title {
    padding: 22px 22px 15px 13px;
    line-height: 30px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #333333;
  }

  .detailklg-tabs {
    background-color: #fff;
    margin-bottom: 0;
    width: 100%;

    li {
      margin: 0 22px 0 22px;
      display: inline-block;
      height: 52px;
      line-height: 52px;
      font-size: 14px;
      font-family: PingFangSC-Regular,
        PingFangSC;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      cursor: pointer;

      &.active {
        // background: rgba(255, 255, 255, 1);
        border-bottom: 1px solid #5B6BAE;
        border-width: 2px;
      }
    }
  }

  .detailklg-table-head {
    background-color: #fff;
    background: rgba(255, 255, 255, 1);
    border-top: 1px solid rgba(238, 238, 238, 1);
    border-bottom: 1px solid rgba(238, 238, 238, 1);
    width: 100%;
    margin-bottom: 0;

    li {
      display: inline-block;
      text-align: center;
      height: 70px;
      line-height: 70px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);

      .table-head-updown {
        font-size: 9px;
        color: rgba(51, 51, 51, 1);
        margin-left: 10px;
      }
    }
  }

  .detailklg-table {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border: 0;
    }

    .ant-spin-nested-loading {
      border: none;
    }
  }

  .detailklg-content-loading {
    border-top: 1px solid rgba(245, 245, 245, 1);
  }

  .detailklg-content-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ant-empty {
      margin: 0;
      padding: 32px 0;
      border-left: 1px solid rgba(245, 245, 245, 1);
      border-right: 1px solid rgba(245, 245, 245, 1);
    }
  }

  .detailklg-content {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    table-layout: fixed;

    tr {
      height: 80px;
      text-align: center;
      border-bottom: 1px solid rgba(245, 245, 245, 1);
      border-left: 1px solid rgba(245, 245, 245, 1);
      border-right: 1px solid rgba(245, 245, 245, 1);
      cursor: pointer;

      &.active {
        background-color: rgba(240, 244, 250, 1);
      }

      &:hover {
        background-color: rgba(240, 244, 250, 1);
      }
    }
  }
}