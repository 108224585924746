.report-page {
  padding: 16px 16px 16px 6px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;

  .report-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
  }

  .report-main {
    flex: 1;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 1px #F9F9F9;
    border-radius: 4px;
  }

  .table-action-td {
    display: flex;

    .table-action-cell:not(:last-child) {
      margin-right: 32px;
    }
  }

  .report-list {
    padding: 8px 10px 15px;
    .table-outline-name{
      width: 130px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .report-edit{
    width: 100%;
    height: 100%;
    padding: 25px 0;
    //padding: 25px 32px;
    >.ant-spin-nested-loading{
      width: 100%;
      height: 100%;
      >.ant-spin-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .report-edit-scroll{

    }
    .report-edit-btn{
      flex-shrink: 0;
      height: 30px;
      padding: 34px 32px;
      .cancel-btn{
        height: 30px;
        width: 88px;
        border-color: #5B6BAE;
        color: rgba(0, 0, 0, 0.8);
      }
      .save-btn{
        height: 30px;
        width: 88px;
        margin-left: 16px;
      }
    }
    .report-edit-list{
      padding: 0 32px;
    }
    .report-edit-item{

    }
    .report-item-title{
      margin: 0 0 16px;
      height: 21px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.8);
      line-height: 21px;
    }
    .report-item-content{

    }
    .report-item-textarea{
      width: 100%;
      height: 266px;
      padding: 19px;
      background: #F8F9FF;
      border-radius: 8px;
      border: none;
    }
  }

  .report-info{
    width: 100%;
    height: 100%;
    >.ant-spin-nested-loading{
      width: 100%;
      height: 100%;
      >.ant-spin-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .report-info-scroll{

    }
    .report-info-ul{
      margin: 0;
    }
    .report-info-item{
      padding: 23px 32px 40px;
      &:not(:first-child) {
        border-top: 1px solid #F3F3F3;
      }
    }
    .question-info-title{
      height: 21px;
      line-height: 21px;
      margin: 0 0 24px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.8);
    }
    .question-info-ques{
      line-height: 21px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.8);
      >span{
        margin-left: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5B6BAE;
        cursor: pointer;
      }
    }
    .question-info-text{
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.6);
      line-height: 19px;
      white-space: pre-wrap;
    }
    .report-graph-content{
      //width: 540px;
      min-width: 540px;
      width: 80%;
    }

    .report-info-btn{
      padding: 28px 32px;
      .cancel-btn{
        height: 30px;
        width: 88px;
        border-color: #5B6BAE;
        color: rgba(0, 0, 0, 0.8);
      }
      .save-btn{
        height: 30px;
        width: 88px;
        //margin-left: 16px;
      }
    }
  }
}

#root .report-page .report-list .unfinished{
  color: rgba(0,0,0,.3);
  cursor: not-allowed;
}
