.search-result-page{
    .search-result-header{
        width: 100%;
        height: 30px;
        margin-bottom: 15px;
        .search-type-list{
            display: flex;
            margin: 0;
            position: fixed;
            top: 60px;
            left: 0;
            width: 100%;
            height: 40px;
            z-index: 999;
            background-color: #fff;
            border-bottom: 1px solid #ddd;
            padding-left: 167px;
            //box-shadow:0px 1px 0px 0px rgba(221,221,221,1);
        }
        .search-type{
            padding: 0 6px;
            height: 40px;
            line-height: 40px;
            position: relative;
            cursor: pointer;
            //margin-right: 68px;
            font-size: 14px;
            color: #333;
            &:not(:last-child) {
                margin-right: 68px;
            }
            &.active-type{
                &:after{
                    display: block;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #5B6BAE;
                    position: absolute;
                    left: 0;
                    bottom: 1px;
                }
            }
        }
    }
}