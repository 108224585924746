.syllabus-page {
  padding: 16px 16px 16px 6px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;

  .syllabus-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
  }

  .syllabus-main {
    flex: 1;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 1px #F9F9F9;
    border-radius: 4px;
  }

  .table-action-td {
    display: flex;

    .table-action-cell:not(:last-child) {
      margin-right: 32px;
    }
  }

  .syllabus-list {
    padding: 8px 10px 15px;
  }

  .syllabus-info {
    padding: 29px 10px;
    .syllabus-info-ul {

    }

    .syllabus-info-item {
      margin-bottom: 60px;
    }

    .question-info-line {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .info-line-label {
      width: 98px;
      height: 19px;
      margin-right: 28px;
      text-align: right;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.5);
    }

    .info-line-content {
      width: 600px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.8);
      line-height: 19px;
      &.content-b{
        font-size: 16px;
        line-height: 21px;
      }
      &.content-t{
        width: 386px;
        table{
          border-color: #ECF3FE;
        }
        .ant-table-tbody td{
          border-color: #ECF3FE;
          border-bottom: 1px solid #ECF3FE!important;
        }
      }
    }
  }

  .syllabus-edit{
    width: 100%;
    height: 100%;
    >.ant-spin-nested-loading{
      width: 100%;
      height: 100%;
      >.ant-spin-container{
        width: 100%;
        height: 100%;
        display: flex;
        padding-top: 24px;
        padding-bottom: 51px;
        align-items: stretch;
      }
    }
    .syllabus-edit-left{
      width: calc(5/8 * 100% - 10px);
      margin-right: 10px;
      padding-left: 28px;
      padding-right: 14px;
    }
    .edit-left-name{

    }
    .edit-left-name-label{
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.5);
      line-height: 19px;
      position: relative;
    }
    .edit-left-name-icon{
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #FF0000;
      line-height: 19px;
      position: absolute;
      top: 0;
      left: -10px;
    }
    .edit-left-name-input{
      margin-top: 15px;
      margin-bottom: 24px;
    }
    .edit-left-name-input-main{
      border-color: #ECF3FE;
      width: 285px;
      height: 40px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
    }
    .edit-left-question-list{
      margin: 0;
    }
    .edit-left-question-item{
      margin-bottom: 27px;
    }
    .question-item-title{
      margin-bottom: 16px;
      height: 19px;
      line-height: 19px;
      display: flex;
      justify-content: space-between;
    }
    .question-item-index{
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.5);
    }
    .question-item-type{
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.8);
    }
    .question-item-type-edit{
      color: #5B6BAE;
      margin-left: 19px;
      cursor: pointer;
    }
    .question-area{

    }
    .question-area-content{
      width: 100%;
      height: 120px;
      background: #F8FAFF;
      border-radius: 8px;
      border: none;
      padding: 24px 32px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.8);
      line-height: 21px;
    }
    .edit-left-btn{
      display: flex;
      justify-content: space-between;
      margin-top: 42px;
      .add-btn{
        height: 30px;
        width: 105px;
        padding: 0;
        text-align: center;
        border-color: #DCE3FF;
        box-shadow: none;
      }
      .cancel-btn{
        height: 30px;
        width: 88px;
        border-color: #5B6BAE;
        color: rgba(0, 0, 0, 0.8);
      }
      .save-btn{
        height: 30px;
        width: 88px;
        margin-left: 16px;
      }
    }
    .syllabus-edit-right{
      width: calc(3/8 * 100%);
      padding-right: 24px;
    }
    .right-variable-list{
      height: 100%;
      margin: 0;
      position: relative;
    }
    .right-variable-item{
      display: none;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #ECF3FE;
      position: absolute;
      left: 0;
      padding: 10px 24px 3px;
      //display: flex;
      flex-wrap: nowrap;
    }
    .variable-default-value{
      width: 42px;
      flex-shrink: 0;
      margin-right: 14px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.5);
      padding-bottom: 15px;
      .efault-value-value{
        height: 40px;
        line-height: 40px;
      }
    }
    .variable-item-scroll{
      width: calc(100% - 56px);
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 15px;
    }

    .variable-item-label{
      margin: 0;
      display: flex;
    }
    .variable-label-li{
      margin-right: 8px;
      display: flex;
      flex-direction: column;
    }
    .variable-label-name{
      min-width: 56px;
      word-break: keep-all;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.8);
    }
    .variable-label-value{
      height: 40px;
      line-height: 40px;
      //width: 100%;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.8);
      .ant-input{
        padding: 0 5px;
        text-align: center;
      }
    }


    .syllabus-edit-modal{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .edit-modal-content{
      width: 630px;
      height: 422px;
      padding: 58px 24px 25px;
      position: relative;
      background: #FFFFFF;
      box-shadow: 0px 0px 14px 4px rgba(200, 200, 200, 0.16);
      border-radius: 4px;
    }
    .edit-icon-close{
      position: absolute;
      display: flex;
      width: 25px;
      height: 25px;
      top: 18px;
      right: 18px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }
    .modal-cascader{
      position: relative;
      width: 100%;
      height: 290px;
      margin-bottom: 19px;
    }
    // 级联选择样式
    .cascader-block{
      top: 0;
      left: 0;
      width: 582px;
      height: 290px;
      background: #FFFFFF;
      box-shadow: none;
      border-radius: 8px;
      border: 1px solid #ECF3FE;
      .ant-cascader-menu{
        height: 100%;
        width: 194px;
        &:not(:last-child) {
          border-right: 1px solid #ECF3FE;
        }
      }
      &>div{
        height: 100%;
        .ant-cascader-menu:first-child{
          border-right: 1px solid #ECF3FE;
        }
      }
      .ant-cascader-menu-item-active{
        background-color: transparent;
        color: #5B6BAE;
        font-weight: normal;
      }
    }
    .edit-modal-btn{
      text-align: center;
    }
  }

  .syllabus-create-report{
    //padding: 39px 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .syllabus-create-scroll{
      flex: 1;
    }
    .create-info-block{
      padding: 40px 10px 24px;
      &:not(:first-child) {
        border-top: 1px solid #F3F3F3;
      }
    }
    .syllabus-create-btn{
      padding: 40px 40px 30px;
      >button{
        &:first-child{
          border-color: #5B6BAE;
          color: rgba(0, 0, 0, 0.8);
          margin-right: 16px;
        }
      }
    }
    .create-info-line{
      display: flex;
      margin-bottom: 24px;
      &.info-line-select{
        margin-bottom: 16px;
      }
      &.info-line-input{
        margin-bottom: 16px;
      }
    }
    .create-line-label{
      width: 86px;
      margin-right: 28px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .create-line-require{
      color: #FF0000;
      font-size: 14px;
      margin-right: 4px;
    }
    .create-line-content{
      flex: 1;
    }
    .create-line-text{
      line-height: 19px;
    }
    .create-line-select{
      .ant-select-selection{
        line-height: 40px;
      }
    }
  }
}

#root{
  .syllabus-info .ant-table{
    .ant-table-thead{
      tr th{
        background-color: #ECF3FE;
        height: 32px;
        line-height: 32px;
        padding: 0 5px;
        //text-align: center;
        width: 193px;
      }
    }
    .ant-table-tbody{
      tr td {
        height: 32px;
        line-height: 32px;
        padding: 0 5px;
      }
    }
  }
  .create-line-select .ant-select-selection{
    height: 40px!important;
  }
}
