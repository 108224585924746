/*
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background: #F1F1F3!important;
}
#root{
  width: 100%;
  height: 100%;
}
.App{
  width: 100%;
  height: 100%;
}
.App__Main{
  width: 100%;
  height: 100%;
}
.App-loading{
  width: 100%;
  height: 100%;
}
.App-loading-spin{
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb{
  background: #ccc;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
