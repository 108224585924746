.research-home-page-content {
    padding: 0 10px;
}

.research {
    margin-top: 0;
    padding: 10px 10px 0;

    .countent {
        padding: 10px 0 0 10px;
        background-color: #fafafa;

        .title {
            margin-bottom: 13px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .nav {
                display: inline-block;
                color: #999999;
                margin: 0;

                li {
                    display: inline-block;
                    font-size: 12px;
                    font-family: MicrosoftYaHei;
                    line-height: 21px;

                    a {
                        color: #999999;
                    }

                    .txt {
                        color: #5B6BAE;
                    }
                }
            }

            .title-btn {
                margin-right: 30px;

                .btn {
                    background-color: #5B6BAE;

                    &:hover {
                        background-color: rgb(71, 90, 167);
                    }
                }
            }
        }
    }
}