 @mixin transitionEffect {
     transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
 }

 .leftdset-describe {
     margin-bottom: 16px;
     padding-top: 2px;
     padding-bottom: 2px;
     border-top: 1px solid rgba(236, 236, 236, 1);

     .title {
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin-top: 11px;

         .title-legend {
             font-size: 14px;
             font-family: PingFangSC-Regular,
                 PingFang SC;
             font-weight: 400;
             color: #333333;
             line-height: 17px;

             .list-tips {
                 font-size: 10px;
                 color: #909090;
                 line-height: 14px;
             }
         }
     }

     .title-progress {
         .ant-progress-text {
             font-size: 14px;
             color: #333;
         }
     }

     .set-list {
         padding-top: 4px;
     }

     .list-tips {
         line-height: 14px;
         font-size: 10px;
         color: #909090;
     }

     .set-list-main {
         margin-top: 8px;

         &.has-more {
             height: 76px;
             overflow: hidden;
         }
     }

     .set-list-loading {
         text-align: center;
         cursor: pointer;

         .set-list-icon {
             margin-bottom: 17px;
             color: #909090;
             font-size: 7px;
         }
     }

     .item {
         margin-right: 8px;
         margin-bottom: 8px;
         display: inline-block;

         &.item-active {
             margin-right: 0;
         }
     }

     .item-title {
         display: flex;
         align-items: center;
         padding: 0 16px;
         cursor: pointer;
         height: 30px;
         line-height: 30px;
         font-size: 14px;
         color: #333;
         @include transitionEffect;

         &.hidden {
             height: 0;
             visibility: hidden;
             @include transitionEffect;
         }

         &>span {
             white-space: nowrap;
             overflow: hidden;
             text-overflow: ellipsis;
             max-width: 638px;
             display: inline-block;
             border-bottom: 1px solid #70A925;
         }
     }

     .item-info {
         display: block;
         margin-bottom: 8px;
         @include transitionEffect;

         &.hidden {
             height: 0;
             visibility: hidden;
             @include transitionEffect;
         }
     }

     .set-list-loading {
         text-align: center;
         cursor: pointer;

         .set-list-icon {
             margin-bottom: 17px;
             color: #909090;
             font-size: 7px;
         }
     }
 }