.my-table-container {
  margin-bottom: 40px;
  .my-table-title {
    color: #5b6bae;
    margin-bottom: 10px;

    span {
      margin-left: 6px;
    }
  }

  .my-table-main {
    border-collapse: 'collapse';
    border: 1px solid #d9d9d9;
    text-align: center;
    width: 100%;

    .my-table-tr {
      height: 40px;
      td {
        border: 1px solid #d9d9d9;
        min-width: 85px;
      }
      td:first-child {
        text-align: left;
        padding-left: 8px;
      }
    }
  }
}
