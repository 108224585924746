@mixin font-css {
    font: {
        size: 12px;
        family: SourceHanSansCN-Regular,
            SourceHanSansCN;
        weight: 400;
    }

    line-height: 18px;
}

@mixin ckblist-item {
    @include font-css;

    .ant-checkbox-group-item {
        margin-right: 2px;
    }

    .ant-checkbox-wrapper {
        font-size: 12px;
        color: #32323D;

        .ant-checkbox+span {
            padding-left: 3px;
            padding-right: 3px;
        }

        .ant-checkbox-inner {
            width: 12px;
            height: 12px;
            border-color: #5B6BAE;
        }

        .ant-checkbox-checked {
            .ant-checkbox-inner {

                background-color: #5B6BAE;
            }
        }
    }
}

.list-table {
    // margin: -4px 10px 10px 10px;
    padding: 11px 20px 20px;
    @include font-css;
    min-width: 1200px;
    height: calc(100vh - 115px);
    overflow: auto;

    .listtb-reminder {

        color: #999999;
        margin-bottom: 19px;

        .reminder-ques,
        .reminder-par {
            color: #E99E51;

            &::before,
            &::after {
                content: " ";
            }
        }

        .reminder-res {
            color: #5B6BAE;
            text-decoration: underline;
            margin-right: 5px;
        }
    }

    .listtb-main {
        background: #FFFFFF;
        padding: 20px;


        .listtb-filter {
            height: auto;
            display: flex;
            justify-content: space-between;

            .filter-words {
                flex: 1;

                &>ul {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0;

                    li {
                        color: #333333;
                        height: 30px;
                        border-radius: 4px;
                        border: 1px solid #DFDFDF;
                        text-align: center;
                        padding: 0 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 24px;
                        margin-bottom: 10px;

                        .filter-words-total {
                            color: #DF0E06;
                        }

                        &.filter-words-add {
                            height: 30px;
                            border-radius: 4px;
                            border: 1px dotted #D8D8D8;
                            cursor: pointer;
                            color: #32323D;

                            &:hover {
                                border: 1px solid #5B6BAE;
                            }
                        }
                    }
                }
            }

            .filter-valuation {
                min-width: 195px;

                &>ul {
                    display: flex;
                    justify-content: flex-end;

                    li {
                        color: #32323D;
                        margin-left: 17px;
                        cursor: pointer;

                        &:hover {
                            color: #5B6BAE;

                            .filter-valuation-icon {
                                color: #5B6BAE;
                            }
                        }

                        &.filter-valuation-active {
                            color: #5B6BAE;

                            .filter-valuation-icon {
                                color: #5B6BAE;
                            }
                        }

                        .filter-valuation-icon {
                            color: #999999;
                            font-size: 12px;
                            margin-right: 2px;
                        }
                    }
                }
            }
        }

        .listtb-funct {
            @include font-css();
            padding: 10px 20px 10px 10px;
            display: flex;
            justify-content: space-between;

            .funct-left {
                display: flex;
                align-items: center;
                margin-right: 5px;

                .funct-left-total {
                    margin-right: 10px;

                    span {
                        font-size: 20px;
                    }
                }

                .funct-left-btn {
                    width: 100px;
                    height: 30px;
                    border-radius: 4px;
                    border: 1px solid #DFDFDF;
                    color: #333;
                    text-align: center;
                    padding-top: 5px;
                    cursor: pointer;
                    margin-right: 10px;

                    .funct-left-icon {
                        width: 10px;
                        height: 10px;
                        color: #000;

                        &::after {
                            content: " ";
                        }
                    }

                    &:hover {
                        border: 1px solid #5B6BAE;
                    }
                }

                .funct-left-ckblist {
                    display: flex;

                    .ckblist-title {
                        margin-right: 8px;
                    }

                    .ckblist-item {
                        @include ckblist-item;
                    }

                    .ckblist-hide {
                        .ckblist-hide-icon {
                            width: 13px;
                            height: 13px;
                            border-radius: 2px;
                            border: 1px solid #D8D8D8;
                            font-size: 3px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .funct-right {
                .funct-search {
                    width: 200px;
                    height: 30px;
                }
            }
        }
    }
}

.listtb-filter-suggestion {
    padding-top: 13px;

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        box-shadow: 0px 0px 8px 1px rgba(228, 228, 228, 0.5);
    }

    .ant-popover-inner-content {
        padding: 10px;
        display: flex;
        flex-direction: column;

        .suggestion-title {
            @include font-css;
            color: #32323D;
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
        }

        .suggestion-area {
            @include font-css;
            width: 340px;
            height: 140px;
            margin-bottom: 10px;

            &:hover {
                border: 1px solid #5B6BAE;
            }

            &:focus {
                border: 1px solid #5B6BAE;
                box-shadow: none;
            }
        }

        .suggestion-btn {
            @include font-css;
            width: 66px;
            height: 24px;
            align-self: flex-end;
            color: #FFFFFF;
            background-color: #5B6BAE;

            &:disabled {
                background: #E6E6E6;
                color: #32323D;
            }
        }
    }
}

.ckblist-hide-list {
    padding-top: 0;

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        box-shadow: 0px 0px 8px 1px rgba(228, 228, 228, 0.5);
        max-height: 300px;
        overflow-y: auto;
    }

    .ant-popover-inner-content {
        padding: 10px;
        display: flex;
        flex-direction: column;

        .ckblist-item {
            @include ckblist-item;

            .ant-checkbox-group-item {
                display: block;
            }
        }
    }
}