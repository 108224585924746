.lesson-plan-create {
    margin: 19px 24px 24px;
    overflow: auto;
    min-width: 1010px;
    // height: calc(100vh - 107px);
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 1px rgba(243, 243, 243, 1);
    border-radius: 4px;

    .plan-create-head {
        height: 55px;
        border-bottom: 1px solid rgba(244, 244, 244, 1);

        &>h5 {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: rgba(0, 0, 0, 0.8);
            padding: 0;
            margin: 0;
            margin-left: 52px;
            font-weight: 600;
            line-height: 55px;
        }
    }

    .plan-create-content {
        display: flex;
        justify-content: center;
        padding: 57px 0 50px;

        .create-content-form {
            width: 569px;

            .content-form-group {
                margin-bottom: 60px;

                .content-form-item {

                    .ant-form-item-label>label {
                        font-size: 14px;
                        font-family: MicrosoftYaHei;
                        color: rgba(0, 0, 0, 0.5);
                    }

                }
            }

            .content-form-btngroup {
                padding-top: 20px;

                .content-form-btn {
                    width: 104px;
                    height: 32px;
                    border-radius: 2px;

                    &.cancel {
                        border: 1px solid rgba(184, 184, 184, 1);
                        margin-right: 24px;

                        &:hover {
                            color: rgba(51, 51, 51, .7);
                        }
                    }
                }
            }
        }
    }
}