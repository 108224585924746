@font-face {
  font-family: "liuqi"; /* Project id 3629740 */
  src: url('iconfont.woff2?t=1662347129181') format('woff2'),
       url('iconfont.woff?t=1662347129181') format('woff'),
       url('iconfont.ttf?t=1662347129181') format('truetype');
}

.iconfont {
  font-family: "liuqi" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-zhishitupu:before {
  content: "\e696";
}

.icon-database:before {
  content: "\ec6c";
}

.icon-finance-sub:before {
  content: "\e63e";
}

