.reslute-source-index {
    padding-top: 10px;
    padding-left: 10px;

    .pdfViewer-head {
        &>h3 {
            margin: 0;
            padding: 0;
            margin-bottom: 16px;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: #999999;
        }
    }

    .pdfViewer-content {}
}