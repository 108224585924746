.result-list{
    padding: 0 10px;
    .result-list-topMenu{
        position: fixed;
        top: 60px;
        left: 0;
        height: 50px;
        width: 100%;
        padding-left: 45px;
        background: #FFFFFF;
        box-shadow: 0 4px 3px 1px #F1F1F1;
        z-index: 999;
        border-top: 1px solid #F1F1F1;
        .ant-anchor{
            display: flex;
            align-items: center;
            .ant-anchor-ink:first-child{
                display: none;
            }
            a{
                font-size:16px;
                font-family:PingFangSC-Regular,PingFangSC;
                font-weight:400;
                color: #333;
                &:hover{
                    text-decoration: none;
                    color: #5B6BAE;
                }
                &:active,&:focus{
                    text-decoration: none;
                }
            }
        }
        .top-menu-item{
            height: 50px;
            line-height: 50px;
            padding: 0;
            margin-right: 58px;
            &.ant-anchor-link-active{
                position: relative;
                &:after{
                    display: block;
                    content: '';
                    width: 100%;
                    height: 3px;
                    background: #5B6BAE;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
                >a{
                    color: #5B6BAE;
                }
            }
        }
    }
    .result-list-main{
        display: flex;
        margin-top: calc(50px - 20px);
        width: 100%;
        .list-result-left{
            min-width: 800px;
            padding-top: 20px;
            flex: 1;
            .left-module{
                margin-right: 20px;
                margin-bottom: 30px;
                .module-title{
                    height: 22px;
                    line-height: 22px;
                    font-size:16px;
                    font-family:PingFangSC-Regular,PingFangSC;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    margin-top: 0;
                    margin-bottom: 16px;
                }
                .module-main{
                    background:rgba(255,255,255,1);
                    box-shadow:0px 0px 10px 1px rgba(237,237,237,1);
                    padding: 0 20px 40px;
                }
                .module-content{
                    margin-bottom: 0;
                    min-height: 100px;
                }
                .ant-empty{
                    padding-top: 20px;
                }
                .article-item{
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-bottom:1px solid rgba(240,240,240,1);
                    &:last-child{
                        border-bottom: none;
                    }
                    .article-item-title{
                        height: 20px;
                        line-height: 20px;
                        font-size:14px;
                        font-family:PingFangSC-Regular,PingFangSC;
                        font-weight:400;
                        color:rgba(51,51,51,1);
                        margin-top: 0;
                        margin-bottom: 10px;
                    }
                    .article-item-text{
                        font-size:12px;
                        font-family:PingFangSC-Regular,PingFangSC;
                        font-weight:400;
                        color:rgba(51,51,51,1);
                        line-height:17px;
                    }
                    .article-item-footer{
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        height: 17px;
                        line-height: 17px;
                    }
                    .article-item-dataFrom{
                        font-size:12px;
                        font-family:PingFangSC-Regular,PingFangSC;
                        font-weight:400;
                        color:rgba(91,107,174,1);
                    }
                    .article-item-author{
                        font-size:12px;
                        font-family:PingFangSC-Regular,PingFangSC;
                        font-weight:400;
                        color:rgba(150,150,150,1);
                    }
                    &:hover{
                        cursor: pointer;
                        .article-item-title,.article-item-text{
                            text-decoration: underline;
                            color: #5B6BAE;
                        }
                    }
                    em[hl] {
                        font-style: normal;
                        color: #E01C1C;
                    }
                }
                .ant-pagination{
                    margin-top: 26px;
                }

                .company-item{
                    display: flex;
                    .left-img{
                        width: 94px;
                        margin-right: 29px;
                        display: flex;
                        align-items: center;
                    }
                    .company-item-img{
                        width: 94px;
                        height: 94px;
                        background-size: 100% 100%;
                        background:rgba(255,255,255,1);
                        border-radius:5px;
                        border:1px solid rgba(240,240,240,1);
                    }

                    .right-info{
                        flex: 1;
                        overflow: hidden;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        border-bottom:1px solid rgba(240,240,240,1);
                    }
                    .company-name{
                        height:20px;
                        font-size:14px;
                        font-family:PingFangSC-Regular,PingFangSC;
                        font-weight:400;
                        color: #333;
                        line-height:20px;
                        margin-bottom: 10px;
                        width: 100%;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                        flex-shrink:0;
                        &:hover{
                            text-decoration: underline;
                            cursor: pointer;
                            color: #5B6BAE;
                        }
                    }
                    .company-person-info,.company-address,.company-more-info{
                        width: 100%;
                        margin-bottom: 10px;
                        display: flex;
                        justify-content: space-between;
                        .company-item-info-item{
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                            display: flex;
                            flex: none;
                            flex-wrap: nowrap;
                            font-size:12px;
                            font-family:PingFangSC-Regular,PingFangSC;
                            font-weight:400;
                            margin-right: 10px;
                        }
                        .info-item-label{
                            color: #969696;
                            flex-shrink: 0;
                        }
                        .info-item-txt{

                            flex-shrink:0;
                            color: #333;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;

                        }
                        .webSite-info{
                            &:hover{
                                text-decoration: underline;
                                cursor: pointer;
                                color: #5B6BAE;
                            }
                        }
                    }
                    .company-person-info .company-item-info-item{
                        max-width: 33.33333333%;
                    }
                    .company-address .company-item-info-item{
                        max-width: 50%;
                    }
                    .company-more-info .company-item-info-item{
                        width: 100%;
                    }
                    em[hl] {
                        font-style: normal;
                        color: #E01C1C;
                    }
                }

            }
        }

        .list-result-right{
            padding-top: 22px;
            width: 260px;
        }
    }


}