.right-item{
    position: relative;
    width: 260px;
    margin-bottom: 30px;
    .right-item-title{
        height: 20px;
        line-height: 20px;
        display: flex;
        margin-top: 0;
        margin-bottom: 16px;
        align-items: center;
        justify-content: space-between;
        color: #333;
        font-size:14px;
        font-family:PingFangSC-Regular,PingFangSC;
        font-weight:400;
    }
    .item-title-right{
        font-size: 12px;
        color: #5B6BAE;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .reload-icon{
        margin-left: 4px;
        font-size: 10px;
    }
    .right-item-content{
        display: flex;
        flex-wrap: wrap;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 1px rgba(241,241,241,1);
        padding: 20px;
        min-height: 100px;
        //justify-content: center;
    }
    .two-columns-item{
        width: 50%;
        font-size:12px;
        font-family:PingFangSC-Regular,PingFangSC;
        font-weight:400;
        color: #333333;
        height: 17px;
        line-height: 17px;
        margin-bottom: 20px;
        cursor: pointer;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        padding: 0 3px;
        &:hover{
            color: #5B6BAE;
            text-decoration: underline;
        }
        &:nth-last-child(1),&:nth-last-child(2) {
            margin-bottom: 0;
        }
    }
    .right-item-select{
        padding-bottom: 24px;
    }
    .select-label{
        width: 100%;
        height: 20px;
        line-height: 20px;
        margin-bottom: 13px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333333;
    }
}