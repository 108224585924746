.graph-rightlist {
  .search-rightlist-content {
    background: #fff;
    border-radius: 4px;
    padding: 8px 10px;

    .ant-empty {
      margin-top: 50px;
    }

    .rightlist-title {
      font-size: 12px;
      font-family: PingFangSC-Regular,
      PingFang SC;
      font-weight: 400;
      color: #909090;
      line-height: 17px;
      margin: 0 0 8px;
    }

    & > ul {
      li.rightlist-item {
        margin-bottom: 12px;
        background: #FAFBFC;
        border-radius: 2px;
        padding: 6px 10px 7px;
        border: 1px solid #FAFBFC;

        & > a {
          text-decoration: none;
        }

        &:hover {
          border-color: #7987BE;

          .title {
            color: #5B6BAE;
          }
        }

        .title {
          font-size: 14px;
          height: 25px;
          line-height: 25px;
          color: #333;
          margin-bottom: 1px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .info {
          //height: 17px;
          line-height: 17px;
          font-size: 12px;
          color: #909090;
          display: flex;
        }

        .source {
          margin-right: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .time {
          width: 95px;
          flex-shrink: 0;
        }
      }
    }
  }
}

.right-value-info {
  padding: 33px 10px;
  width: 100%;

  .right-value-item {
    width: 100%;
    padding: 10px;
    background: #FAFBFC;
    border-radius: 2px;
    border: 1px solid transparent;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .right-value-title {
      font-size: 16px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      //height: 22px;
      line-height: 22px;
      margin: 0 0 4px;
    }

    .right-value-time {
      height: 16px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(144, 144, 144, 1);
      line-height: 16px;
      margin-bottom: 4px;
    }

    .right-value-content {
      font-size: 12px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 22px;
      min-height: 22px;
      margin: 0;
      position: relative;

      > .right-value-last {
        width: 76px;
        height: 1px;
        display: inline-block;
      }
    }

    .right-value-button {
      display: block;
      width: 76px;
      height: 22px;
      line-height: 22px;
      background: rgba(91, 107, 174, 1);
      border-radius: 2px;
      text-align: center;
      font-size: 12px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:hover {
      border: 1px solid rgba(121, 135, 190, 1);

      .right-value-title, .right-value-content {
        color: #5B6BAE;
        cursor: default;
      }
    }
  }
}

.feed-back-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;

  .feed-back-block {
    background: #fff;
    border-radius: 4px;
    width: 58%;
    min-width: 703px;
    position: relative;
  }

  .content-top {
    background: #5B6BAE;
    border-radius: 4px 4px 0 0;

    > ul {
      display: flex;
      padding: 25px 40px;
    }

    .class-list-item {
      padding: 0 10px;
      font-size: 20px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      border: 1px solid rgba(150, 171, 200, 1);
      color: #fff;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &.active {
        background: #fff;
        color: #333;

        .quick-num {
          color: #333;
        }
      }
    }

    .quick-num {
      font-size: 12px;
      color: #fff;
      margin-left: 8px;
    }
  }

  .content-main {
    padding: 24px 40px 66px;
    color: #32323D;
    font-size: 20px;

    .content-text {
      margin-bottom: 80px;

      > span {
        display: inline-block;
        height: 40px;
        line-height: 40px;

        &::selection {
          background: #FFE5A4;
          font-weight: 700;
          color: #32323D;
        }
      }
    }

    .text-mark {
      background: transparent;
      padding: 0;
      margin: 0 5px;
      cursor: pointer;
      position: relative;

      .select-text {
        display: inline-block;
        //background: #FFE5A4;
        height: 40px;
        line-height: 40px;
        position: relative;
        z-index: 0;
        font-weight: 600;

        &:after {
          display: block;
          content: '';
          width: 100%;
          height: 32px;
          position: absolute;
          top: 4px;
          left: 0;
          background: #FFE5A4;
          z-index: -1;
        }

        &:first-child {
          padding-left: 8px;
          //margin-left: 5px;
        }

        &::selection {
          background: #FFE5A4;
          font-weight: 700;
          color: #32323D;
        }
      }

      .mark-label {
        padding: 0 8px 0 8px;
        //margin-right: 5px;
        > em {
          font-style: normal;
          color: #5B6BAE;
          font-size: 12px;
          font-weight: 500;

          &::selection {
            background: #FFE5A4;
            font-weight: 700;
            color: #32323D;
          }
        }
      }

      .delete-merge-icon {
        display: none;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        border-radius: 50%;
        background: #636368;
        color: transparent;
        position: absolute;
        top: -10px;
        left: -9px;
        font-size: 8px;
        z-index: 3;

        > i {
          color: #fff;
        }
      }

      &:hover {
        .delete-merge-icon {
          display: block;
        }
      }
    }

    .content-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-edit-btns {
        display: flex;

        .edit-btn {
          display: block;
          width: 70px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #fff;
          border: 1px solid #5B6BAE;
          cursor: pointer;

          &.active {
            background: #5B6BAE;
            color: #fff;
          }

          &:nth-child(1) {
            border-radius: 4px 0 0 4px;
          }

          &:nth-child(2) {
            border-radius: 0 4px 4px 0;
            margin-left: -1px;
          }
        }

        .clear-btn {
          margin-left: 10px;
          height: 40px;
          line-height: 40px;
          color: #333333;
          font-size: 16px;
          cursor: pointer;

          > i {
            margin-right: 2px;
          }

          &:hover {
            color: #5B6BAE;
          }
        }
      }

      .right-save-btns {
        > button {
          width: 110px;
          height: 40px;
          font-size: 20px;

          &:first-child {
            background: #4dab14;
            color: #fff;
            border-color: #4dab14;

            &:hover {
              background: #6AAB42;
              border-color: #6AAB42;
            }
          }

          &:last-child {
            background: #fff;
            color: #32323D;
            border-color: #8D8D8D;
            margin-left: 16px;

            &:hover {
              color: #5B6BAE;
              border-color: #5B6BAE;
            }
          }
        }
      }
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: -50px;
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    display: flex;
    width: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
  }
}