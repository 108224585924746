$btnColor: #5b6bae;
$btnHover: #7882aa;

@mixin transitionEffect {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

html {
  background: #F1F1F3;
}

//transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
.home-page {
  background: #F1F1F3;

  .home-page-main {
    //padding-top: 20px;
    //padding-top: 28px;
    //padding-left: 200px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    .home-page-content {
      // padding: 10px 10px 0;
      //width: 1000px;
      margin: 0 auto;
    }
  }
}

.home-page__home {
  padding: 10px 10px 0;

  .home-page-item {
    margin-bottom: 30px;

    /*&:first-child {
      margin-bottom: 0;
    }*/
  }

  .home-page-item__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding: 0 18px 0 2px;
    padding-left: 3px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFangSC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    margin-top: 0;
  }

  .home-page-item__title-txt {
    font-family: PingFang-SC-Medium;
    font-size: 14px;
    color: #333333;
  }

  .home-page-item__title-more {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: $btnColor;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    .home-page-item__title-more-icon {
      margin-left: 9px;
      font-size: 10px;
    }
  }

  .home-page-item__main {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .home-page-item__history-item {
    /*padding: 0 17px;
    background: #ffffff;
    box-shadow: 0 2px 20px 1px #ededed;
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    margin-right: 20px;
    margin-bottom: 20px;*/
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFangSC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    cursor: pointer;
    margin-right: 30px;
    margin-bottom: 20px;

    //@include transitionEffect;
    &:hover {
      text-decoration: underline;
      color: $btnColor;
      //color: #fff;
      //background: $btnColor;
      //@include transitionEffect;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .home-page-item-left {
    width: 312px;
    flex-shrink: 0;
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(237, 237, 237, 1);
    //border-radius: 4px;
    margin-right: 20px;
    //padding: 23px 0 23px 23px;
    padding: 15px 23px 10px 23px;
    min-height: 210px;
  }

  .home-page-item-left-list {
    display: flex;
    flex-wrap: wrap;
    min-height: 30px;
    overflow: auto;
    margin-bottom: 0;
  }

  .home-page-item-left-item {
    display: flex;
    align-items: center;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 0 13px;
    margin-right: 15px;
    margin-bottom: 20px;
    height: 24px;
    cursor: pointer;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFangSC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    @include transitionEffect;

    &:hover {
      background-color: $btnColor;
      border-color: $btnColor;
      color: #fff;
      //text-decoration: underline;
      @include transitionEffect;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .home-page-item__main-title {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #333333;
    margin-bottom: 19px;
    margin-top: 0;
    height: 20px;
    line-height: 20px;
  }

  .home-page-item-right {
    // width: calc(100% - 332px);
    width: 100%;
    padding: 15px 18px 20px 20px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(237, 237, 237, 1);
    //border-radius: 4px;
  }

  .home-page-item-right-list {
    margin-top: -3px;
    margin-bottom: 0;
    min-height: 30px;
  }

  .home-page-item-right-item {
    height: 17px;
    display: flex;
    cursor: pointer;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      text-decoration: underline;
    }

    >span {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .list-item-index {
      width: 19px;
      flex-shrink: 0;
      margin-right: 0;
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #333333;
      display: flex;
      align-items: center;

      >span {
        display: block;
        text-align: center;
        width: 10px;
        height: 12px;
        line-height: 12px;
        font-size: 12px;
      }

      &.txt-one {
        >span {
          display: block;
          text-align: center;
          width: 10px;
          height: 12px;
          line-height: 12px;
          color: #fff;
          font-size: 8px;
          background-image: linear-gradient(-180deg, #2fcccb 0%, #1e8ac0 100%);
          border-radius: 2px;
        }
      }

      &.txt-two {
        >span {
          display: block;
          text-align: center;
          width: 10px;
          height: 12px;
          line-height: 12px;
          color: #fff;
          font-size: 8px;
          background-image: linear-gradient(-180deg, #e7786a 0%, #e56784 100%);
          border-radius: 2px;
        }
      }

      &.txt-three {
        >span {
          display: block;
          text-align: center;
          width: 10px;
          height: 12px;
          line-height: 12px;
          color: #fff;
          font-size: 8px;
          background-image: linear-gradient(-180deg, #605ce2 0%, #8f3cca 100%);
          border-radius: 2px;
        }
      }
    }

    .list-item-title {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      width: calc(100% - 233px);
      flex-shrink: 0;
      margin-right: 10px;
      color: #333333;
    }

    .list-item-dataFrom {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #999999;
      display: block;
      width: 65px;
      flex-shrink: 0;
      margin-right: 10px;
    }

    .list-item-author {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: $btnColor;
      width: 50px;
      flex-shrink: 0;
      margin-right: 10px;
    }

    .list-item-time {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: #999999;
      width: 75px;
      flex-shrink: 0;
    }
  }

  .home-page-item__table {
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(237, 237, 237, 1);
    border-radius: 6px;
    //padding: 34px 13px 49px;
    padding: 0 10px;

    .ant-table-body {
      .ant-table-thead {
        tr {
          th {
            font-family: PingFang-SC-Medium;
            font-size: 16px;
            color: #333333;
            background: #fff;
            border: none;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td {
            border-bottom: none;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #333333;
          }

          &:hover {
            td {
              //background-color: #fff;
              //background: $btnColor;
              //color: #fff;
            }
          }

          &.single-row {
            td {
              background: #edf5ff;
            }

            &:hover {
              td {
                //background: #edf5ff;
                //background: $btnColor;
                //color: #fff;
              }
            }
          }
        }
      }
    }

    .ant-table-placeholder {
      border-bottom: none;
    }
  }

  .home-page__copyright {
    text-align: center;
    width: 100%;
    height: 17px;
    font-size: 12px;
    font-family: AppleColorEmoji;
    color: rgba(51, 51, 51, 1);
    line-height: 17px;
    margin-top: 39px;
    margin-bottom: 40px;
    color: #999;
  }
}

.home-page__company-list {
  width: 100%;
  min-width: 1000px;

  .company-list__industry-screen {
    //     width:982px;
    // height:100px;
    margin-bottom: 20px;
    padding: 9px 13px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 1px rgba(241, 241, 241, 1);
    border-radius: 4px;
  }

  .company-list__industry-screen__title {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    line-height: 22px;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .company-list__industry-screen__main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 18px;
  }

  .company-list__industry-screen__item {
    margin-right: 40px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }
  }

  .company-list__industry-screen__item-label {
    height: 30px;
    font-size: 12px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 30px;
    margin-right: 10px;
  }

  .company-list__table-main {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 1px rgba(237, 237, 237, 1);
    // border-radius: 6px 0px 0px 0px;
    padding: 0 10px 44px;
    margin-bottom: 40px;

    /*.ant-table-body {
      .ant-table-thead {
        tr {
          th {
            font-family: PingFang-SC-Medium;
            font-size: 16px;
            color: #333333;
            background: #fff;
            border: none;
          }
        }
      }
      .ant-table-tbody {
        tr {
          td {
            border-bottom: none;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #333333;
          }
          &:hover {
            td {
              //background: $btnColor;
              //color: #fff;
            }
          }
          &.single-row {
            td {
              background: #edf5ff;
            }
            &:hover {
              td {
                //background: $btnColor;
                //color: #fff;
              }
            }
          }
        }
      }
    }*/
    .ant-table-placeholder {
      border-bottom: none;
    }
  }
}

.summary-list {
  .summary-list-item {
    .summary-list-chk {
      .ant-checkbox {

        .ant-checkbox-inner {
          width: 12px;
          height: 12px;
          border: 1px solid #8B8B8B;
        }

        &.ant-checkbox-checked {

          .ant-checkbox-inner {
            width: 12px;
            height: 12px;
            border-radius: 2px;
            background: #5B6BAE;

            &::after {
              width: 4px;
              height: 7px;
            }
          }
        }
      }

      .summary-chk-title {
        margin-left: 8px;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
      }
    }

    .summary-chk-label {
      margin-left: 28px;
      margin-top: 10px;

      li {
        display: inline-block;
        background: #F0F4FA;
        border-radius: 2px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        padding: 2px 12px;
        margin-right: 10px;

      }
    }

    .summary-chk-content {
      margin-top: 17px;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #333333;
      margin-left: 28px;
    }

    .summary-chk-annotation {
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #969696;
      margin-top: 10px;
      margin-left: 28px;

      li {
        display: inline-block;
        margin-right: 33px;

        &:last-child {
          float: right;
        }

        a {
          color: #5B6BAE;
          text-decoration: underline;
        }
      }
    }
  }

  .ant-list-pagination {
    text-align: left !important;
  }
}

.year-report-search-title {
  //margin-bottom: 12px;
  height: 46px;
  line-height: 46px;
  margin: 0;
}

.home-page__research-library {
  padding: 10px 10px 0;
  width: 100%;
  min-width: 1000px;

  .company-list__industry-screen {
    //     width:982px;
    // height:100px;
    margin-bottom: 20px;
    padding: 9px 13px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 1px rgba(241, 241, 241, 1);
    border-radius: 4px;
  }

  .company-list__industry-screen__header {
    cursor: pointer;

    .company-list__industry-screen__title {
      display: inline-block;
      width: 64px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      line-height: 22px;
      margin-bottom: 12px;
      margin-top: 12px;
    }

    .company-list__industry-screen__icon {
      margin-left: 9.8px;
      color: #333333;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &.up-icon {
        transform: rotate(180deg);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }

  .company-list__industry-screen__main {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 4px;
    margin-top: 10px;
    //margin-bottom: 18px;
  }

  .company-list__industry-screen__item {
    margin-right: 30px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
      //margin-bottom: 20px;
    }
  }

  .company-list__industry-screen__item-label {
    width: 72px;
    text-align: right;
    height: 30px;
    font-size: 12px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 30px;
    margin-right: 10px;
  }

  .company-list__table-main {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 1px rgba(237, 237, 237, 1);
    //border-radius: 6px 0px 0px 0px;
    padding: 0 10px 10px;

    &.pt20 {
      padding-top: 20px;
    }

    .report-row {
      &:hover {
        cursor: pointer;
      }
    }

    .table-main-list {
      display: inline-block;
      //margin-bottom: 20px;

      &.add-btn-suspend {
        position: fixed;
        z-index: 1001;
        top: 110px;
      }

      &.year-add-btn-suspend {
        position: fixed;
        z-index: 1001;
        top: 60px;
      }
    }

    .table-main-model {
      float: right;
      line-height: 30px;

      ul {
        li {
          height: 30px;
          width: 70px;
          display: inline-block;
          background: #D8D8D8;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #333333;
          text-align: center;
          cursor: pointer;

          &.left {
            border-radius: 15px 0 0 15px;
          }

          &.right {
            border-radius: 0 15px 15px 0;
          }

          &.active {
            color: #FFFFFF;
            background: #5B6BAE;
          }
        }
      }
    }
  }

  .research-library-header {
    width: calc(100% + 30px);
    padding: 0 42px;
    position: fixed;
    z-index: 1000;
    top: 60px;
    //left: 191px;
    margin-left: -19px;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 2px 5px 1px rgba(241, 241, 241, 1);
    display: flex;

    .research-header-item {
      height: 50px;
      line-height: 50px;
      position: relative;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #333333;
      cursor: pointer;
      margin-right: 74px;

      &.select-item {
        color: $btnColor;

        &:after {
          display: block;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background: $btnColor;
        }
      }
    }
  }
}


#root {
  .ant-pagination.ant-table-pagination {
    float: left;
    margin-top: 40px;

    .ant-pagination-total-text {
      margin: 0 15px 0 14px;
    }
  }

  //表格页码样式
  .ant-pagination {
    .ant-pagination-item-active {
      border-color: $btnColor;

      >a {
        color: $btnColor;
      }
    }

    .ant-pagination-item {
      margin-right: 14px;

      &:hover {
        border-color: $btnColor;

        >a {
          color: $btnColor;
        }
      }
    }

    .ant-pagination-prev[aria-disabled="false"],
    .ant-pagination-next[aria-disabled="false"] {

      &:hover,
      &:focus {
        >a {
          border-color: $btnColor;
          color: $btnColor;
        }
      }
    }

    .ant-pagination-prev {
      margin-right: 14px;
    }

    .ant-select-selection {

      &:hover,
      &:focus {
        border-color: $btnColor;
        color: $btnColor;
      }
    }

    .ant-pagination-options-quick-jumper {
      &:hover {
        input {
          border-color: $btnColor;
        }
      }

      input {

        &:hover,
        &:focus {
          border-color: $btnColor;
        }
      }
    }
  }

  //表格样式

  .ant-table-body {
    .ant-table-thead {
      tr {
        th {
          font-family: PingFang-SC-Medium;
          font-size: 14px;
          color: #333333;
          background: #fff;
          border: none;
          line-height: 20px;
          padding: 15px 16px;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          border-bottom: none;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #333333;
          padding: 10px 16px;
          line-height: 20px;
          min-height: 40px;

        }

        &:hover {
          /*td {
            background: $btnColor;
            color: #fff;
          }*/
        }

        &.single-row {
          td {
            background: #F0F4FA;
          }

          &:hover {
            td {
              background: #F0F4FA;
              /*background: $btnColor;
              color: #fff;*/
            }
          }
        }

        &.double-row:hover {
          td {
            background-color: #fff;
            /*background: $btnColor;
            color: #fff;*/
          }
        }
      }
    }

    .click-row-style {
      cursor: pointer;
    }

    .table-action-cell {
      font-size: 12px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(91, 107, 174, 1);
      cursor: pointer;
    }

    .ant-table-tbody tr:hover {
      /*.table-action-cell {
        //color: #fff;
        .ant-radio-wrapper{
          color: #fff;
        }
      }*/
      /*.ant-progress-text{
        color: #fff;
      }*/
    }

    //表格选择框
    .ant-table-selection {
      .ant-checkbox {

        .ant-checkbox-inner {
          width: 12px;
          height: 12px;
        }

        &.ant-checkbox-indeterminate {
          .ant-checkbox-inner:after {
            background-color: $btnColor;
          }
        }
      }
    }

    .ant-table-selection-column {
      .ant-checkbox {
        &:hover {
          .ant-checkbox-inner {
            border-color: $btnColor;
          }
        }

        .ant-checkbox-inner {
          width: 12px;
          height: 12px;
        }

        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background: $btnColor;
            border-color: $btnColor;
          }
        }
      }
    }
  }

  .ant-table-placeholder {
    border-bottom: none;
  }

  .ant-calendar-picker-input {

    &:active,
    &:hover,
    &:focus {
      border-color: $btnColor;
    }

    .ant-calendar-picker-icon {
      right: 3px
    }
  }

  .ant-calendar-picker {
    &:hover {
      .ant-calendar-picker-input {
        border-color: $btnColor !important;
      }
    }

    &:focus {
      .ant-calendar-picker-input {
        border-color: $btnColor !important;
      }
    }

    .ant-input {

      &:hover,
      &:focus,
      &:active {
        border-color: $btnColor;
      }
    }
  }

  .ant-select-selection {

    &:active,
    &:hover,
    &:focus {
      border-color: $btnColor;
    }
  }

  .ant-calendar-panel {
    .ant-calendar-table {
      .ant-calendar-today {
        .ant-calendar-date {
          border-color: $btnColor;
          color: $btnColor;
        }

        &.ant-calendar-selected-day {
          .ant-calendar-date {
            color: #fff;
          }
        }

        &.ant-calendar-selected-date {
          .ant-calendar-date {
            color: #fff;
          }
        }
      }
    }
  }

  .ant-select-open .ant-select-selection {
    border-color: $btnColor;
  }


  //按钮样式
  .ant-btn-primary {
    background: $btnColor;
    border-color: $btnColor;
    color: #fff;

    &:hover,
    &:focus {
      background: $btnHover;
      border-color: $btnHover;
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .ant-input {
    //border-color: #f6f6f6;
    //background: #f6f6f6;
    //border-radius: 4px;
    //height: 30px;
    //line-height: 30px;
    //font-family: PingFangSC-Regular;
    font-size: 14px;

    &:hover {
      border-color: $btnColor;
    }

    &:focus {
      border-color: $btnColor;
    }
  }

  .ant-cascader-picker:focus .ant-cascader-input {
    border-color: $btnColor;
  }
}


.rs-picker-toggle-caret:before {
  display: none;
}

.ant-popover {
  .ant-popover-buttons {
    .ant-btn {

      &:hover,
      &:focus {
        color: $btnColor;
        border-color: $btnColor;
      }

      &[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    //按钮样式
    .ant-btn-primary {
      background: $btnColor;
      border-color: $btnColor;
      color: #fff;

      &:hover,
      &:focus {
        background: $btnHover;
        border-color: $btnHover;
        color: #fff;
      }

      &[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
  }

}

body {
  .public-header-left-menu {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        padding: 0;
        background: #ffffff;

        .ant-drawer-body {
          padding: 21px 0 0;
        }
      }
    }

    &.left-always-menu {
      .ant-drawer-content-wrapper {
        border-bottom: 1px solid #DDDDDD;
        box-shadow: 0px 5px 10px 1px rgba(237, 237, 237, 1);

        &:before {
          width: calc(100% + 7px);
          height: 7px;
          content: '';
          background: #fff;
          position: absolute;
          top: -8px;
          left: 0;
          z-index: 999;
        }
      }

      .ant-drawer-wrapper-body {
        overflow: initial !important;
      }
    }

    &.left-drop-menu {
      .ant-drawer-content-wrapper {
        overflow: hidden;
        box-shadow: none;

        .ant-drawer-content {
          background: #ffffff;
        }
      }
    }
  }
}

.relation-graph {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #130D37;
  z-index: 1000;

  .relation-graph-back {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10000;
  }

  .chart-div {
    width: 100%;
    height: 100%;
  }
}

.project-modal {
  .ant-modal-header {
    border-bottom: unset;
  }
  .ant-modal-footer {
    border-top: unset;
  }
  .ant-modal-body {
    padding: 0 24px;
  }
  .ant-modal-title {
    color: #161717 ;
  }
  .project-wrapper {
    border: 1px solid #F1F1F1;
    li{
      padding: 4px 12px;
      color: #161717 ;
    }
    li:hover {
      cursor: pointer;
      background-color: #EAF0FF;
      border-radius: 1px;
      color:  #576CB4;
    }
    li.active {
      background-color: #EAF0FF;
      border-radius: 1px;
      color:  #576CB4;
    }
  }
}