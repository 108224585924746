@mixin font-base($color:#333333, $size:18px, $lineHeight:25px, $weight:400, $fontFamily:(PingFang-SC-Regular,
        PingFang-SC)) {
    font: {
        size: $size;
        family: $fontFamily;
        weight: $weight;
    }

    color:$color;
    line-height:$lineHeight;
}

.enter-search {
    padding: 25px 16px;
    @include font-base();

    .entersearch-head {
        margin-bottom: 8px;

        .entersearch-head-fn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 17px;

            .entersearch-head-fn-return {
                cursor: pointer;

                .return-icon {
                    font-size: 16px;
                    color: #000000;
                    margin-right: 8px;
                }

                .return-txt {
                    @include font-base($size:16px, $lineHeight:22px);

                    .light {
                        color: #F03333;
                    }
                }
            }

            .entersearch-head-fn-input {
                .ant-input-affix-wrapper {
                    width: 310px;
                    height: 40px;

                    .ant-input-prefix {
                        left: 20px;

                        .anticon-search {
                            font-size: 11px;
                            color: #999999;
                        }
                    }

                    .ant-input {
                        padding-left: 38px;
                        border-radius: 20px;
                        border: 1px solid #999999;
                        background: none;
                    }
                }
            }
        }

        .entersearch-head-title {
            height: 60px;
            background: #FEFEFE;
            border-radius: 4px;

            h3 {
                margin-left: 16px;
                line-height: 60px;
            }
        }
    }

    .entersearch-content {
        table {
            // width: 100%;

            thead {
                th {
                    @include font-base($size:16px, $lineHeight:24px, $color:#6D6D6D, $weight:400, $fontFamily:(SourceHanSansCN-Regular, SourceHanSansCN));
                    min-width: 140px;
                    padding: 16px 0;
                    padding-right: 10px;

                    &:last-of-type {
                        width: 100%;
                    }
                }
            }

            tbody {
                @include font-base($weight:500, $color:#32323D, $fontFamily:(SourceHanSansCN-Regular, SourceHanSansCN));
                background-color: #FFFFFF;

                tr {
                    height: 100px;
                    cursor: pointer;

                    &:not(.none-active):hover {
                        background-color: #F0F4FA;

                        p.content {
                            color: #5B6BAE;
                        }
                    }

                    td {
                        padding-right: 10px;

                        &:first-child {
                            padding-left: 12px;
                        }

                        em {
                            font-style: normal;
                            color: #EC2323;
                        }

                        p.content {
                            @include font-base($size:14px, $lineHeight:21px, $weight:500, $color:#32323D, $fontFamily:(SourceHanSansCN-Regular, SourceHanSansCN));
                            margin-top: 7px;
                        }
                    }
                }
            }
        }
    }

    .entersearch-pagination {
        background-color: #fff;
        padding: 10px 0 34px 12px;

        .ant-pagination {
            &>li {
                min-width: 30px !important;
                width: 30px !important;
                height: 30px !important;

                &.ant-pagination-item-active {
                    background: none !important;
                    border-color: #5B6BAE !important;

                    &>a {
                        color: #5B6BAE !important;
                    }
                }

                &.ant-pagination-next {
                    margin-right: 0 !important;
                }

                &.ant-pagination-options {
                    width: auto !important;
                    margin-left: 8px !important;

                    input {
                        width: 30px;
                        height: 30px;
                    }
                }
            }

        }
    }
}