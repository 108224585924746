.qa-charts {
  // border: 1px solid #f1f1f1;
  // border-radius: 8px;
  .article {
    // float: none;
    // border: none;
    .chart {
      margin-top: 10px;
    }
  }
}
