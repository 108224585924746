@mixin font-base($color:#333333, $size:14px, $lineHeight:21px) {
    font: {
        size: $size;
        family: SourceHanSansCN-Regular,
            SourceHanSansCN;
        weight: 400;
    }

    color:$color;
    line-height:$lineHeight;
}

@mixin style-base {
    h3 {
        margin: 0;
        padding: 0;
    }
}

.entersinfo {
    @include style-base;
    @include font-base;
    min-width: 1200px;
    padding: 16px;
    display: flex;

    .entersinfo-left {
        width: 422px;
        margin-right: 16px;

        @mixin title {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @mixin icon {
            font-size: 10px;
            color: #999999;
            cursor: pointer;
            padding-right: 8px;
        }

        @mixin background-style {
            background: #FFFFFF;
            border-radius: 4px;
            margin-bottom: 16px;
            padding: 16px;
            padding-right: 8px;
        }

        .entersinfo-left-vocation {
            @include background-style;

            &.unexpand {
                .left-vocation-title {
                    margin-bottom: 0;
                }
            }

            .left-vocation-title {
                @include title;

                .left-vocation-title__icon {
                    @include icon();
                }
            }

            .left-vocation-labellist {
                max-height: 232px;
                overflow: hidden;

                &.expandlist {
                    height: auto;
                    max-height: none;
                }

                .left-vocation-labelitem {
                    display: inline-block;
                    height: 32px;
                    padding: 0 24px 0;
                    border-radius: 4px;
                    border: 1px solid #F4F4F4;
                    line-height: 32px;
                    cursor: pointer;
                    margin: 0 8px 8px 0;

                    @mixin active {
                        background: #F3F6FF;
                        border-radius: 4px;
                        border: 1px solid #C8D3FF;
                    }

                    &:hover {
                        @include active;
                    }

                    &.active {
                        @include active;
                    }
                }
            }
        }

        .entersinfo-left-createtime {
            @include background-style;
            width: 422px;
            height: 106px;

            .left-createtime-title {
                @include title;

                .left-createtime-title__icon {
                    @include icon();
                }
            }

            .left-createtime-date {
                width: 384px;
                height: 30px;

                .left-createtime-date__icon {
                    font-size: 16px !important;
                    right: 16px !important;
                }

                .anticon-close-circle {
                    right: 33px;
                }
            }
        }

        .entersinfo-left-province {
            @include background-style;


            &.unexpand {
                .left-province-title {
                    margin-bottom: 0;
                }
            }

            .left-province-title {
                @include title;

                .left-province-title__icon {
                    @include icon();
                }
            }

            .left-province-labellist {
                .left-province-labelitem {
                    display: inline-block;
                    width: 90px;
                    height: 32px;
                    border-radius: 4px;
                    border: 1px solid #F4F4F4;
                    line-height: 32px;
                    cursor: pointer;
                    margin: 0 8px 8px 0;
                    text-align: center;

                    .iconfont {
                        font-size: 12px;
                        color: #999999;
                        margin-right: 3px;
                    }

                    @mixin active {
                        background: #F3F6FF;
                        border-radius: 4px;
                        border: 1px solid #C8D3FF;
                    }

                    &:hover {
                        @include active;
                    }

                    &.active {
                        @include active;
                    }
                }
            }
        }
    }

    .entersinfo-right {
        @include font-base($lineHeight:20px);
        flex: 1;
        border-radius: 4px;

        .entersinfo-content {
            padding: 8px;
            background: #FFFFFF;

            .entersinfo-right-content {
                margin-bottom: 24px;

                .right-enter {
                    display: flex;
                    padding: 16px 8px;
                    cursor: pointer;

                    &:hover {
                        background-color: #F0F4FA;
                    }

                    .right-enter-img {
                        width: 80px;
                        height: 80px;
                        border-radius: 4px;
                        background-color: #5B6BAE;
                        margin-right: 21px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .right-enter-img__icon {
                            color: #FFFFFF;
                            font-size: 52px;
                        }
                    }

                    .right-enter-info {
                        h3 {
                            font-size: 18px;
                            display: flex;
                            align-items: center;
                            margin-bottom: 24px;

                            span {
                                display: inline-block;
                                height: 18px;
                                border-radius: 2px;
                                border: 1px solid #74D262;

                                font-size: 11px;
                                line-height: 17px;
                                color: #68AE5B;
                                margin-left: 8px;
                                padding: 0 8px;
                            }
                        }

                        .right-enterinfo-tb {
                            tr {
                                td {
                                    min-width: 235px;

                                    .right-enterinfo-tb__title {
                                        color: #999999;

                                    }

                                    .right-enterinfo-tb__content {

                                        &.level1 {
                                            color: #B70303;
                                        }

                                        &.level2 {
                                            color: #FC3838;
                                        }

                                        &.level3 {
                                            color: #FDCD31;
                                        }

                                        &.level4 {
                                            color: #74D262;
                                        }

                                        &.level5 {
                                            color: #1C9006;
                                        }


                                    }
                                }
                            }
                        }
                    }
                }
            }

            .entersinfo-right-pagination {
                margin-bottom: 32px;

                .right-pagination-elem {
                    li {

                        &.ant-pagination-prev,
                        &.ant-pagination-item,
                        &.ant-pagination-next {
                            width: 30px !important;
                            height: 30px !important;
                        }

                        &:hover {
                            background-color: #FFFFFF !important;
                            border-color: #5B6BAE !important;

                            a {
                                color: #5B6BAE !important;
                            }
                        }

                        &.ant-pagination-item-active {
                            background-color: #FFFFFF !important;
                            border-color: #5B6BAE !important;

                            a {
                                color: #5B6BAE !important;
                            }
                        }
                    }
                }
            }
        }
    }
}