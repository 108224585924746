@mixin font-base($color:#333333, $size:14px, $lineHeight:21px) {
    font: {
        size: $size;
        family: SourceHanSansCN-Regular,
            SourceHanSansCN;
        weight: 400;
    }

    color:$color;
    line-height:$lineHeight;
}

.enterdetail {
    @include font-base();
    padding: 16px 36px 16px 16px;
    min-width: 1200px;
    height: calc(100vh - 70px);
    overflow: auto;

    .enterdetail-left {
        width: 232px;
        float: left;
        margin-right: 16px;

        .enterdetail-left-nav {
            @include font-base($size:20px, $lineHeight:30px);
            height: 240px;
            background: #FFFFFF;
            border-radius: 4px;
            padding: 17px 0;

            ul {
                li {
                    cursor: pointer;
                    height: 50px;
                    padding-left: 54px;
                    line-height: 50px;

                    &:hover,
                    &.active {
                        background-color: #5B6BAE;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .enterdetail-left-return {

            background: #FFFFFF;
            margin-bottom: 10px;
            padding-bottom: 20px;

            .enterdetail-left-return__btn {
                @include font-base($size:12px, $lineHeight:30px, $color:#5B6BAE);
                width: 130px;
                height: 30px;
                border-radius: 16px;
                border: 1px solid #5B6BAE;
                text-align: center;
                margin: 0 auto;
                cursor: pointer;

                span.icon {
                    font-size: 11px;
                    margin-right: 3px;
                }
            }
        }

        .enterdetail-left-search {
            .ant-input-search {
                width: 232px;
                height: 30px;
            }
        }
    }


    .enterdetail-right {
        margin-left: 248px;

        .detailright-timeline {
            margin-bottom: 32px;
            background: #FEFEFE;
            border-radius: 4px;
            position: relative;

            .timeline-point {
                position: absolute;
                display: inline-block;
                height: 50px;
                width: 44px;
                line-height: 50px;
                text-align: center;
                font-size: 12px;
                color: #E3E3E3;
                cursor: pointer;

                &:hover {
                    color: #5B6BAE;
                }

                &.left {
                    left: 0;
                }

                &.right {
                    right: 0;
                }
            }

            .detailright-timeline-content {
                display: flex;
                overflow: auto;

                &::-webkit-scrollbar {
                    display: none
                }

                .detailright-timeline-container {
                    display: flex;

                    ul {
                        display: flex;
                        justify-content: space-between;
                        margin: 0;
                        padding: 0 55px;
                        pointer-events: none;

                        &.event-open {
                            pointer-events: auto;
                        }

                        li {
                            cursor: pointer;
                            height: 50px;
                            line-height: 50px;
                            color: #6D6D6D;

                            &:hover {
                                color: #5B6BAE;
                            }

                            &.active {
                                color: #5B6BAE;
                                border-bottom: 1px solid #5B6BAE;
                            }
                        }
                    }
                }



            }
        }

        .detailright-content {}
    }
}