.add-tag {
  height: 24px;
  width: 58px;
  margin-bottom: 8px;

  .btn-status {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1px dashed #D9D9D9;
    font-size: 12px;
    color: #4A4A4A;
    cursor: pointer;
  }

  .add-icon {
    color: #4A4A4A;
    font-size: 12px;
    margin-right: 4px;
  }

  .input-status {
    width: 100%;
    height: 100%;
    font-size: 12px !important;
    padding: 0 8px;
    border-radius: 2px;
  }
}
