.plan-detail-head {

    .detail-fun {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 23px;
        height: 32px;

        &>h5 {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: rgba(0, 0, 0, 0.8);
            padding: 0;
            margin: 0;
            margin: 0 0 0 7px;
        }
    }

    .plan-head-info {
        .ant-descriptions-view {
            border: 1px solid rgba(243, 243, 243, 1);
            border-radius: 0;

            .ant-descriptions-row {
                td.ant-descriptions-item {
                    padding: 0;
                    margin: 0;
                    min-height: 40px;

                    span {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        font-size: 14px;
                        font-family: MicrosoftYaHei;
                        color: rgba(51, 51, 51, 1);
                    }

                    &>span {
                        padding: 5px 2px;
                    }
                }

                &:first-child {
                    td.ant-descriptions-item {
                        min-height: 50px;
                        background: rgba(240, 244, 250, 1);
                    }
                }

            }
        }
    }
}