.advanced-search{
    position: relative;
    .ant-input {
        height: 30px;
    }
    .search-icon {
        cursor: pointer;
    }
    .history {
        top: 40px;
        right: 0;
        width: 282px;
        background-color: #fff;
        box-shadow: 0px 0px 5px 1px #F1F1F1;
        z-index: 100;
        position: absolute;
        max-height: 612px;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-thumb{
            background: #ccc;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        .history-item {
            color: #333;
            padding: 7px 13.5px 9px 9px;
            .search-text {
                margin-left: 6px;
            }
            h6 {
                color: #9D9D9D;
                font-size: 12px;
                margin-bottom: 6px;
                margin-top: unset;
            }
            img {
                margin-right: 9px;
            }
            li {
                display: flex;
                margin-top: 5px;
                border-radius: 2px;
                padding: 5px 14px 5px 7px;
                cursor: pointer;
                align-items: center;
                position: relative;
                img {
                    margin-top: 2px;
                    display: block;
                }
                .del-history-item {
                    display: none;
                }
                &:hover {
                    background-color: #EDEEEE;
                    .del-history-item {
                        position: absolute;
                        display: block;
                        right: 15px;
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;
                        img {
                            position: absolute;
                            left: 15%;
                            top: 5%;
                        }
                        &:hover {
                            background-color: rgba(31,35,41,0.1);
                        }
                    }
                }
            }
            li:first-child {
                margin-top: unset;
            }
        }
    }
}
  