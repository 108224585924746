.subject-graph {
  display: flex;
  flex-direction: column;

  .ant-tabs-nav-container {
    background-color: #fff;
    padding-left: 230px;
  }

  .subject-graph-body {
    padding: 0px 24px 20px 18px;
    display: flex;

    #graph-main {
      flex: 1;
      background-color: #fff;
      height: calc(100vh - 140px);
      min-height: 500px;
    }

    .graph-right {
      padding: 8px 19px;
      box-sizing: border-box;
      width: 430px;
      margin-left: 28px;
      background-color: #fff;
      height: calc(100vh - 140px);
      overflow: scroll;

      .rela-node {
        margin-bottom: 10px;
      }

      .attrs-container {
        border-radius: 4px;
        border: 1px solid #e1e7ff;
        padding: 4px 16px 22px;
        line-height: 26px;
        color: #535561;
        font-size: 12px;
        margin-bottom: 20px;

        .key {
          font-weight: bold;
        }
      }
    }
  }
}
