#theChart {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .d3-force-hover{
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        border-style: solid;
        white-space: nowrap;
        z-index: 9999999;
        transition: left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
        background-color: rgba(50, 50, 50, 0.7);
        border-width: 0px;
        border-color: rgb(51, 51, 51);
        border-radius: 4px;
        color: rgb(255, 255, 255);
        font: 14px / 21px "Microsoft YaHei";
        padding: 5px;
    }
    @keyframes nodeAnimation {
        0% {
            //transform: scale(1);
            stroke-width: 2;
        }
        50% {
            //transform: scale(0.99);
            stroke-width: 8;
        }
        100% {
            //transform: scale(1);
            stroke-width: 2;
        }
    }

    .node-circle{
        stroke-width: 2;
        &.value-node-loading{
            animation: nodeAnimation .8s infinite;
            //transform-origin: 500px 500px;
        }
        &.relation-node-loading{

        }
    }
    /*.entity2{
        cursor: alias;
    }*/
}

.graph-empty{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cursor-module{
    #theChart {
        .node-circle {
            cursor: copy;
        }
    }
}