.my-search {
  position: relative;

  & > span {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: rgba(0, 0, 0, 0.8);
    margin-right: 10px;
  }

  .relate-select {
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.14);
    position: absolute;
    z-index: 1;
    left: 38px;
    right: 10px;
    margin-top: 8px;

    .select-item {
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;

      .select-item-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }

      &:hover {
        background-color: rgb(238, 245, 254);
      }
    }
  }
}
