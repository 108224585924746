.search-leftlist {

    .leftlist-content {
        background: #FFFFFF;
        border-radius: 4px;
        padding: 16px;

        &.no-child {
            padding: 0;
        }

        .leftlist-item {
            //margin-bottom: 24px;
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            .leftlist-item-title {
                font-family: PingFangSC-Regular,
                    PingFang SC;
                font-weight: 400;
                text-decoration: none;

                &:visited {
                    &>h3 {
                        //color: #5B6BAE;
                        color: #825BAE;
                    }
                }

                &>h3 {
                    font-size: 16px;
                    color: #5B6BAE;
                    margin: 0;
                    padding: 0;
                    margin-bottom: 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &>cite {
                    font-size: 14px;
                    color: #909090;
                    font-style: normal;
                    margin-bottom: 4px;
                }
            }

            .leftlist-item-content {
                font-size: 12px;
                font-family: PingFangSC-Regular,
                    PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 16px;

                &>em {
                    color: #EC2323;
                    font-style: normal;
                }

                &>time {
                    color: #909090;
                    word-spacing: -0.15rem;

                    &::after {
                        content: " ∙ ";
                        word-spacing: normal;
                    }
                }

                &.item-content-elli {
                    overflow: hidden;
                    position: relative;
                    max-height: 60px;

                    &::after {
                        content: "...";
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        padding-left: 17px;
                        background: -webkit-linear-gradient(left, transparent, #fff 75%);
                        background: -o-linear-gradient(right, transparent, #fff 75%);
                        background: -moz-linear-gradient(right, transparent, #fff 75%);
                        background: linear-gradient(to right, transparent, #fff 75%);
                    }
                }
            }
        }
    }

    .leftlist-load {
        background: #FFFFFF;
        border-radius: 3px;
        margin-top: 14px;
        margin-bottom: 38px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 50px;

        .leftlist-load-txt {
            font-size: 14px;
            font-family: PingFangSC-Regular,
                PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-bottom: 3px;
        }

        .leftlist-load-icon {
            color: #909090;
            font-size: 14px;
        }
    }
}