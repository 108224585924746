#col-m {
  .tab-cnt {
    padding: 15px;
    line-height: 24px;
  }
  .tab-list {
    margin: 15px 0;
  }
  .radis-border {
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .tab-inner {
    background-color: #f7f8fc;
    border-radius: 5px 5px 0 0;
  }
  .tab {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 15px;
    margin-right: 18px;
    cursor: pointer;
    white-space: nowrap;
    &:hover {
      border-color: #cdcdcd;
    }
    &.on {
      border-color: #3151ff;
    }
  }
  .sub-title span {
    border-left: 6px solid #3151ff;
    padding: 0 10px;
    font-weight: bold;
    font-size: 16px;
  }
  .deal {
    margin: 22px 0;
  }
  .sbtn {
    border-radius: 3px;
    padding: 4px 17px;
    cursor: pointer;
    white-space: nowrap;
    background-color: #3151ff;
    color: #fff;
    display: inline-block;
    min-width: 80px;
    text-align: center;
  }
  .sbtn.small {
    padding: 5px 15px;
    min-width: 60px;
  }
  .sbtn.warning {
    background-color: #f5a158;
  }
  .sbtn:hover {
    opacity: 0.85;
  }
  .line-title {
    margin: 30px 0 10px 0;
  }
  .line-title span {
    font-weight: bold;
    color: #434343;
    font-size: 16px;
  }

  .inner-title {
    width: auto;
  }
  .inner-title .opt {
    display: inline-block;
  }
  .inner-title .opt em {
    font-style: normal;
    font-weight: bold;
  }

  #highlighter .highlight-mengshou-wrap {
    background: #ffe184;
    cursor: pointer;
    padding: 5px 38px 7px 5px;
    position: relative;
    overflow: visible;
    margin-right: 6px;
    display: inline-block;
    margin-bottom: 15px;
    font-size: 15px;
    word-wrap: break-word;
    white-space: pre-wrap;
    color: #434343;
    font-weight: bold;
    margin-left: 6px;
    &.on {
      border: 2px solid #ffc178;
    }
    &:hover .my-remove-tip {
      visibility: visible;
    }
  }
  .word-slot-attr {
    position: absolute;
    color: #767676;
    font-size: 12px;
    bottom: -2px;
    right: 4px;
    color: #583fcf;
    font-weight: bold;
    font-family: 'Roboto Condensed', 'Arial Narrow', sans-serif;
  }
  .my-remove-tip {
    visibility: hidden;
    box-sizing: border-box;
    position: absolute;
    border-radius: 100%;
    height: 15px;
    width: 14px;
    color: #fff;
    background: #444;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    line-height: 15px;
    overflow: visible;
    left: -4px;
    top: -8px;
  }

  .graph {
    background-color: #f7f8fc;
    padding: 0;
    height: 420px;
    .graph-inner {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }

  .tag-list {
    .tag {
      margin-right: 10px;
      cursor: pointer;
      display: inline-block;
      max-width: 188px;
      white-space: nowrap;
      border: 1px solid #ddd;
      border-radius: 3px;
      padding: 0px 6px;
      b {
        display: inline-block;
        max-width: 168px;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
      }
      &:hover {
        color: #3151ff;
        border-color: #cdcdcd;
      }
      &.on {
        color: #3151ff;
        border-color: #3151ff;
      }
      em {
        margin-left: 10px;
        font-style: normal;
        font-size: 20px;
        vertical-align: middle;
        &:hover {
          color: #f5a158;
        }
      }
    }
  }
}
