// 查看全文弹窗
.home-view-article-modal{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  .view-modal-content{
    //width: 608px;
    //height: 456px;
    width: 90%;
    height: 90%;
    min-width: 800px;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 2px rgba(222, 222, 222, 0.5);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  .view-modal-title{
    height: 51px;
    margin: 0;
    background: #F8FAFF;
    line-height: 51px;
    padding: 0 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: rgba(0, 0, 0, 0.8);
    position: relative;
  }
  .view-modal-close{
    position: absolute;
    height: 25px;
    width: 25px;
    top: 13px;
    right: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    color: #737373;
  }
  .view-modal-text{
    flex: 1;
  }
}
