$btnColor: #5b6bae;
$btnHover: #7882aa;

.public-header {
  width: 100%;
  //min-width: 1200px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 1px rgba(237, 237, 237, 1);
  //padding: 0 10px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #DDDDDD;

  /*&.search-header {
    height: 80px;
  }*/

  .public-header-style {
    position: relative;
    width: 100%;
    height: 100%;
    //padding: 0 20px;
  }

  .public-header__main {
    height: 100%;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    //padding: 0 20px;
    padding: 0 20px 0 10px;
    min-width: 930px;

    .public-header__left-menu {
      // width: 30px;
      // height: 30px;
      // line-height: 30px;
      display: flex;
      align-items: center;
    }

    .left-menu-btn {
      width: 30px;
      height: 30px;
      line-height: 30px;
      //background: $btnColor;
      //border-color: $btnColor;
      border-radius: 4px;
      padding: 0;
      color: #fff;
      margin-right: 25px;

      &:hover {
        background: $btnHover;
        border-color: $btnHover;
      }
    }

    .center-search-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      // padding-left: 14px;
      //margin-left: 27px;
      margin-left: 16px;
      margin-right: 28px;
      height: 30px;
      line-height: 30px;
      //background: url("../../../assets/phbs.png") left center no-repeat;
      //background-size: 101px 27px;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #333333;

      .logo-click {
        display: block;
        width: 101px;
        height: 27px;
        //margin-right: 21px;
        margin-right: 16px;
        background: url("../../assets/phbs.png") left center no-repeat;
        background-size: 101px 27px;
        cursor: pointer;

      }
    }

    // .center-search-logo {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   // padding-left: 14px;
    //   margin-left: 29.3px;
    //   height: 30px;
    //   // line-height: 30px;
    //   //background: url("../../../assets/phbs.png") left center no-repeat;
    //   //background-size: 101px 27px;
    //   font-family: PingFangSC-Regular;
    //   font-size: 16px;
    //   color: #333333;

    //   .logo-click {
    //     display: block;
    //     // width: 101px;
    //     // height: 27px;
    //     // background: url("../../assets/phbs.png") left center no-repeat;
    //     // background-size: 101px 27px;
    //     cursor: pointer;

    //     .logo-click-icon {
    //       font-size: 24px;
    //       margin-right: 8.3px;
    //       color: #1E6CBA;
    //     }
    //   }
    // }

    .public-header__center-search {
      //min-width: 540px;
      //position: absolute;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);

      .header-cycle-question {
        width: 24px;
        height: 22px;
        display: inline-block;
        margin-left: 16px;
        cursor: pointer;

        &>img {
          width: 24px;
          height: 22px;
          color: #5B6BAE;
        }
      }

      .input-search-icon {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        font-size: 12px;
        color: #999;
        top: 6px;
        right: 7px;
        z-index: 2;
        cursor: pointer;
      }

      .ant-select-selection--single {
        cursor: text;
      }

      .ant-select-selection-selected-value {
        opacity: 1 !important;
      }

      .ant-input-prefix {
        left: 0;
      }

      //.ant-input {
      //  padding-left: 109px;
      //}
      .public-header__search-select {
        line-height: 30px;
        position: relative;

        &:after {
          display: block;
          content: "";
          width: 2px;
          height: 12px;
          position: absolute;
          top: 9px;
          right: 0;
          background: #979797;
        }

        display: flex;
        align-items: center;

        .ant-select {
          line-height: 30px;

          .ant-select-selection {
            border: none;
            background: transparent;
            height: 30px;
            box-shadow: none;
            cursor: pointer;

            &:active,
            &:focus {
              border: none;
            }
          }

          .ant-select-selection__rendered {
            height: 30px;
            width: 100%;
            margin: 0;

            .ant-select-selection-selected-value {
              line-height: 30px;
              height: 30px;
              font-size: 14px;
              font-family: PingFangSC-Regular;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              width: 100%;
              padding-right: 20px;
              //padding-left: 10px;
              text-align: center;
            }
          }

          .ant-select-arrow {
            color: rgba(51, 51, 51, 1);
            top: 17px;
            right: 9px;
            font-size: 10px;
          }
        }
      }

      .graph-search-list {
        display: flex;
        margin: 4px 0 0;

        .graph-search-item {
          padding: 0 36px;
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          color: #333;
          border-radius: 4px;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 33px;
          }

          &.active-item {
            background: #5B6BAE;
            color: #fff;
          }
        }
      }

      .search-drop-list-box {}

      .search-drop-list {
        position: absolute;
        z-index: 999;
        top: 30px;
        left: 0;
        width: 100%;
        margin-top: 5px;
        background: #fff;
        box-shadow: 0px 1px 10px 0px rgba(235, 235, 235, 0.5);
        border-radius: 4px;
        max-height: 150px;
        overflow: auto;

        .search-drop-item {
          padding: 0 15px;
          height: 30px;
          line-height: 30px;
          overflow: hidden;
          color: rgba(0, 0, 0, 0.65);
          font-weight: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;

          //transition: background 0.3s ease;
          // &:hover{
          //   background: $btnColor;
          //   color: #fff;
          // }
          &.search-drop-item-hover {
            background: $btnColor;
            color: #fff;
          }
        }
      }
    }

    .center-search-input {
      //width: 330px;
      height: 30px;

      .ant-input {
        border-color: #f6f6f6;
        background: #f6f6f6;
        border-radius: 4px;
        height: 30px;
        line-height: 30px;
        font-family: PingFangSC-Regular;
        font-size: 14px;

        //padding-left: 109px;
        &:hover {
          border-color: $btnColor;
        }

        &:focus {
          border-color: $btnColor;
        }
      }
    }

    .public-header__right-login {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }

    .right-login-login {
      width: 60px;
      height: 30px;
      padding: 0;
      border: 1px solid $btnColor;
      border-radius: 4px;
      color: $btnColor;
      //margin-right: 17px;

      &:hover {
        color: #fff;
        border-color: $btnHover;
        background: $btnHover;
      }
    }

    .right-login-register {
      width: 60px;
      height: 30px;
      padding: 0;
      background: $btnColor;
      border-color: $btnColor;
      border-radius: 4px;
      color: #fff;

      &:hover {
        border-color: $btnHover;
        background: $btnHover;
      }
    }
  }

  .header-left {
    color: $btnColor;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
      color: $btnHover;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}

.public-header-left-menu {
  position: fixed;
  left: 0;
  z-index: 999;

  &.search-header {
    height: calc(100vh - 80px);

    .ant-drawer-mask {
      top: 80px;
    }
  }

  .ant-drawer-mask {
    top: 0;
  }

  .left-menu-li{
    .drawer-left-menu-item{
      margin-bottom: 8px;
    }
    &:hover {
      .left-menu-item-icon,.left-menu-item-label{
        color: $btnColor;
      }
      .drawer-left-menu-item-dropIcon{
        color: $btnColor;
      }
    }
    .drawer-left-menu-item{
      &:hover {
        color: $btnColor;
      }
    }
  }
  .drawer-left-menu{
    .drawer-left-menu-item-min{
      &:hover{
        color: $btnColor;
      }
    }
  }

  .drawer-left-menu {
    .drawer-left-menu-item {
      padding-left: 30px;
      height: 40px;
      display: flex;
      align-items: center;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #333333;
      position: relative;
      cursor: pointer;
      //transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &.select-menu-item {
        background: #f0f4fa;
        color: $btnColor;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        /*&:before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 6px;
          height: 100%;
          background: $btnColor;
        }*/
        &:after {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 4px;
          height: 100%;
          background: $btnColor;
        }
      }

      .left-menu-item-icon {
        display: block;
        flex-shrink: 0;
        width: 18px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 7px;

        .nav_icon {
          font-size: 16px;
          &.icon-nav_inst {
            font-size: 13px;
          }
        }
      }

      .left-menu-item-label {
        max-width: 110px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      &.upload-item {
        padding-left: 0;

        >span {
          display: block;
          width: 100%;
          height: 100%;

          .ant-upload {
            width: 100%;
            height: 100%;
          }

          .upload-box {
            width: 100%;
            height: 100%;
            line-height: 52px;
            padding-left: 57px;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #333333;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
        }

        &:hover {
          .upload-box {
            color: #fff;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
        }
      }
    }

    .drawer-left-menu-item-min {
      min-height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #333333;
      position: relative;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      margin-bottom: 8px;

      &.select-menu-item {
        background: #f0f4fa;
        color: #5B6BAE;

        &:before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 4px;
          height: 100%;
          background: $btnColor;
        }
      }

      .left-menu-item-icon {
        display: block;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
      }
      .nav_icon {
        font-size: 14px;
      }

      .left-menu-item-label {
        max-width: 90px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 20px;
        line-height: 20px;
      }

      .useCenter-min-card {
        >span {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      &:hover{
        color: $btnColor;
      }
    }

    .drawer-left-menu-item-dropIcon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      line-height: 12px;
      font-size: 8px;
      color: #333;
      right: 30px;
      top: 15px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &.up-icon {
        transform: rotate(180deg);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    .drawer-left-menu-child {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      &.show-child {
        height: auto;
      }

      &.hidden-child {
        height: 0;
        overflow: hidden;
      }
    }
  }

  .ant-drawer-content-wrapper {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.search-select-item {
  .ant-select-dropdown-menu-item {
    padding: 5px;
    text-align: center;
  }
}

.user-center-popover {
  left: 110px !important;
  top: 0 !important;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .min-left-menu-popover {
    padding: 20px 0;
    width: 140px;

    .min-left-menu-popover-item {
      font-size: 14px;
      color: #333;
      height: 40px;
      line-height: 40px;
      width: 140px;
      padding: 0 30px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;

      &:hover {
        background: #5B6BAE;
        color: #fff;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &.active-popover-item {
        background: rgba(240, 244, 250, 1);
        position: relative;

        &:hover {
          color: #333;
        }

        &:after {
          display: block;
          content: '';
          width: 4px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background: #5B6BAE;
        }
      }
    }
  }
}

.public-header__search-filter {
  padding: 0;
  width: 500px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    box-shadow: 0px 0px 8px 1px rgba(228, 228, 228, 0.5);
  }

  .ant-popover-inner-content {
    padding: 12px 0;
    display: flex;
    flex-direction: column;

    ul.search-filterlist {
      margin: 0;

      li {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular,
          SourceHanSansCN;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);

        cursor: pointer;
        display: flex;
        align-items: center;

        height: 24px;

        &:hover {
          background-color: #E1E7FF;
        }

        .search-filter-icon {
          font-size: 20px;
          color: #979797;
          margin: 0 5px;
        }
      }
    }
  }
}