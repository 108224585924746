.home-cycleque-drawer {
    &.home-cycleque-drawer__hide {
        .ant-drawer-content-wrapper {
            right: 0;
        }
    }

    .ant-drawer-content-wrapper {
        border-radius: 4px;
        right: 20px;
        width: 420px !important;
        height: 489px !important;
        bottom: 20px;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);

        .ant-drawer-header {
            position: absolute;
            width: 100%;
            height: 45px;
            padding: 0;
            text-align: center;

            .ant-drawer-title {
                font-size: 12px;
                font-family: PingFangSC-Regular,
                    PingFang SC;
                font-weight: 400;
                color: #909090;
                line-height: 45px;
            }

            .ant-drawer-close {
                width: 45px;
                height: 45px;
                line-height: 45px;
                font-size: 12px;
            }
        }

        .ant-drawer-body {
            padding: 0 10px;
            margin-top: 50px;

            .home-cycleque-exhibition {
                height: 318px;
                border-bottom: 1px solid #F3F3F3;
                overflow: auto;

                &>ul {
                    display: flex;
                    flex-direction: column;

                    li.cycleque-exhibition-item {
                        width: auto;
                        height: auto;
                        max-width: 390px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular,
                            PingFang SC;
                        font-weight: 400;
                        line-height: 20px;
                        padding: 8px 15px;
                        margin-bottom: 22px;
                        word-break: break-all;

                        &.cycleque-exhibition-questioner {
                            background: rgba(244, 244, 244, 1);
                            border-radius: 22px 22px 0px 22px;
                            color: #333333;
                            align-self: flex-end;
                        }

                        &.cycleque-exhibition-answerer {
                            background: rgba(91, 107, 174, 1);
                            border-radius: 22px 22px 22px 0px;
                            color: #FFFFFF;
                            align-self: flex-start;

                            a {
                                color: #FFFFFF;
                                margin-left: 10px;
                                display: inline-block;
                                height: 20px;
                                border-bottom: 1px solid #fff;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            .home-cycleque-expresser {
                padding-top: 8px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .cycleque-expresser-area {
                    border: none;
                    font-size: 14px;
                    font-family: PingFangSC-Regular,
                        PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    padding: 0 10px;
                    box-shadow: none;
                    margin-bottom: 8px;
                }

                .cycleque-expresser-btn {
                    width: 49px;
                    height: 26px;
                    border-radius: 13px;
                    border: 1px solid #5B6BAE;
                    line-height: 26px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular,
                        PingFang SC;
                    font-weight: 400;
                    color: #5B6BAE;
                    padding: 0;
                }
            }
        }
    }
}