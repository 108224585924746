@mixin transitionEffect {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.leftset-property {
    margin-bottom: 16px;
    padding-top: 2px;
    border-top: 1px solid rgba(236, 236, 236, 1);

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 11px;

        .title-legend {
            font-size: 14px;
            font-family: PingFangSC-Regular,
                PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 17px;

            .list-tips {
                font-size: 10px;
                color: #909090;
                line-height: 14px;
            }
        }
    }

    .title-progress {
        .ant-progress-text {
            font-size: 14px;
            color: #333;
        }
    }

    .set-list {
        padding-top: 4px;
    }

    .list-tips {
        line-height: 14px;
        font-size: 10px;
        color: #909090;
    }

    .set-list-main {
        margin-top: 8px;

        .list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;

            .item {
                margin-right: 8px;
                margin-bottom: 8px;
                width: 48%;
                height: 48px;
                background: #FAFBFC;
                border-radius: 4px;
                line-height: 48px;

                &.item-active {
                    height: auto;
                    line-height: 20px;
                    margin-right: 0;
                }

                &.item-extent {
                    visibility: hidden;
                }

                .title-info {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 10px;

                    .item-title {
                        font-size: 14px;
                        font-family: PingFangSC-Regular,
                            PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 160px;

                        &.hidden {
                            height: 0;
                            visibility: hidden;
                            @include transitionEffect;
                        }
                    }

                    .item-operator {
                        display: flex;
                        align-items: center;

                        &.hidden {
                            height: 0;
                            visibility: hidden;
                            @include transitionEffect;
                        }

                        .item-value {
                            display: block;
                            padding: 0 16px;
                            cursor: pointer;
                            height: 30px;
                            line-height: 30px;
                            font-size: 12px;
                            color: #333;
                            @include transitionEffect;
                            background: #CCEBA4;
                            border-radius: 4px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            max-width: 160px;
                        }

                        .item-operator-elli {
                            padding-right: 10px;
                            cursor: pointer;

                            .item-operator-icon {
                                margin-left: 10px;
                                font-size: 9px;
                                color: #909090;
                            }
                        }
                    }

                    .item-info {
                        display: block;
                        margin-bottom: 8px;
                        @include transitionEffect;

                        &.hidden {
                            height: 0;
                            visibility: hidden;
                            @include transitionEffect;
                        }
                    }
                }


            }
        }
    }

    .set-list-loading {
        text-align: center;
        cursor: pointer;

        .set-list-icon {
            margin-bottom: 17px;
            color: #909090;
            font-size: 7px;
        }
    }
}