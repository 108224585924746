.summary-list {
    margin: 24px;
    padding: 16px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10px 1px rgba(249, 249, 249, 1);
    border-radius: 4px;

    min-height: calc(100vh - 109px);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .summary-btn {
        min-width: 80px;
        padding: 0 11px;
        height: 28px;
        border-radius: 2px;

        &.panel-inner {
            height: 24px;
        }

        &.back {
            border: 1px solid rgba(102, 118, 183, 1);
            color: rgba(102, 118, 183, 1);
            margin-right: 16px;

            &:hover {
                border: 1px solid rgba(102, 118, 183, .7);
                color: rgba(102, 118, 183, .7);
            }
        }
    }


    .summary-panel {

        .ant-collapse {
            background: none;

            .ant-collapse-item {
                border: 0;
                margin-bottom: 23px;

                .summary-panel-chk {
                    margin-right: 16px;

                    .ant-checkbox {
                        .ant-checkbox-inner {
                            width: 12px;
                            height: 12px;
                            border-radius: 1px;
                            border-color: rgba(91, 107, 174, 1);
                        }

                        &.ant-checkbox-checked {
                            .ant-checkbox-inner {
                                background-color: rgba(91, 107, 174, 1);

                                &::after {
                                    width: 4px;
                                }
                            }
                        }

                        &.ant-checkbox-indeterminate {
                            .ant-checkbox-inner::after {
                                background-color: rgba(91, 107, 174, 1);
                            }
                        }
                    }
                }

                .ant-collapse-header {
                    padding: 0 40px 0 0;

                    .summary-panel-icon {
                        color: rgba(204, 204, 204, 1);
                    }

                }

                .ant-collapse-content {
                    background: rgba(248, 249, 255, 1);
                    border-radius: 4px;
                    margin-top: 14px;

                    .ant-collapse-content-box {
                        padding: 6px 17px 19px;

                        &>textarea {
                            border: 0;
                            padding: 0px;
                            background: rgba(248, 249, 255, 1);
                            line-height: 30px;
                            font-family: MicrosoftYaHei;
                            color: rgba(0, 0, 0, 0.8);

                            &:focus {
                                box-shadow: none
                            }
                        }
                    }
                }

                .summary-panel-head {
                    display: flex;
                    align-items: center;

                    .summary-panel-chk {
                        padding-top: 1px;
                    }

                    .summary-panel-title {
                        padding: 0;
                        margin: 0;
                        margin-right: 16px;
                        font-size: 16px;
                        font-family: MicrosoftYaHei;
                        color: rgba(0, 0, 0, 0.8);
                    }
                }
            }
        }

        .summary-panel-text {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: rgba(0, 0, 0, 0.8);
            line-height: 30px;
        }
    }

    .summary-btngroup {
        position: sticky;
        bottom: 0;
        background-color: #fff;
        padding: 10px 0 24px 0;
    }
}