.financial-result{
    min-width: 1200px;
    .left-menu{
        >.ant-menu{
            >li{
                font-weight: 600;
            }
            .ant-menu{
                font-weight: 400;
            }
        }
        .ant-menu-submenu-title{
            &:hover{
                color: #333;
                .ant-menu-submenu-arrow{
                    &:before,&:after{
                        background: linear-gradient(to right, #333, #333);
                    }
                }
            }
        }
        .ant-menu-item{
            &:before{
                position: absolute;
                top: 0;
                left: 0;
                width: 6px;
                height: 100%;
                display: block;
                content: '';
                -webkit-transform: scaleY(0.0001);
                transform: scaleY(0.0001);
                opacity: 0;
                transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
            &:after{
                display: none;
            }
            &:hover{
                background: #F0F4FA;
                color: #333;
            }
        }
        .ant-menu-item-selected{
            background: #F0F4FA;
            position: relative;
            color: #333;
            &:before{
                background: #5B6BAE;
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                opacity: 1;
                transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
        }
    }
}