.plan-display {
    margin-top: 37px;

    .detail-fun-btngroup {
        position: absolute;
        right: 40px;
        top: 100px;

        .detail-fun-btn {
            width: 104px;
            height: 32px;
            border-radius: 2px;
            margin-right: 24px;

            &:last-child {
                margin-right: 0;
            }

            &.example {
                background: none;
                border: 1px solid rgba(91, 107, 174, 1);
                color: rgba(91, 107, 174, 1);

                &:hover {
                    border: 1px solid rgba(91, 107, 174, .7);
                    color: rgba(91, 107, 174, .7);
                }
            }
        }
    }

    .plan-display-tree {
        .ant-collapse {
            background: none;
        }

        .ant-collapse-item {
            border: none
        }

        .ant-collapse-header {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: rgba(0, 0, 0, 0.8);
        }

        &>.ant-collapse {
            &>.ant-collapse-item {
                &>.ant-collapse-header {
                    font-weight: bold;
                }
            }
        }
    }
}