.lesson-plan-edit {
    margin-top: 37px;

    .plan-edit-tree {
        .ant-collapse {
            background: none;
        }

        .ant-collapse-item {
            border: none
        }

        .ant-collapse-header {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: rgba(0, 0, 0, 0.8);
        }

        &>.ant-collapse {
            &>.ant-collapse-item {
                &>.ant-collapse-header {
                    font-weight: bold;
                }
            }
        }
    }

    .plan-edit-btngroup {
        position: absolute;
        margin-top: 50px;
        margin-bottom: 35px;
        margin-left: -16px;

        .plan-edit-btn {
            min-width: 104px;
            height: 32px;
            border-radius: 2px;

            &.cancel {
                background: none;
                border: 1px solid rgba(184, 184, 184, 1);
                margin-right: 24px;

                &:hover {
                    background: rgba($color: #fff, $alpha: .3);
                    color: rgba(51, 51, 51, .7);
                }
            }
        }
    }
}