.research-body {
    .body-top {
        height: 95px;
        line-height: 95px;

        .top-nav {
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #909090;
            text-align: center;
        }
    }

    .body-content {
        .body-pdf {
            float: left;
            box-shadow: 0 0 10px 1px #F1F1F1;
        }

        .body-right {
            display: inline-block;

            .right-keyword {
                background-color: #fff;
                border-radius: 6px;
                margin-bottom: 15px;

                .keyword-title {
                    font-family: PingFangSC-Regular;
                    font-size: 16px;
                    color: #333333;
                    padding: 21px 0 0 18px;
                }

                .keyword-cloud {
                    width: 80%;
                    margin: 0 auto;
                }
            }

            .right-klogger {
                background-color: #fff;
                border-radius: 6px;
                padding: 0;

                .affix-item {
                    background-color: #fff;

                    .klogger-head {
                        padding: 22px 22px 15px 13px;
                        line-height: 30px;
                        background: rgba(255, 255, 255, 1);

                        .klogger-title {
                            float: left;
                            font-family: PingFangSC-Regular;
                            font-size: 16px;
                            color: #333333;
                            .ant-btn-primary[disabled] {
                                color: rgba(0,0,0,0.25) !important;
                                background-color: #f5f5f5!important;
                                border-color: #d9d9d9;
                                text-shadow: none;
                                box-shadow: none;
                                cursor: not-allowed!important;
                            }
                        }

                        .klogger-select {
                            text-align: right;
                            font-size: 12px;
                        }
                    }
                }

                .klogger-tabs {
                    background-color: #fff;
                    margin-bottom: 0;
                    width: 100%;

                    li {
                        margin: 0 22px 0 22px;
                        display: inline-block;
                        height: 52px;
                        line-height: 52px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular,
                            PingFangSC;
                        font-weight: 400;
                        color: rgba(51, 51, 51, 1);
                        cursor: pointer;

                        &.active {
                            // background: rgba(255, 255, 255, 1);
                            border-bottom: 1px solid #5B6BAE;
                            border-width: 2px;
                        }
                    }
                }

                .klogger-table {
                    overflow: auto;

                    &::-webkit-scrollbar {
                        width: 4px;
                    }

                    &::-webkit-scrollbar-track {
                        border: 0;
                    }

                    .ant-spin-nested-loading {
                        border-top: none;
                    }
                }

                .klogger-table-head {
                    background-color: #fff;
                    background: rgba(255, 255, 255, 1);
                    border-top: 1px solid rgba(238, 238, 238, 1);
                    border-bottom: 1px solid rgba(238, 238, 238, 1);
                    width: 100%;
                    margin-bottom: 0;

                    li {
                        display: inline-block;
                        text-align: center;
                        height: 70px;
                        line-height: 70px;
                        font-size: 14px;
                        font-family: PingFangSC;
                        font-weight: 400;
                        color: rgba(51, 51, 51, 1);

                        .table-head-updown {
                            font-size: 9px;
                            color: rgba(51, 51, 51, 1);
                            margin-left: 10px;
                        }

                    }

                }

                .klogger-content-loading {
                    border-top: 1px solid rgba(245, 245, 245, 1);
                }

                .klogger-content-empty {
                    margin: 0;
                    padding: 32px 0;
                    border-bottom: 1px solid rgba(245, 245, 245, 1);
                    border-left: 1px solid rgba(245, 245, 245, 1);
                    border-right: 1px solid rgba(245, 245, 245, 1);
                }

                .klogger-content {
                    background: rgba(255, 255, 255, 1);
                    width: 100%;
                    table-layout: fixed;

                    tr {
                        height: 80px;
                        text-align: center;
                        border-bottom: 1px solid rgba(245, 245, 245, 1);
                        border-left: 1px solid rgba(245, 245, 245, 1);
                        border-right: 1px solid rgba(245, 245, 245, 1);
                        cursor: pointer;

                        &.active {
                            background-color: rgba(240, 244, 250, 1);
                        }

                        &:hover {
                            background-color: rgba(240, 244, 250, 1);
                        }
                    }
                }
            }
        }
    }

    .research-graph{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        width: 100vw;
        height: 100vh;
        background: #130D37;
        .research-graph_btn{
            position: absolute;
            top: 20px;
            left: 20px;
            z-index: 10000;
        }
    }
}

.table-head-updown-menu {
    padding: 0;

    li {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #333333;
        height: 32px;
        line-height: 20px;
        text-align: center;
        vertical-align: middle;
    }
}