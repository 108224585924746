.financial-case-sheet{
    min-width: 1140px;
    .caseSheet-time-choice{
        margin-bottom: 36px;
        text-align: right;
    }
    .case-sheet-pie{
        display: flex;
        margin-bottom: 44px;
    }
    .pie-item{
        width: calc((100% - 56px)/3);
        min-width: 360px;
        margin-right: 28px;
        background: #FFFFFF;
        box-shadow: 0 0 10px 1px #F1F1F1;
        height: 336px;
        padding: 41px 26px 55px 24px;
        position: relative;
        overflow: hidden;
        &:last-child{
            margin-right: 0;
        }
    }
    .pie-item-title{
        padding: 0 8px;
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #333333;
        height: 22px;
        line-height: 22px;
        margin-bottom: 35px;
    }
    .case-sheet-table{
        background: #FFFFFF;
        box-shadow: 0 0 10px 1px #F1F1F1;
        border-radius: 6px;
        padding: 27px 32px;
        margin-bottom: 30px;
    }
    .table-title{
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #2578E9;
        border-radius: 6px 6px 0 0;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #FFFFFF;
        position: relative;
    }
    .table-title-unit{
        position: absolute;
        height: 60px;
        line-height: 60px;
        right: 0;
        top: 0;
        font-size: 14px;
    }
    .table-main{

    }
    .left-table{

    }
    .right-table{

    }


    .ant-table-thead{
        tr {
            background: #C7DFFF;
            th:nth-child(3){
                //border-right: 1px solid #2578E9;
            }
        }
    }
    .ant-table-tbody{
        .ant-table-row{
            td {
                //padding-left: 25px;
                &:nth-child(3){
                    //border-right: 1px solid #2578E9;
                }
            }
        }
        .level-1{
            td{
                &:nth-child(1),&:nth-child(2),&:nth-child(3){
                    font-weight: 700;
                }
            }
        }
        .level-2{
            td{
                &:first-child{
                    padding-left: 26px;
                }
            }
        }
        .level-3{
            td{
                &:first-child{
                    padding-left: 36px;
                }
            }
        }
    }
    .red-color-style{
        >td{
            &:nth-child(1),&:nth-child(2),&:nth-child(3) {
                color: #E01C1C;
            }
            /*&:first-child{
                padding-left: 16px;
            }*/
        }
    }
    .ant-table-thead > tr:first-child > th:first-child{
        border-radius: 0;
    }
    .ant-table-thead > tr:first-child > th:last-child{
        border-radius: 0;
    }
    .ant-table-body .ant-table-thead th{
        background: #C7DFFF;
        color: #333333;
    }
}

.financial-assets-info{
    .assets-info-item{
        background: #fff;
        box-shadow: 0 0 10px 1px #F1F1F1;
        padding: 30px;
        border-radius: 6px;
        margin-bottom: 40px;
        min-width: 980px;
        position: relative;
    }
    .assets-info-item-title{
        width: 100%;
        font-family: PingFangSC-Semibold;
        font-size: 20px;
        color: #333333;
        height: 28px;
        line-height: 28px;
    }
    .assets-info-item-time{
        position: absolute;
        top: 33px;
        right: 33px;
    }



    .ant-table-thead{
        tr {
            background: #C7DFFF;
            th:nth-child(2){
                border-right: 1px solid #2578E9;
            }
        }
    }
    .ant-table-tbody{
        .ant-table-row{
            td {
                //padding-left: 25px;
                &:nth-child(2){
                    border-right: 1px solid #2578E9;
                }
            }
        }
    }
    .left-color{
        td{
            &:nth-child(1),&:nth-child(2){
                color: #E01C1C;
            }
        }
    }
    .right-color{
        td{
            &:nth-child(3),&:nth-child(4){
                color: #E01C1C;
            }
        }
    }
    .left-level-1{
        td{
            &:nth-child(1),&:nth-child(2){
                font-weight: 700;
            }
        }
    }
    .left-level-2{
        td{
            &:nth-child(1){
                padding-left: 26px;
            }
        }
    }
    .left-level-3{
        td{
            &:nth-child(1){
                padding-left: 36px;
            }
        }
    }
    .right-level-1{
        td{
            &:nth-child(3),&:nth-child(4){
                font-weight: 700;
            }
        }
    }
    .right-level-2{
        td{
            &:nth-child(3){
                padding-left: 26px;
            }
        }
    }
    .right-level-3{
        td{
            &:nth-child(3){
                padding-left: 36px;
            }
        }
    }

    .ant-table-body .ant-table-thead th{
        background: #2578E9;
        color: #fff;
    }
}


.header-title{
    width: 100%;
    height: 32px;
    font-family: PingFangSC-Semibold;
    font-size: 20px;
    color: #333333;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.download-table-button{
    display: flex;
    width: 90px;
    height: 32px;
    line-height: 32px;
    background: #2578E9;
    border-radius: 6px;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s;
    &:hover{
        background-color: #3892E9;
        transition: all .3s;
    }
}
.download-icon{
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.financial-assets-liability{
    background: #FFFFFF;
    box-shadow: 0 0 10px 1px #F1F1F1;
    border-radius: 6px;
    padding: 28px 32px;
    min-width: 920px;
    .financial-assets-liability-title-right{
        display: flex;
        align-items: center;
        justify-content: center;
        .time-choice{
            margin-right: 49px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #333333;
        }
    }
    .table-title{
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #2578E9;
        border-radius: 6px 6px 0 0;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #FFFFFF;
        position: relative;
    }
    .table-main{
        position: relative;
        .ant-table-thead > tr:first-child > th:first-child{
            border-radius: 0;
        }
        .ant-table-thead > tr:first-child > th:last-child{
            border-radius: 0;
        }
    }
    .ant-table-body .ant-table-thead th{
        background: #C7DFFF;
        color: #333333;
        &:nth-child(3){
            border-left: 1px solid #2578E9;
        }
    }
    .ant-table-body .ant-table-tbody tr{
        td:nth-child(3){
            border-left: 1px solid #2578E9;
        }
    }
    .left-color{
        td{
            &:nth-child(1),&:nth-child(2){
                color: #E01C1C;
            }
        }
    }
    .right-color{
        td{
            &:nth-child(3),&:nth-child(4){
                color: #E01C1C;
            }
        }
    }
    .left-level-1{
        td{
            &:nth-child(1),&:nth-child(2){
                font-weight: 700;
            }
        }
    }
    .left-level-2{
        td{
            &:nth-child(1){
                padding-left: 26px;
            }
        }

    }
    .left-level-3{
        td{
            &:nth-child(1){
                padding-left: 36px;
            }
        }

    }
    .right-level-1{
        td{
            &:nth-child(3),&:nth-child(4){
                font-weight: 700;
                //padding-left: 36px;
            }
        }

    }
    .right-level-2{
        td{
            &:nth-child(3){
                padding-left: 26px;
            }
        }

    }
    .right-level-3{
        td{
            &:nth-child(3){
                padding-left: 36px;
            }
        }

    }
}
