.company-industryAndAbstract-main{
    width: 100%;
    min-width: 1200px;
    padding: 0 30px;
    margin-bottom: 50px;
    //display: flex;
    .company-industryAndAbstract-left{
        margin-right: 385px;
        //flex: 1;

        .industryAndAbstract-left-item{
            padding: 28px 34px;
            background: #FFFFFF;
            box-shadow: 0 0 10px 1px #F1F1F1;
            border-radius: 6px;
            margin-bottom: 30px;
            position: relative;
            .left-item-title{
                width: 100%;
                height: 46px;
                padding-bottom: 18px;
                border-bottom: 1px solid #F0F0F0;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .left-item-left{
                font-family: PingFangSC-Semibold;
                font-size: 20px;
                color: #333;
                line-height: 28px;
            }
            .left-item-importTitle{

            }
            .left-item-secondTitle{
                margin-left: 4px;
                color: #8C8C8C;
            }
            .left-item-right-refresh{
                width: 70px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #2578E9;
                background: #fff;
                border-radius: 6px;
                color: #2578E9;
                font-size: 12px;
                cursor: pointer;
                transition: all .3s;
                &:hover{
                    color: #fff;
                    background: #2578E9;
                    transition: all .3s;
                }
            }
            .refresh-icon{
                margin-left: 4px;
            }
            .left-item-content{
                margin-bottom: 20px;
            }
            .left-item-ul{
                >li{
                    font-family: PingFangSC-Regular;
                    font-size: 12px;
                    color: #333333;
                    line-height: 36px;
                }
            }
            .left-item-footer{
                height: 36px;
                text-align: right;
            }
            .item-footer-dataFrom{
                font-family: PingFangSC-Regular;
                font-size: 12px;
                color: #2578E9;
                text-decoration: underline;
                cursor: pointer;
                transition: all .3s;
                &:hover{
                    color: #46A5E9;
                    transition: all .3s;
                }
            }
        }
    }
    .company-industryAndAbstract-right{
        width: 344px;
        position: fixed;
        top: 130px;
        right: 30px;
        height: calc(100vh - 140px);
        overflow: auto;

        /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
        //margin-top: 15px;
        &::-webkit-scrollbar {
            width: 10px; /*对垂直流动条有效*/
            height: 10px; /*对水平流动条有效*/
        }

        /*定义滚动条的轨道颜色、内阴影及圆角*/
        &::-webkit-scrollbar-track{
            background-color: #fff;
            border-radius: 3px;
        }


        /*定义滑块颜色、内阴影及圆角*/
        &::-webkit-scrollbar-thumb{
            border-radius: 7px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #fff;
        }
    }

    .prediction-item-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        .table-description{
            color: #E01C1C;
        }
        .table-data-from{
            color: #333333;
            cursor: pointer;
            &:hover{
                color: #2578E9;
            }
        }
        .data-from-icon{
            margin-left: 4px;
        }
    }


    .ant-table-header{
        height: 53px;
        th{
            background: #2578E9;
            color: #fff;
        }
        /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .ant-table-body{
        /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
        margin-top: 15px;
        &::-webkit-scrollbar {
            width: 10px; /*对垂直流动条有效*/
            height: 10px; /*对水平流动条有效*/
        }

        /*定义滚动条的轨道颜色、内阴影及圆角*/
        &::-webkit-scrollbar-track{
            background-color: #fff;
            border-radius: 3px;
        }


        /*定义滑块颜色、内阴影及圆角*/
        &::-webkit-scrollbar-thumb{
            border-radius: 7px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #fff;
        }
    }


}

.ant-drawer-content-wrapper{
    position: relative;
}
.ant-spin-spinning{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.5);
    z-index: 9;
}
.drawer-item{
    margin-bottom: 12px;
    cursor: pointer;
    transition: all .3s;
    width: calc(100% + 48px);
    padding: 0 24px;
    margin-left: -24px;
    &:hover{
        background: #e6f7ff;
        transition: all .3s;
    }
    >p{
        font-size: 14px;
        color: #333333;
        line-height: 26px;
        font-family: PingFangSC-Regular;
        margin-bottom: 0;
    }
    .drawer-title{
        font-family: PingFangSC-Semibold;
    }
    .drawer-author{
        text-align: right;
    }
}
.ant-drawer-content-wrapper{
    position: fixed!important;
}

