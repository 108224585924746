.breadcrumb-nav{
  display: flex;
  margin: 0;
  .breadcrumb-item{
    display: flex;
    align-items: center;
  }
  .breadcrumb-label{
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  .breadcrumb-label-last{
    cursor: auto;
    color: rgba(0, 0, 0, 0.8);
  }
  .breadcrumb-sep{
    margin: 0 5px;
  }
}
