$btnColor: #5b6bae;
$btnHover: #7882aa;
$unActive: #B4B4B4;

.login-page {
    width: 100vw;
    height: 100vh;
    min-width: 900px;
    overflow: auto;
    background: url("../../../assets/login-bg.png") center center no-repeat;
    background-size: 100% 100%;
    display: flex;
    //flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    .login-page-logo {
        top: 57px;
        left: 66px;
        position: absolute;
        width: 182px;
        padding-top: 66px;
        height: 94px;
        color: #fff;
        font-size: 20px;
        font-family: PingFangSC;
        font-weight: 400;
        background: url("../../../assets/login-logo.png") left top no-repeat;
        background-size: 182px;
    }

    // .login-page-logo {
    //     top: 59px;
    //     left: 99px;
    //     position: absolute;
    //     font-family: PingFangSC-Regular;
    //     font-size: 30px;
    //     color: #FFFFFF;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     // width: 182px;
    //     // padding-top: 66px;
    //     // height: 94px;
    //     // color: #fff;
    //     // font-size:20px;
    //     // font-family:PingFangSC;
    //     // font-weight:400;
    //     // background: url("../../../assets/login-logo.png") left top no-repeat;
    //     // background-size: 182px;

    //     .login-page-logo-icon {
    //         font-size: 52px;
    //     }
    // }

    @media screen and (max-width: 620px) {
        .login-main {
            margin: 0 auto;
        }
    }

    @media screen and (min-width: 620px) {
        .login-main {
            margin-right: 170px;
        }
    }

    .login-main {
        width: 450px;
        background: #fff;
        border-radius: 4px;
        padding: 50px 75px;

        .login-box {
            width: 300px;

            .login-title {
                margin: 0 0 40px;
                text-align: center;
                height: 33px;
                font-size: 24px;
                font-family: PingFangSC;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                line-height: 33px;
            }

            .input-main {}

            .input-item {
                margin-bottom: 25px;
                position: relative;

                &:last-child {
                    margin-bottom: 0;
                }

                .input-item-password-tips {
                    position: absolute;
                    width: 100%;
                    text-align: right;
                    bottom: -20px;
                    left: 0;
                    font-size: 12px;
                    font-family: PingFangSC;
                    font-weight: 400;
                    color: rgba(216, 35, 35, 1);
                    display: block;
                    height: 17px;
                    line-height: 17px;
                }

                .get-verify-code {
                    text-decoration: underline;
                    cursor: pointer;
                    color: #333;

                    &:hover {
                        color: $btnColor;
                    }

                    &.has-send {
                        cursor: not-allowed;
                        text-decoration: none;

                        &:hover {
                            color: #333;
                        }
                    }
                }
            }

            .login-input {
                .ant-input {
                    padding: 0 28px 0 19px;
                    border-radius: 0;
                    border: none;
                    border-bottom: 1px solid #333;
                    box-shadow: none;
                    color: #333;
                    background-color: transparent;
                    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
                }

                &.input-error {
                    .ant-input {
                        border-bottom: 1px solid #DC2020;
                    }
                }

                .ant-input-prefix {
                    left: 0;
                    text-align: left;
                }

                .font_family {
                    color: #B4B4B4;
                    font-size: 14px;
                }

                .ant-input-suffix {
                    right: 0;
                }

                &.verify-code-input {
                    .ant-input {
                        padding-right: 100px;
                    }
                }
            }

            .forget-line {
                margin-top: 4px;
                height: 17px;
                line-height: 17px;
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 29px;

                .remember-checked {
                    .ant-checkbox-wrapper {
                        color: rgba(51, 51, 51, 1);
                        font-size: 12px;

                        &:hover {
                            .ant-checkbox-inner {
                                border-color: $btnColor;
                            }
                        }
                    }

                    .ant-checkbox {
                        .ant-checkbox-inner {
                            width: 10px;
                            height: 10px;
                        }
                    }

                    .ant-checkbox-input:focus+.ant-checkbox-inner {
                        border-color: $btnColor;
                    }

                    .ant-checkbox-checked .ant-checkbox-inner {
                        background-color: $btnColor;
                        border-color: $btnColor;
                    }
                }

                .forget-password {
                    color: rgba(220, 32, 32, 1);
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .action-btn {
                .btn-main {
                    width: 100%;
                    height: 46px;
                    line-height: 46px;
                    border-radius: 4px;

                    &[disabled] {
                        background-color: $unActive !important;
                        border-color: $unActive !important;
                        color: #fff !important;
                    }
                }

                &.register-btn {
                    margin-top: 40px;
                }
            }

            .to-register-line {
                text-align: center;
                text-decoration: underline;
                margin-top: 4px;
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                line-height: 17px;
                cursor: pointer;

                &:hover {
                    color: $btnColor;
                }
            }

            &.unActive {
                .login-title {
                    color: $unActive;
                }

                .login-input .ant-input {
                    color: $unActive;
                    border-bottom: 1px solid $unActive;
                }

                .to-register-line {
                    color: $unActive;
                }

                .get-verify-code {
                    color: $unActive;
                }
            }
        }
    }

    .copyright-info {
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
        color: #aaa;
    }
}