/* 重置浏览器缺省样式，减少兼容性问题 */
html,
body {
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  color: #000;
}

select,
input {
  border: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

ol,
ul {
  list-style: none;
}

input,
button {
  border-style: none;
  outline: none;
}

body,
button,
input,
select,
textarea {
  font: 14px/1.4 -apple-system-font, 'Helvetica Neue', 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', sans-serif;
}

button,
input,
select,
textarea {
  font-size: 100%;
}

textarea {
  resize: none;
  outline: none;
}
.clearfix:after {
  display: block;
  height: 0;
  content: '';
  clear: both;
  visibility: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

#root .ant-spin-spinning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 8;
}
.lockHTML {
  height: 100vh;
  overflow: hidden;
}

@font-face {
  font-family: 'font_icon';
  src: url('./iconfont/iconfont.eot');
  src: url('./iconfont/iconfont.eot?#iefix') format('embedded-opentype'),
    url('./iconfont/iconfont.woff2') format('woff2'),
    url('./iconfont/iconfont.woff') format('woff'),
    url('./iconfont/iconfont.ttf') format('truetype'),
    url('./iconfont/iconfont.svg#font_family') format('svg');
}
.font_icon {
  font-family: 'font_icon' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'nav_icon';
  src: url('./navIcon/iconfont.eot');
  src: url('./navIcon/iconfont.eot?#iefix') format('embedded-opentype'),
    url('./navIcon/iconfont.woff2') format('woff2'),
    url('./navIcon/iconfont.woff') format('woff'),
    url('./navIcon/iconfont.ttf') format('truetype'),
    url('./navIcon/iconfont.svg#font_family') format('svg');
}
.nav_icon {
  font-family: 'nav_icon' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'iconfont_mei';
  src: url('./iconfont2/iconfont.eot');
  src: url('./iconfont2/iconfont.eot?#iefix') format('embedded-opentype'),
    url('./iconfont2/iconfont.woff2') format('woff2'),
    url('./iconfont2/iconfont.woff') format('woff'),
    url('./iconfont2/iconfont.ttf') format('truetype'),
    url('./iconfont2/iconfont.svg#iconfont') format('svg');
}
.iconfont_mei {
  font-family: 'iconfont_mei' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'liuqi';
  src: url('./liuqi/iconfont.woff2') format('woff2'),
    url('./liuqi/iconfont.woff') format('woff'),
    url('./liuqi/iconfont.ttf') format('truetype');
}
.liuqi {
  font-family: 'liuqi' !important;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'iconf';
  src: url('./iconf/iconfont.ttf') format('truetype');
}
.iconf {
  font-family: 'iconf' !important;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  //列表页码样式
  .ant-pagination {
    line-height: 28px;
    .ant-pagination-total-text {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFangSC;
      font-weight: 400;
      color: rgba(108, 108, 108, 1);
      margin-right: 25px;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      font-size: 12px;
      width: 28px;
      height: 28px;
      line-height: 28px;
      margin-right: 20px;
    }
    .ant-pagination-item {
      font-size: 14px;
      //width: 28px;
      height: 28px;
      line-height: 28px;
      margin-right: 20px;
      border-radius: 4px;
      // border: 1px solid rgba(240, 240, 240, 1);
      border: none;
      > a {
        color: #576cb4;
        &:hover {
          text-decoration: none;
        }
      }
      &:hover {
        border: none;
      }
      &.ant-pagination-item-active {
        // background: #5b6bae;
        border-color: #fff;
        > a {
          color: #161717;
          text-decoration: underline;
          &:focus {
            text-decoration: none;
          }
        }
        &:hover {
          > a {
            color: #576cb4;
          }
        }
        &:focus {
          > a {
            color: #161717;
          }
        }
      }
    }
    .ant-pagination-jump-next {
      &:hover {
        .ant-pagination-item-link-icon {
          color: #5b6bae;
        }
      }
    }
    .ant-pagination-options {
      .ant-select-selection {
        height: 28px;
        margin-right: 12px;
        .ant-select-selection__rendered {
          height: 28px;
          line-height: 28px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFangSC;
          font-weight: 400;
        }
      }
      .ant-pagination-options-quick-jumper {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFangSC;
        font-weight: 400;
        color: rgba(108, 108, 108, 1);
        > input {
          min-width: 28px;
          height: 28px;
          padding: 0 5px;
          text-align: center;
        }
      }
    }
  }
  //下拉选择高度
  .ant-select {
    .ant-select-selection {
      height: 30px !important;
      .ant-select-selection__placeholder {
        font-size: 12px;
      }
    }
  }
  //时间选择高度
  .ant-calendar-picker {
    .ant-calendar-picker-input {
      height: 30px;
      line-height: 30px;
      padding: 0 9px;
    }
  }
  //级联选择高度
  .rs-picker-cascader {
    .rs-picker-toggle {
      height: 30px;
      line-height: 30px;
      padding: 0 32px 0 11px;
      font-size: 12px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
    }
    .rs-picker-toggle-caret {
      top: 0;
    }
  }
  .ant-calendar-picker .ant-calendar-picker-input {
    //padding: 0;
    display: flex;
    align-items: center;
    padding: 0 9px;
    .ant-calendar-range-picker-separator {
      font-size: 12px;
      min-width: 6px;
    }
    .ant-calendar-range-picker-input {
      font-size: 12px;
      height: 28px;
      line-height: 28px;
    }
    .ant-calendar-picker-icon {
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
    }
  }
  .ant-table-selection-column {
    padding: 0 !important;
    .ant-checkbox-wrapper {
      padding: 10px 16px !important;
    }
  }
  .ant-input-search {
    .ant-input {
      height: 30px;
      line-height: 30px;
      font-size: 12px;
    }
    &.center-search-input {
      .ant-input {
        font-size: 14px;
      }
    }
  }
}
.ant-select-selection-selected-value {
  font-size: 12px;
}
