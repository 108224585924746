$btnColor: #5b6bae;
$btnHover: #7882aa;
.knowledge-result-page {
  background: #fafafa;

  //生成关系图谱样式
  .show-full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background: #000;

    .full-screen-exit {
      font-size: 16px;
      z-index: 3;
      color: #fff;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 30px;
      top: 30px;
      cursor: pointer;
    }
  }

  .knowledge-result-main {
    //padding: 21px 32px 0 34px;
    margin-bottom: 50px;
    display: flex;
    min-width: 1200px;
    .ant-btn-primary {
      background: $btnColor;
      border-color: $btnColor;
      &:hover {
        background: $btnHover;
        border-color: $btnHover;
      }
    }
    // .ant-select-selection{
    //   &:hover{
    //     border-color: $btnColor;
    //   }
    //   &:focus{
    //     border-color: $btnColor;
    //   }
    //   &:active{
    //     border-color: $btnColor;
    //   }
    // }
  }
  .knowledge-result__left {
    //width: 347px;
    flex-shrink: 0;
    margin-right: 31px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    .knowledge-result__left-icon {
      margin-bottom: 20px;
      .font_icon {
        font-size: 20px;
        color: $btnColor;
        cursor: pointer;
        &:hover {
          color: $btnHover;
        }
      }
    }
    .knowledge-result__left-main {
      //background: #ffffff;
      border-radius: 6px;
    }
    //展开情况
    .left-unfload-style {
      //padding: 25px 0 46px 0;
      padding-bottom: 15px;
    }
    //卡片类型选择模块
    .type-card-item {
      background: #fff;
      margin-bottom: 10px;
      .ant-tabs {
        .ant-tabs-top-bar {
          margin-bottom: 0;
          border-bottom: none;
          .ant-tabs-nav-container {
            margin-bottom: 0;
            height: 34px;
          }
          .ant-tabs-nav-wrap {
            .ant-tabs-nav {
              width: 100%;
            }
            .ant-tabs-tab {
              width: calc(33.33333% + 1px);
              padding: 0;
              margin-right: -1px;
              background: rgba(250, 250, 250, 1);
              border-radius: 6px 6px 0px 0px;
              border-color: rgba(238, 238, 238, 1);
              border-bottom: none;
              height: 34px;
              line-height: 34px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
              &:first-child {
                width: 33.333333%;
              }
              &.ant-tabs-tab-active {
                background: #fff;
              }
            }
          }
        }
        .ant-tabs-top-content {
          padding: 20px 18px 30px;
          border-radius: 0px 0px 4px 4px;
          border: 1px solid rgba(238, 238, 238, 1);
          border-top: none;
        }
      }
    }

    .ant-collapse {
      border: none;
      background-color: #fff;
      padding: 20px 18px;
      margin-bottom: 10px;
      border-radius: 4px;
      border: 1px solid rgba(238, 238, 238, 1);
      .ant-collapse-item {
        border-bottom: none;
        .ant-collapse-header {
          padding: 0;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333333;
          height: 25px;
          line-height: 25px;
        }

        .ant-collapse-content {
          margin-top: 12px;
          border-top: none;
          .ant-collapse-content-box {
            padding: 0;
          }
        }
      }
      &.left-structure-item {
        cursor: pointer;
      }
      &.left-select-item {
        background: #eee;
        .ant-collapse-content-box {
          background: #eee;
        }
      }
    }
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      margin-top: 0;
      right: 0;
      font-size: 12px;
      color: #999;
    }
    //折叠情况
    .left-fload-style {
      padding: 28px 0 31px 19px;
      margin-bottom: 0;
      background: #fff;
    }
    .left-fload-item {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #333333;
      height: 25px;
      line-height: 25px;
      margin-bottom: 24px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        color: #4f94fd;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      &.select-fload-item {
        color: #4f94fd;
      }
    }
  }
  .knowledge-result__left-collapse {
    .knowledge-result__left-collapse__item-tips {
      margin-bottom: 0;
      margin-top: -8px;
      font-family: PingFangSC-Regular;
      font-size: 10px;
      color: #ff3a3a;
    }
    .knowledge-result__select-item {
      display: flex;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .knowledge-result__select-item-label {
      width: 42px;
      height: 32px;
      line-height: 32px;
      flex-shrink: 0;
      font-size: 14px;
    }
    .knowledge-result__select-item-select {
      flex: 1;
    }
    &.industry-item {
      margin-top: 10px;
      .industry-item-classification {
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .industry-item-title {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }
    }
  }
  .time-item {
    .ant-collapse-content {
      margin-top: 15px !important;
    }
    .year-select{
      .ant-select-selection__placeholder{
        text-align: center;
        font-size: 14px;
      }
      .ant-select-selection__rendered{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .ant-calendar-picker {
    .ant-input {
      &:hover,
      &:focus,
      &:active {
        border-color: $btnColor;
      }
    }
  }

  //多选框样式
  .ant-select-selection {
    &:active,
    &:hover,
    &:focus {
      border-color: $btnColor;
    }
  }
  // .knowledge-result__left .ant-collapse.check-item-collapse {
  //   //padding-bottom: 6px;
  // }
  //选择框样式
  .checked-item {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #333333;
    .ant-checkbox-group{
      width: 100%;
    }
    .ant-checkbox-group-item {
      width: 50%;
      margin-right: 0;
      margin-bottom: 14px;
      font-size: 12px;
      &:nth-last-child(2),&:last-child {
        margin-bottom: 0;
      }
      .ant-checkbox {
        font-size: 12px;
      }
      .ant-checkbox-inner {
        width: 12px;
        height: 12px;
        border-color: rgba(139,139,139,1);
      }
      &.ant-checkbox-wrapper-checked {
        .ant-checkbox-inner {
          background: $btnColor;
          border-color: $btnColor;
        }
      }
      &:hover {
        .ant-checkbox-inner{
          border-color: $btnColor;
        }
      }
    }
  }
  //表格页码样式
  .ant-pagination {
    .ant-pagination-item-active {
      border-color: $btnColor;
      > a {
        color: $btnColor;
      }
    }
    .ant-pagination-item {
      margin-right: 14px;
      &:hover {
        border-color: $btnColor;
        > a {
          color: $btnColor;
        }
      }
    }
    .ant-pagination-prev[aria-disabled="false"],
    .ant-pagination-next[aria-disabled="false"] {
      &:hover,
      &:focus {
        > a {
          border-color: $btnColor;
          color: $btnColor;
        }
      }
    }
    .ant-pagination-prev {
      margin-right: 14px;
    }
    .ant-select-selection {
      &:hover,
      &:focus {
        border-color: $btnColor;
        color: $btnColor;
      }
    }
    .ant-pagination-options-quick-jumper {
      &:hover {
        input {
          border-color: $btnColor;
        }
      }
      input {
        &:hover,
        &:focus {
          border-color: $btnColor;
        }
      }
    }
  }

  .knowledge-result__right {
    flex: 1;
  }
  .knowledge-result__right-createMap-btn {
    margin-bottom: 20px;
  }
  .knowledge-result__right-main {
    background: #ffffff;
    box-shadow: 0 0 10px 1px #f1f1f1;
    border-radius: 6px;
    padding: 0 14px 20px;
    .ant-table-body {
      .ant-table-thead {
        tr {
          th {
            font-family: PingFang-SC-Medium;
            font-size: 16px;
            color: #333333;
            background: #fff;
            border: none;
          }
        }
      }
      .ant-table-tbody {
        tr {
          td {
            border-bottom: none;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #333333;
          }
          &:hover {
            td {
              background: $btnColor;
              color: #fff;
            }
          }
          &.single-row {
            td {
              background: #edf5ff;
            }
            &:hover {
              td {
                background: $btnColor;
                color: #fff;
              }
            }
          }
        }
      }
    }
    .ant-table-placeholder {
      border-bottom: none;
    }
  }
}
.ant-calendar-tbody {
  .ant-calendar-selected-date {
    .ant-calendar-date {
      background: $btnColor;
    }
  }
  .ant-calendar-selected-start-date,
  .ant-calendar-selected-end-date {
    .ant-calendar-date {
      background: $btnColor;
    }
  }
}

.industry-item-classification{



}
.rs-picker-cascader{
  background: #fff;
  .rs-picker-toggle{
    background: #fff;
    padding-right: 36px!important;
    &:hover{
      border-color: $btnColor!important;
    }
    &.active{
      border-color: $btnColor!important;
    }
    .rs-picker-toggle-value{
      color: $btnColor!important;
      display: flex;
      align-items: center;
      .rs-picker-value-count{
        background: $btnColor;
        margin: 0 0 0 1px;
        padding: 0 5px;
        height: 17px;
        line-height: 17px;
        border-radius: 50%;
      }
    }
    .rs-picker-toggle-clean{
      top: 0;
      margin: 0;
      right: 25px;
    }
  }
}
.mult-select-menu{
  .rs-picker-cascader-menu-column{
    .rs-picker-check-menu-item-active{
      .rs-picker-check-menu-item-wrapper{
      &:before{
        border-color: $btnColor;
      }
      .rs-picker-check-menu-item-inner{
        &:before{
          border-color: $btnColor;
          background: $btnColor;
        }
      }
    }
    }
    
  }
  
}

.select-year-box{
  width: 100%;
  overflow: hidden;
  .select-year-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 27px;
    margin: 0;
    font-size: 12px;
    padding: 0 10px;
    .pre-icon{
      display: block;
      width: 15px;
      text-align: center;
      cursor: pointer;
      color: #333;
      &.disabled-icon{
        cursor: not-allowed;
        color: #999;
      }
    }
    .next-icon{
      display: block;
      width: 15px;
      text-align: center;
      cursor: pointer;
      color: #333;
      &.disabled-icon{
        cursor: not-allowed;
        color: #999;
      }
    }

  }
  .select-year-main{
    border-top:1px solid rgba(244,244,244,1);
    width: 100%;
    height: 113px;
    //overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  }
  .select-year-list{
    display: flex;
    flex-wrap: wrap;
    padding: 13px 6px 16px;
    width: 232px;
    flex-shrink: 0;
    margin-bottom: 0;
  }
  .select-year-item{
    font-size:12px;
    font-family:PingFangSC;
    color: #333;
    margin-bottom: 13px;
    padding: 0 10px;
    height: 20px;
    //line-height: 20px;
    >span{
      display: block;
      padding: 0 4px;
      height: 20px;
      line-height: 20px;
      cursor: pointer;
      &:hover{
        background: $btnColor;
        color: #fff;
      }
    }
    &:nth-child(9),&:nth-child(10),&:nth-child(11),&:nth-child(12){
      margin-bottom: 0;
    }
    &:nth-child(1),&:nth-child(5),&:nth-child(9){
      padding-left: 6px;
    }
    &:nth-child(4),&:nth-child(8),&:nth-child(12){
      padding-right: 4px;
    }


    &.current-year{
      >span{
        border: 1px solid $btnColor;
        color: $btnColor;
        &:hover{
          color: #fff;
        }
      }
    }
    &.start-year{
      border-radius: 10px 0 0 10px;
      background: #F0F4FA;
      &.open-select-year{
        border-radius: 0;
        background: transparent;
        >span{
          background: $btnColor;
          color: #fff;
        }
      }
    }
    &.end-year{
      border-radius: 0 10px 10px 0;
      background: #F0F4FA;
      &>span{
        border: none;
        color: #333;
        &:hover{
          color: #fff;
        }
      }
      &.start-year{
        border-radius: 10px;
      }
    }
    &.between-year{
      background: #F0F4FA;
    }
    &.disabled-year{
      >span{
        background: #eee;
        cursor: not-allowed;
        &:hover{
          background: #eee;
          color: #333;
        }
      }

    }

  }
}
