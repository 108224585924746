.home-page__new-home {
  height: calc(100vh - 60px);
  padding: 16px 16px 15px 6px;
  display: flex;
  flex-direction: column;

  .new-home-title {
    margin-top: 0;
    margin-bottom: 17px;
    height: 26px;
    flex-shrink: 0;
    font-size: 20px;
    font-family: MicrosoftYaHei;
    color: rgba(0, 0, 0, 0.8);
    line-height: 26px;
    overflow: hidden;
  }

  .new-home-content {
    flex: 1;
    display: flex;
    height: calc(100% - 33px);
  }

  .analysis-btn {
    padding: 0 16px;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: right;

    button {
      width: 88px;
      height: 30px;
      color: #5B6BAE;
      border-color: #5B6BAE;
      border-radius: 4px;

      &[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: none;
      }
    }
  }

  .new-home-left, .new-home-center, .new-home-right {
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 1px #F9F9F9;
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .new-home-left {
    .home-left-question-ul {
      margin-bottom: 0;

      .home-left-question-li {
        width: 100%;
        background: #F4F9FF;
        padding: 16px;
        border-radius: 4px;
        margin-bottom: 16px;
        position: relative;

        &:hover {
          .question-li-edit {
            display: block;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .question-li-title {
        height: 21px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        //color: rgba(0, 0, 0, 0.8);
        line-height: 21px;
        margin-top: 0;
        margin-bottom: 8px;
      }

      .question-li-edit {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 16px;
        height: 16px;
        font-size: 16px;
        color: #5B6BAE;
        cursor: pointer;
        display: none;
      }

      .question-text {
        margin: 0;
        line-height: 21px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        //color: rgba(0, 0, 0, 0.8);
      }

      .question-area {
        width: 100%;
        background: transparent;
        border: none;
        padding: 0;
        line-height: 21px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        min-height: 124px;

        &::placeholder {
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .new-home-center {
    margin: 0 16px;
    .home-center-button{
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding: 0 16px;
      .home-center-button-action{
        flex: 1;
        text-align: right;
        >button{
          height: 30px;
        }
      }
      .home-center-page{
        display: flex;
        align-items: center;
        .ant-pagination-prev,.ant-pagination-next{
          margin-right: 0!important;
        }
        .ant-pagination-simple-pager{
          height: 28px;
          color: #6C6C6C;
          margin-right: 0;
          >input{
            width: 38px;
            margin-right: 0;
            &:hover{
              border-color: #5b6bae;
            }
          }
          .ant-pagination-slash{
            margin: 0 8px;
          }
        }
      }
    }
  }

  .new-home-right {
    .home-right-result {
      border: none;
      background: #fff;
    }
    &.new-home-sheet{
      margin-left: 16px;
    }

    /*.result-card-item {
      margin-bottom: 24px;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #E1E7FF;

      &:last-child {
        margin-bottom: 0;
      }

      .ant-collapse-header {
        padding: 16px;
        line-height: 21px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.8);

        .anticon {
          color: #999999;

          svg {
            transform: rotate(-90deg);
          }
        }
      }

      .ant-collapse-content {
        border-top: none;

        .ant-collapse-content-box {
          padding: 0 16px;
        }
      }
    }

    .result-card-line {
      display: flex;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 20px;
      }

      .result-card-attr {
        width: 77px;
        padding-right: 4px;

        .attr-tag {
          display: inline-block;
          padding: 3px 8px;
          line-height: 16px;
          font-size: 12px;
          color: #5B6BAE;
          border-radius: 2px;
          border: 1px solid #C8D2FB;
          margin-bottom: 8px;
        }
      }

      .result-card-tag-ul {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
      }

      .result-card-tag-li {
        display: flex;
        align-items: center;
        padding: 0 8px;
        height: 24px;
        font-size: 12px;
        color: #5B6BAE;
        border-radius: 2px;
        border: 1px solid #C8D2FB;
        margin-right: 8px;
        margin-bottom: 8px;
        background: #EFF2FF;

        .tag-delete-icon {
          color: #C8D2FB;
          font-size: 8px;
          width: 12px;
          height: 12px;
          margin-left: 4px;
          cursor: pointer;

          svg {

          }
        }
      }

      .add-tag {
        height: 24px;
        width: 58px;

        .btn-status {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          border: 1px dashed #D9D9D9;
          font-size: 12px;
          color: #4A4A4A;
          cursor: pointer;
        }

        .add-icon {
          color: #4A4A4A;
          font-size: 12px;
          margin-right: 4px;
        }

        .input-status {
          width: 100%;
          height: 100%;
          font-size: 12px !important;
          padding: 0 8px;
          border-radius: 2px;
        }
      }
    }

    .right-result-submit {
      margin-top: 40px;
      margin-bottom: 20px;
      text-align: right;

      button {
        width: 88px;
        height: 30px;
        background: #5B6BAE;
        border-radius: 4px;
      }
    }*/

    .ant-spin-nested-loading {
      height: 100%;
    }

    .ant-spin-container {
      height: 100%;
    }

    .home-right-module{
      width: 100%;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      &.home-right-question{
        .home-right-card{
          padding-bottom: 8px;
        }
      }
      &.home-right-corpus{
        .home-right-card{
          padding-bottom: 8px;
        }
      }
    }
    // 问句解析添加属性标签弹窗
    .add-attribute-modal{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      padding: 47px 34px 0;
      z-index: 9;
      .attr-modal-content{
        display: flex;
        flex-direction: column;
        background: #fff;
        box-shadow: 0px 0px 8px 2px rgba(222, 222, 222, 0.5);
        border-radius: 4px;
        padding: 0 24px;
        position: relative;
      }
      .attr-modal-close{
        position: absolute;
        top: 17px;
        right: 17px;
        font-size: 10px;
        cursor: pointer;
      }
      .attr-modal-title{
        height: 21px;
        text-align: center;
        margin-top: 24px;
        margin-bottom: 31px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: rgba(0, 0, 0, 0.8);
      }
      .attr-modal-ul{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 25px;
      }
      .attr-tags{
        display: flex;
        align-items: center;
        height: 24px;
        padding: 0 12px;
        border-radius: 2px;
        border: 1px solid #C8D2FB;
        margin-bottom: 8px;
        margin-right: 8px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #5B6BAE;
        cursor: pointer;
        &.selected-attr{
          background-color: #EFF2FF;
        }
      }
      .attr-modal-btn{
        text-align: center;
        margin-bottom: 16px;
      }

    }
    .home-right-module-title{
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.8);
      line-height: 21px;
      margin: 0 0 16px;
    }
    .home-right-module-content{
      min-height: 300px;
      .ant-spin-nested-loading{
        min-height: 300px;
      }
      /*属性标签*/
      .home-right-card{
        border-radius: 4px;
        border: 1px solid #E1E7FF;
        padding: 16px;
        margin-bottom: 16px;

        .corpus-item-ul{
          display: flex;
          flex-wrap: wrap;
        }
        .right-corpus-text{
          margin: 0 0 8px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          line-height: 17px;

        }
        .value-item-card{
          padding: 0 8px;
          line-height: 22px;
          background: #EFF2FF;
          border-radius: 2px;
          border: 1px solid #C8D2FB;
          margin-right: 8px;
          margin-bottom: 8px;
          color: #5B6BAE;
          display: flex;
          align-items: center;
          .tag-delete-icon{
            margin-left: 3px;
            font-size: 8px;
            color: #C8D2FB;
          }
        }
      }
      /**右侧问题解析*/
      .right-question-card{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        .right-question-card-attribute{
          padding: 0 8px;
          line-height: 22px;
          border-radius: 2px;
          border: 1px solid #C8D2FB;
          //max-width: 68px;
          margin-right: 4px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #5B6BAE;
          &.add-attribute {
            color: #4A4A4A;
            border: 1px dashed #D9D9D9;
            cursor: pointer;
            margin-bottom: 16px;
          }
        }
        .right-question-card-values{
          width: calc(100% - 76px);
          flex-shrink: 0;
          display: flex;
          flex-wrap: wrap;
        }

      }
      // 右侧生成摘要
      .right-abstract-item{
        user-select: none;
        cursor: pointer;
        &:hover{
          .abstract-edit{
            display: flex;
          }
        }
        &.active-abstract-item{
          .home-right-card{
            border-color: #5B6BAE;
          }
        }
        &.right-abstract-item-line {
          color: #5B6BAE;
          .home-right-module-title .abstract-title{
            color: #5B6BAE;
          }
          .home-right-card{
            border: 1px solid #5B6BAE;
            .right-abstract-text{
              color: #5B6BAE;
              &[contenteditable="true"] {
                color: rgba(0, 0, 0, 0.8);
              }
            }
          }
        }
        .home-right-module-title{
          position: relative;
          .abstract-title{
            display: block;
            height: 21px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.8);
            max-width: calc(100% - 15px);
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
        .abstract-edit{
          display: none;
          cursor: pointer;
          position: absolute;
          bottom: 0;
          right: 0px;
          height: 21px;
          align-items: center;
          color: #999999;
          font-size: 12px;
        }
      }
      .right-abstract-text{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 17px;
        outline: none;
        .abstract-area{
          width: 100%;
          border: none;
          height: auto;
        }
      }

    }

  }

  .home-content {
    //padding: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    .home-content-title {
      padding: 16px;
      margin: 0;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: rgba(0, 0, 0, 0.8);
      line-height: 24px;
      width: 100%;
    }

    .open-icon {
      position: absolute;
      top: 16px;
      width: 24px;
      height: 24px;
      background: #F4F9FF;
      border-radius: 2px;
      cursor: pointer;

      .anticon {
        width: 100%;
        height: 100%;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .home-main-content {
      &.center-content{
        padding: 0 0 20px;
        height: calc(100% - 56px);
        .ant-spin-nested-loading{
          height: 100%;
          .ant-spin-container{
            height: 100%;
          }
        }
      }
      .ant-spin-container{
        display: flex;
        flex-direction: column;
      }
      padding: 0 16px 20px;
      /* 中间语料匹配 */
      .corpus-match-title{
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: rgba(0, 0, 0, 0.8);
        line-height: 21px;
        margin: 0 0 16px;
        padding: 0 16px;
      }
      .corpus-match-list{
        margin: 0;
        min-height: 100px;
        padding: 0 16px;
      }
      .corpus-match-scroll-bar{
        flex: 1;
      }
      .corpus-match-item{
        margin: 0 0 16px;
        padding: 14px 16px 8px;
        border-radius: 4px;
        border: 1px solid #E1E7FF;
        cursor: pointer;
        &.active-corpus-item{
          color: #E1E7FF;
          .corpus-match-item-title .ant-checkbox-wrapper,.corpus-match-item-content{
            color: #5B6BAE;
          }
          .corpus-match-item-title{
            color: #5B6BAE;
          }
        }
        &.corpus-match-item-line {
          border: 1px solid #5B6BAE;
          .corpus-match-item-title,.corpus-match-item-content{
            color: #5B6BAE;
          }
        }
        .corpus-match-item-title{
          margin: 0 0 8px;
          line-height: 20px;
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          display: flex;
          align-items: center;
          .ant-checkbox-wrapper{
            margin-right: 8px;
            .ant-checkbox{
              width: 12px;
              height: 12px;
              &:hover{
                .ant-checkbox:hover::after{
                  border: 1px solid #5B6BAE;
                }
              }
            }
            .ant-checkbox-checked::after{
              border: 1px solid #5B6BAE;
            }
            .ant-checkbox-inner{
              width: 12px;
              height: 12px;
            }
            .ant-checkbox-checked .ant-checkbox-inner{
              background-color: #5B6BAE;
              border-color: #5B6BAE;
            }
            .ant-checkbox-inner::after{
              left: 10%;
            }
          }

        }
        .corpus-item-title-block{
          width: 100%;
          display: flex;
          justify-content: space-between;
          overflow: hidden;
        }
        .corpus-item-title-text{
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .view-text{
          cursor: pointer;
          color: #5B6BAE;
          font-size: 12px;
          width: 48px;
        }
        .corpus-match-item-content{
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          line-height: 18px;
          user-select: none;
        }
      }
    }

    .home-right-submit{
      margin: 20px 0;
      padding: 0 16px;
      text-align: right;
      .home-right-submit-btn{
        height: 30px;
        &:not(:first-child) {
          margin-left: 19px;
        }
        &:last-child{
          border-radius: 4px;
          border: 1px solid #5B6BAE;
          color: #5B6BAE;
          &:hover{
            color: #fff;
            background: #5B6BAE;
          }
        }
      }
    }
    // 表格模式
    .home-sheet-content{
      width: 100%;
      height: 100%;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      #luckysheet{
        flex: 1;
        width: 100%;
      }
      .home-sheet-button{
        margin: 16px 0;
        text-align: right;
      }
    }
  }

  /*.home-main-content {
    padding: 0 16px;
  }*/

}
