$btnColor: #5b6bae;
$btnHover: #7882aa;
.financial-view{
    .top-info{
        background:rgba(255,255,255,1);
        box-shadow:0 0 10px 1px rgba(241,241,241,1);
        border-radius:6px;
        padding: 34px 21px 79px 30px;
        margin-bottom: 20px;
        .top-info-title{
            height: 22px;
            line-height: 22px;
            font-size:16px;
            font-family:PingFang-SC;
            font-weight:500;
            color: #333;
            margin: 0 0 23px;
        }
        .top-info-main{

        }
        .top-info-card{
            display: flex;
            margin-bottom: 0;
        }
        .top-info-card-item{
            padding: 0 25px;
            height: 32px;
            line-height: 32px;
            border:1px solid $btnColor;
            border-radius: 6px 6px 0 0;
            margin-right: 30px;
            font-size:12px;
            font-family:PingFangSC;
            color: #333;
            border-bottom: none;
            cursor: pointer;
            &.select-card-item{
                background: $btnColor;
                color: #fff;
                &:hover{
                    background: $btnColor;
                    color: #fff;
                    border-color: $btnColor;
                }
            }
            &:hover{
                background: $btnHover;
                border-color: $btnHover;
                color: #fff;
            }
            &:last-child{
                margin-right: 0;
            }
        }
        .top-info-graph{
            border: 1px solid $btnColor;
            height: 417px;
            border-radius: 0 6px 6px;
        }
    }
    .relevant-data{
        background: #fff;
        box-shadow:0 0 10px 1px rgba(241,241,241,1);
        border-radius:6px;
        padding: 45px 27px 49px 24px;
        margin-bottom: 40px;
        .relevant-data-title{
            font-size:16px;
            font-family:PingFang-SC;
            font-weight:500;
            color:rgba(51,51,51,1);
            height: 22px;
            line-height: 22px;
            margin: 0 0 33px;
        }
        .relevant-data-main{

        }
        .relevant-data-list{
            border-top:1px solid #F0F0F0;
            margin-bottom: 0;
        }
        .relevant-data-item{
            border-bottom: 1px solid #F0F0F0;
        }
        .relevant-data-item-title{
            height: 22px;
            line-height: 22px;
            font-size:16px;
            font-family:PingFangSC;
            font-weight:400;
            color:#333;
            margin: 20px 0 10px;

        }
        .relevant-data-item-key{
            display: flex;
            margin-bottom: 17px;
            >span{
                display: block;
                height: 22px;
                line-height: 22px;
                padding: 0 12px;
                font-size:12px;
                font-family:PingFangSC;
                font-weight:400;
                color:rgba(51,51,51,1);
                background:rgba(240,244,250,1);
                border-radius:2px;
                margin-right: 10px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .relevant-data-item-abstract{
            font-size:14px;
            font-family:PingFangSC;
            font-weight:400;
            color:#333;
            line-height: 20px;
            margin-bottom: 10px;
        }
        .relevant-data-item-from{
            height: 17px;
            line-height: 17px;
            font-size:12px;
            font-family:PingFangSC;
            font-weight:400;
            color: #969696;
            margin-bottom: 14px;
            >span{
                margin-right: 33px;
            }
        }
        .ant-pagination{
            margin-top: 30px;
        }
    }
}