$btnColor: #5b6bae;
$btnHover: #7882aa;

.business-page {
    padding: 16px 16px 16px 6px;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    .business-head {
        display: flex;
        justify-content: space-between;

        .business-filter {
            display: flex;

            .business-filter-item {
                &>span {
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: rgba(0, 0, 0, 0.8);
                    margin-right: 10px;
                }
            }
        }
    }
    .business-list {
        padding: 8px 0 15px;
    
        .table-outline-name {
          width: 130px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
    
        .business-table {
            background-color: #FFFFFF;
            padding: 10px;

            .ant-table-body {
                .ant-table-thead {
                    tr {
                        th {
                            font-family: PingFang-SC-Medium;
                            font-size: 16px;
                            color: #333333;
                            background: #fff;
                            border: none;
                        }
                    }
                }

                .ant-table-tbody {
                    tr {
                        td {
                            border-bottom: none;
                            font-family: PingFangSC-Regular;
                            font-size: 14px !important;
                            color: #333333 !important;
                        }

                        &:hover {
                            td {
                            background: $btnColor !important;
                            color: #fff !important;

                            div,
                            span {
                                color: #fff !important;
                            }
                            }
                        }

                        &.single-row {
                            td {
                            background: #edf5ff !important;
                            }

                            &:hover {
                            td {
                                background: $btnColor !important;
                                color: #fff !important;
                            }
                            }
                        }
                    }
                }
            }

            .ant-pagination {
                float: right !important;
            }
        }
    }

    .table-action-td {
        display: flex;

        .table-action-cell:not(:last-child) {
            margin-right: 32px;
        }
    }
}

