.card-list-type{
    width: 100%;
    min-width: 960px;
    min-height: 446px;
    padding: 30px;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 34px;
    box-shadow: 0 3px 10px 1px rgba(0,0,0,0.10);
    position: relative;
    overflow: hidden;

    .list-title{
        display: flex;
        align-items: center;
        height: 28px;
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #333333;
        margin-bottom: 22px;
    }
    .list-title-eng{
        margin-left: 15px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0,0,0,0.65);
    }
    .list-content{
        position: relative;
    }
    .list-line{
        height: 48px;
        display: flex;
        align-items: center;
    }
    .single-line{

    }
    .double-line{
        background: #F6F6F7;
        border-radius: 6px;
    }
    .list-item{
        height: 48px;
        line-height: 48px;
        width: 33.3333333333%;
        overflow: hidden;
        display: flex;
    }
    .list-item-label{
        display: block;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        width: 50%;
        flex-shrink: 0;
        padding-left: 30px;
    }
    .list-item-value{
        display: block;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
}