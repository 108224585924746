.search-rightlist {

    .search-rightlist-content {
        background: #fff;
        border-radius: 4px;
        padding: 8px 10px;

        .rightlist-title {
            font-size: 12px;
            font-family: PingFangSC-Regular,
                PingFang SC;
            font-weight: 400;
            color: #909090;
            line-height: 17px;
            margin: 0 0 8px;
        }

        &>ul {
            li.rightlist-item {
                margin-bottom: 12px;
                background: #FAFBFC;
                border-radius: 2px;
                padding: 6px 10px 7px;
                border: 1px solid #FAFBFC;

                &>a {
                    text-decoration: none;
                }

                &:hover {
                    border-color: #7987BE;

                    .title {
                        color: #5B6BAE;
                    }
                }

                .title {
                    font-size: 14px;
                    height: 25px;
                    line-height: 25px;
                    color: #333;
                    margin-bottom: 1px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .info {
                    height: 17px;
                    line-height: 17px;
                    font-size: 12px;
                    color: #909090;
                    display: flex;
                }

                .source {
                    margin-right: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .time {
                    width: 95px;
                    flex-shrink: 0;
                }
            }
        }
    }
}