.result-card {
    border-radius: 4px;
    border: 1px solid #EDEDED;
    background-color: #FFFFFF;
    padding: 0 19px 0 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular,
        PingFang SC;
    font-weight: 400;
    color: #333333;

    .result-card-header {
        border-bottom: 1px solid #ECECEC;
        height: 50px;
        display: flex;

        .result-card-header_left {
            flex: 1;

            &>ul {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &>li {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 50px;

                    .card-header-title {
                        font-size: 10px;
                        font-family: PingFangSC-Regular,
                            PingFang SC;
                        font-weight: 400;
                        color: #909090;

                        &+span {
                            max-width: 300px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                    .card-header-progress {
                        cursor: default;
                    }
                }
            }
        }

        .result-card-header_right {
            line-height: 50px;
        }


        margin-bottom: 4px;
    }

    .result-card-content {
        border-bottom: 1px solid #ECECEC;
        margin-bottom: 8px;

        .card-content-mark {
            font-size: 10px;
            font-family: PingFangSC-Regular,
                PingFang SC;
            font-weight: 400;
            color: #909090;
            margin-bottom: 10px;
        }

        &>ul {
            line-height: 20px;

            &>li {
                margin-bottom: 10px;
                cursor: pointer;

                &>a {
                    text-decoration: none;

                    &:visited {
                        h5 {
                            color: #5B6BAE;
                        }
                    }

                    h5 {
                        font-size: 14px;
                        font-family: PingFangSC-Regular,
                            PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        margin: 0;
                        padding: 0;

                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .content-item-info {
                        font-size: 10px;
                        font-family: PingFangSC-Regular,
                            PingFang SC;
                        font-weight: 400;
                        color: #909090;

                        &>cite {
                            font-style: normal;
                        }

                        &>time {
                            &::before {
                                content: " ";
                            }
                        }
                    }
                }
            }
        }
    }

    .result-card-footer {
        margin-bottom: 8px;

        &>ul {
            display: flex;
            align-items: center;

            &>li {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                .card-footer-btn {
                    padding: 0 10px;
                    height: 30px;
                    background: #C5D0FE;
                    border-radius: 4px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular,
                        PingFang SC;
                    font-weight: 400;
                    color: #333333;

                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 120px;
                }
            }
        }
    }
}