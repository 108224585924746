@mixin scrollStyle {
    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: #F5F5F5;
    }
    /*定义滚动条轨道 内阴影+圆角*/
    &::-webkit-scrollbar-track {
        //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: transparent;
    }
    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #ccc;
    }
}

.search-result {
    //width: 100%;
    //min-height: 100vh;
    //height: 100vh;
    //overflow: auto;
    background: rgba(249, 249, 249, 80);
}

.page-header {
    width: 100%;
    height: 64px;

    .header_content {
        width: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        background-image: linear-gradient(-90deg, #00C8FF 14%, #3383FF 89%);
        padding: 0 25px;
        height: 64px;
        display: flex;
        align-items: center;
    }

    .header-logo {
        width: 24px;
        height: 24px;
        background: url("../assets/logo.png") left center no-repeat;
        background-size: 24px 24px;
    }

    .header_search {
        margin-left: 25px;
        position: relative;
    }

    .header_search-button-box {
        position: absolute;
        top: 0;
        right: 0;
        width: 34px;
        height: 36px;
        font-size: 22px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #2578E9;
        transition: all .3s;

        &:hover {
            color: #499CE9;
            transition: all .3s;
        }
    }

    .select-history {
        width: 100%;
        position: absolute;
        top: 36px;
        left: 0;
        margin-bottom: 0;
        background: #FFFFFF;
        border: 1px solid #1890FF;
        border-radius: 0 0 10px 10px;
        z-index: 109;

        .history-li {
            height: 36px;
            line-height: 36px;
            padding: 0 17px;
            font-family: Helvetica;
            font-size: 14px;
            color: #22272E;
            cursor: pointer;

            &.select-history-li {
                background-image: linear-gradient(-90deg, #00C8FF 14%, #3383FF 89%);
                color: #fff;
            }

        }
    }

    .header_search-input {
        background: #FFFFFF;
        //background-image: linear-gradient(-180deg, #FFFFFF 3%, #617985 100%);
        //border: 1px solid #1890FF;
        box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
        width: 380px;
        height: 36px;
        line-height: 36px;
        padding: 0 50px 0 17px;
        border-radius: 10px;

        &.show-history-input {
            border-radius: 10px 10px 0 0;
        }
    }

    .header_search-button {
        height: 36px;
        line-height: 36px;
        padding: 0 11px;
        background: #1890FF;
        border-radius: 4px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #FFFFFF;
        margin-left: 30px;
        cursor: pointer;
    }
}

//.search-main {
    //width: 100%;
    //height: calc(100vh - 64px);
    //overflow: auto;

    //@include scrollStyle;

    //检索单一数据表（股价走势）
    .search-main__data-table {
        margin-top: 30px;
        width: 100%;
        min-width: 1150px;
        padding: 0 30px 50px;

        .search-main__data-table__box {
            background: #FFFFFF;
            box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.10);
            border-radius: 0 6px 6px 6px;
            padding: 36px;
        }

        .search-main__data-table__title-line {
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;
        }

        .search-main__data-table__left-title {
            height: 32px;
            display: flex;
            align-items: center;
            margin: 0;
        }

        .search-main__data-table__left-title-CHN {
            display: block;
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: PingFangSC-Medium;
            font-size: 20px;
            color: #333333;
        }

        .search-main__data-table__left-title-ENG {
            display: block;
            height: 30px;
            line-height: 30px;
            /*max-width: 200px;
            margin-right: 15px;*/
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            margin-left: 10px;
        }

        .search-main__data-table__right-time {
            height: 32px;
            display: flex;
            align-items: center;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
        }

        .search-main__data-table__right-time-select {
            margin-left: 30px;
        }

        .search-main__data-table__graph-box {

        }

        .search-main__data-table__graph-title {
            display: table;
            background: #1890FF;
            border-radius: 6px 6px 0 0;
            padding: 0 30px;
            height: 40px;
            line-height: 40px;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #FFFFFF;
        }

        .search-main__data-table__graph-main {
            width: 100%;
            background: #FFFFFF;
            border: 1px solid #488BFF;
            box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
            border-radius: 0 3px 3px 3px;
            padding: 30px;
        }

        .search-main__data-table__graph-main__select-radio {
            margin-bottom: 50px;
        }

        .ant-radio-wrapper {
            margin-right: 60px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            letter-spacing: 0;
            text-align: left;
            line-height: 22px;
        }

        .recharts-default-legend li {
            margin-right: 50px !important;

            &:last-child {
                margin-right: 0 !important;
            }
        }
    }

    //检索广泛类型信息  柱形+曲线图  表格
    .search-result__mix {
        width: 100%;
        min-width: 980px;
        padding: 0 30px 50px;

        .mix-item {
            margin-top: 36px;
            margin-bottom: 43px;
        }

        .mix-item-title {
            height: 28px;
            font-family: PingFangSC-Medium;
            font-size: 20px;
            color: #345777;
            line-height: 28px;
            margin-bottom: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .mix-item-title-down {
            margin-right: 32px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #1890FF;
            letter-spacing: 0;
            text-align: left;
            line-height: 24px;
            height: 24px;
            padding-left: 32px;
            background: url("../assets/downloadIcon.png") left center no-repeat;
            background-size: 24px 24px;
            cursor: pointer;
        }

        .mix-item-content {
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.10);
            border-radius: 6px;
            padding: 34px 37px;
        }

        .mix-item-graph-box {
            background: #FFFFFF;
            border: 1px solid #488BFF;
            box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
            border-radius: 0 3px 3px 3px;
            padding: 43px 48px;
        }

        .mix-item-graph-radio {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            letter-spacing: 0;
            text-align: left;
            line-height: 22px;
        }

        .ant-radio-group label {
            margin-right: 60px;
        }

        .mix-item-graph-show {

        }
    }

    //表格样式修改
    .ant-table-body {
        .ant-table-thead {
            th {
                background: #2578E9;
                font-family: PingFangSC-Medium;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 22px;
            }
        }

        .ant-table-tbody > tr > td {
            border-bottom: none;
        }

        .second-row {
            background: #EDF5FF;
            border-radius: 4px;
        }

        .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
            background: #1890FF;
        }
    }

    //检索单一企业
    .company-result {
        width: 100%;
        //顶部菜单栏
        .top-menu {
            width: 100%;
            height: 42px;
            display: flex;
            padding-left: 80px;
            background: #fff;
            box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
            //position: relative;
            position: fixed;
            left: 0;
            top: 60px;
            z-index: 9;
            margin-bottom: 0;

            > li {
                height: 44px;
                line-height: 44px;
                position: relative;
                margin-right: 68px;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #333333;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    transition: all 0.3s;
                    color: #1890FF;
                }

                &.choice-top-menu {
                    transition: all 0.3s;
                    color: #1890FF;

                    &:after {
                        display: block;
                        content: '';
                        width: 100%;
                        height: 2px;
                        background: #1890FF;
                        position: absolute;
                        left: 0;
                        bottom: 2px;
                    }
                }
            }
        }

        //下方内容
        .bottom-content {
            margin-top: 20px;
            width: 100%;
            //height: calc(100vh - 108px);
            //overflow: auto;
            @include scrollStyle;
            padding-top: 15px;

        }

        //左侧菜单栏
        .left-menu {
            width: 220px;
            height: calc(100vh - 105px);
            overflow: auto;
            flex-shrink: 0;
            margin-right: 24px;
            background: #fff;
            padding: 10px 0;
            margin-bottom: 10px;
            position: fixed;
            top: 102px;
            left: 0;
            z-index: 1;

            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                background: transparent;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            .ant-anchor-wrapper {
                padding-bottom: 20px;

                &::-webkit-scrollbar { /*滚动条整体样式*/
                    width: 0; /*高宽分别对应横竖滚动条的尺寸*/
                    height: 0;
                }

                &::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
                    background: transparent;
                }

                &::-webkit-scrollbar-track { /*滚动条里面轨道*/
                    background: transparent;
                }

                /* for Chrome */
                .inner-container::-webkit-scrollbar {
                    display: none;
                }
            }

            .left-menu__ul .menu-item {
                width: 100%;
                height: 39px;
                line-height: 39px;
                padding: 0 50px;
                font-family: PingFangSC-Medium;
                font-size: 14px;
                color: #333;
                cursor: pointer;

                &.ant-anchor-link-active {
                    background-image: linear-gradient(-90deg, #00C8FF 14%, #3383FF 89%);

                    > a {
                        color: #fff;

                    }

                    &:hover {
                        background: #1890FF;
                        transition: all 0.3s;
                    }
                }

                &:hover {
                    background: #ccc;
                    transition: all 0.3s;
                }
            }

            .left-menu__profitInfo—first {
                padding: 0 18px;

                .first-menu-item {
                    > div {
                        width: 100%;
                        height: 22px;
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: #333333;
                        margin-bottom: 30px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                    }
                }

                .second-menu {
                    padding-left: 24px;
                }

                .second-menu-item {
                    > div {
                        width: 100%;
                        height: 22px;
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: #333333;
                        margin-bottom: 30px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                    }
                }

                .third-menu {
                    position: relative;
                    padding-left: 32px;

                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        opacity: 0.5;
                        background: #1890FF;
                        top: 0;
                        left: 32px;
                    }
                }

                .third-menu-item {
                    padding-left: 32px;
                    width: 100%;
                    height: 22px;
                    font-family: PingFangSC-Regular;
                    font-size: 14px;
                    color: #333333;
                    margin-bottom: 30px;
                    cursor: pointer;
                    position: relative;

                    &.profit-choice-menu {
                        &:before {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 2px;
                            height: 100%;
                            display: block;
                            content: '';
                            background: #1890FF;
                        }
                    }
                }

                .profit-choice-menu {
                    color: #1890FF !important;
                }

                .minus-icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 20px;
                    background: url("../../src/assets/minus.png") left center no-repeat;
                }

                .plus-icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 20px;
                    background: url("../../src/assets/plus.png") left center no-repeat;
                }

                .default-box {
                    width: 16px;
                    height: 16px;
                    margin-right: 20px;
                }
            }
        }

        .right-main {
            width: 100%;
            padding: 0 24px 30px 260px;
            //padding-right: 24px;
        }
    }
//}