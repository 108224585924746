@mixin transitionEffect {
    transition: all 0.3s;
}

.result-search__graph{
    /*display: flex;
    min-width: 1160px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 13px;*/
    //padding: 0 10px 17px;
    /*&:after{
        display: block;
        content: '';
        clear: both;
        visibility: hidden;
        height: 0;
    }*/
}
.result-search__graph-content{
    display: flex;
    min-width: 1160px;
    //padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 13px;
}
.result-search__graph__left{
    flex: 1;
    min-width: 722px;
    height: calc(100vh - 135px);
    background: #fff;
    margin-right: 10px;
    position: relative;
    border-radius: 4px;
    @include transitionEffect;

    &.full-screen{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
    }

    .graph-btn{
        position: absolute;
        top: 40px;
        left: 40px;
        z-index: 998;
        margin: 0;
        >li{
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 18px;
            background: #909090;
            color: #fff;
            border-radius: 2px;
            text-align: center;
            cursor: pointer;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            @include transitionEffect;
            &:hover{
                background: #DCE6F2;
                color: #333;
            }
        }
    }
}

.result-search__graph__right{
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    //width: 420px;
    position: relative;
    max-height: calc(100vh - 135px);
    background-color: #fff;
    margin-left: 8px;
    @include transitionEffect;

    .selected-content{
        height: 100%;
        width: 100%;
        opacity: 1;
        background-color: #fff;
        position: relative;
        z-index: 5;
        @include transitionEffect;
    }

    .position-list{
        position: absolute;
        top: 0;
        left: 0;
        width: 420px;
        height: 100%;
    }
    .right-value-loading{
        height: 100%;
        overflow: hidden;
        .ant-spin-container{
            height: 100%;
        }
    }
    .value-info-block{
        height: 100%;
        //position: relative;
    }
    .value-block-close{
        position: absolute;
        display: none;
        top: 10px;
        left: 10px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50px;
        text-align: center;
        background: #dfdfdf;
        color: #333;
        z-index: 99;
        cursor: pointer;
    }
    &:hover{
        .value-block-close{
            display: block;
        }
    }
}
.graph__right-shrink-icon{
    position: absolute;
    top: 50%;
    left: -7px;
    //transform: rotateY(-50%);
    margin-top: -15px;
    width: 14px;
    height: 29px;
    line-height: 29px;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
    z-index: 9;
    @include transitionEffect;
    .font_icon{
        display: flex;
        font-size: 10px;
        height: 29px;
        align-items: center;
        justify-content: center;
    }
    .font_icon.transform{
        transform: rotateZ(180deg);
    }
}
.result-search__graph__right__main{
    background: #fff;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px 10px 30px;

    .pivot-set{
        .second-title{
            margin-top: 5px;
        }
        .scroll-box{
            margin-top: 4px;
            border-radius:4px;
            border:1px solid rgba(237,237,237,1);
        }
        .key-list{
            padding: 10px;
            min-height: 120px;
            max-height: 250px;
            //选择框样式
            .ant-checkbox-group{
                display: flex;
                flex-direction: column;
                .ant-checkbox-wrapper{
                    margin-left: 0;
                    position: relative;
                    width: 370px;
                    &:hover{
                        .key-list-delete{
                            display: block;
                        }
                    }
                }
            }

            .key-list-delete{
                display: none;
                position: absolute;
                height: 28px;
                line-height: 28px;
                top: calc(50% - 14px);
                right: -10px;
                &:hover{
                    color: red;
                }
            }
        }
    }
    .first-title{
        margin: 0;
        color: #333;
        font-size: 16px;
        height: 22px;
        line-height: 22px;
        display: flex;
        justify-content: space-between;
        >button{
            height: 28px;
            display: flex;
            align-items: center;
            font-size: 12px;
            padding: 0 16px;
            border-radius:4px;
            border:1px solid #5A69B0;
            color: #5A69B0;
            .font_icon{
                font-size: 10px;
                margin-left: 5px;
            }
            &:hover{
                background-color: #5A69B0;
                color: #fff;
            }
            &[disabled] {
                color: rgba(0,0,0,0.25);
                background-color: #f5f5f5;
                border-color: #d9d9d9;

            }
        }
    }
    .second-title{
        margin: 0;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
    }
    .table-view{
        margin-top: 24px;
        .graph-table{
            margin-top: 22px;
            min-height: 190px;
        }
    }
}
#root .result-search__graph__right__main .graph-table .ant-table {
    .ant-table-thead{
        >tr{
            >th{
                padding: 0;
                height: 24px;
                line-height: 24px;
                text-align: center;
                background: #DCE6F2;
                border-bottom: none;
                color: #333;
                font-size: 12px;
                &:nth-child(1) {
                    min-width: 86px;
                }
                &:nth-child(2) {
                    min-width: 50px;
                }
                &:nth-child(3) {
                    min-width: 90px;
                }
                &:nth-child(4) {
                    min-width: 90px;
                }
            }
        }
    }
    .ant-table-tbody{
        >tr{
            >td{
                height: 24px;
                line-height: 24px;
                padding: 0;
                color: #333;
                font-size: 12px;
            }
            &:last-child{
                >td {
                    //background: #DCE6F2;
                }
            }
        }
    }
}

.result-search__graph-template{
    width: 100%;
    height: 100%;
}



//12.27 mind图谱样式
.result-search__mind{
    width: 100%;
    height: 100%;
    .jsmind_content{
        width: 100%;
        height: 100%;
    }
}

#mountNode>svg {
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}