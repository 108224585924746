.regulation-fall-table {
    .ant-table-thead {
        tr {
            th:nth-child(3) {
                .ant-table-header-column {
                    display: none;
                }
            }
        }
    }
    .ant-table-tbody {
        tr{
            td {
                .operation {
                    opacity: 0;
                    position: relative;
                    .more-wrapper {
                        position: absolute;
                        right: -127px;
                        top: 2px;
                        padding: 6px 4px;
                        background-color: #FFFFFF;
                        box-shadow: 0px 1px 7px 0px rgba(7,7,9,0.2);
                        border-radius: 2px;
                        border: 1px solid #D1D3D6;
                        z-index: 1;
                        li.more-list {
                            display: flex;
                            position: relative;
                            align-items: center;
                            padding: 4px 5px;
                            border-radius: 1px;
                            cursor: pointer;
                            span {
                                margin-left: 10px;
                                margin-right: 20px;
                            }
                            .arrow {
                                position: absolute;
                                top: 7px;
                                right: 10px;
                            }
                            .download-list {
                                position: absolute;
                                display: none;
                                top: -7px;
                                right: -88px;
                                padding: 3px 4px;
                                background-color: #FFFFFF;
                                box-shadow: 0px 1px 7px 0px rgba(7,7,9,0.2);
                                border-radius: 2px;
                                border: 1px solid #D1D3D6;
                                li {
                                    padding: 4px 40px 4px 6px;
                                    border-radius: 1px;
                                }
                                li:hover {
                                    background-color: #EDEEEE;
                                }
                            }
                        }
                        li.more-list:first-child:hover {
                            .download-list {
                                display: block;
                            }
                        }
                        li.more-list:hover {
                            background: #EDEEEE;
                        }
                    }
                    img {
                        cursor: pointer;
                    }
                }
            }
            &.single-row {
                td{
                    background-color: #F0F4FA !important;
                }
            }
            &:hover {
                td {
                    .operation {
                        opacity: 1;
                    }
                    background-color: #E1E8F3 !important;
                }
            }
            &.row-active {
                td {
                    .operation {
                        opacity: 1;
                    }
                    background-color: #E1E8F3 !important;
                }
            }
        }
    }
}
