.search-listfn {
    background: #fff;
    border-radius: 4px;
    padding: 14px 16px 0;
    margin-bottom: 16px;

    .set-title {
        padding-bottom: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-main {
            margin: 0;
            line-height: 25px;
            font-size: 18px;
            color: #333;
        }

        .title-legend {
            line-height: 20px;
            font-size: 12px;
            color: #909090;
        }

        .title-progress {
            .ant-progress-text {
                font-size: 14px;
                color: #333;
            }
        }
    }
}