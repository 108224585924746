.company-portrait{
    margin-bottom: 34px;
    .portrait-title{
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #345777;
        line-height: 28px;
        height: 28px;
        width: 100%;
        margin-bottom: 15px;
    }
    .portrait-content{
        background: #FFFFFF;
        box-shadow: 0 3px 10px 1px rgba(0,0,0,0.10);
        border-radius: 6px;
        display: flex;
        height: 382px;
    }
    .left-canvas{
        width: 65%;
        min-width: 65%;
        height: 382px;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        .full-screen-icon{
            font-size: 16px;
            z-index: 3;
            color: #fff;
            width: 16px;
            height: 16px;
            position: absolute;
            bottom: 30px;
            right: 30px;
            cursor: pointer;
        }
    }
    .show-full-screen{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999;

        .full-screen-exit{
            font-size: 16px;
            z-index: 3;
            color: #fff;
            width: 16px;
            height: 16px;
            position: absolute;
            bottom: 30px;
            right: 30px;
            cursor: pointer;
        }
    }
    .right-info{
        width: 35%;
        min-width: 35%;
        background: #fff;
    }
    .top-input{
        width: 100%;
        margin-top: 60px;
        .input{
            display: flex;
            margin: 0 auto 30px;
            align-items: center;
            justify-content: center;
            //margin-bottom: 30px;
            >span{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.65);
            }
        }
        .select{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            >span{
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.65);
            }
        }
    }
    .bottom-button{
        width: 100%;
        margin-top: 65px;
        >span{
            display: block;
            background: #1890FF;
            box-shadow: 0 2px 4px 0 rgba(36,53,77,0.20);
            border-radius: 4px;
            margin: 0 auto;
            width: 290px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #FFFFFF;
            letter-spacing: 1px;
            cursor: pointer;
            transition: all .3s;
            &:hover{
                background: #2EA8FF;
            }
        }
    }
    .company-echarts-canvas{
        background: #000;
    }

}