.smart-search-home{
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/smartSearchBg.png");
  background-size: cover;
  background-position: center;
  min-width: 900px;
  min-height: 600px;
  display: flex;
  justify-content: center;
}
.smart-search-logo{
  position: absolute;
  top: 48px;
  left: 72px;
  cursor: pointer;
  .logo-img{
    background-image: url("../../../assets/login-logo.png");
    background-size: 94px 25px;
    width: 94px;
    height: 25px;
    margin-bottom: 3px;
  }
  .logo-text{
    height: 23px;
    font-size:18px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(255,255,255,1);
  }
}
.smart-search-main{
  .search-area{
    .main-title{
      text-align: center;
      font-size:32px;
      font-family:MicrosoftYaHei;
      color:rgba(255,255,255,1);
      line-height:42px;
      margin-top: 172px;
      margin-bottom: 40px;
    }
    .main-input{
      background:rgba(255,255,255,0.72);
      border-radius:13px;
      border:2px solid rgba(255,255,255,1);
      width: 640px;
      //height: 320px;
      padding: 13px 19px 20px;
    }
    .answer-input{
      background: transparent;
      border: none;
      margin-bottom: 4px;
      width: 100%;
      height: 236px;
      font-size:14px;
      font-family:MicrosoftYaHei;
      color:rgba(0,0,0,0.8);
      line-height:19px;
    }
    .input-button{
      display: flex;
      justify-content: center;
    }
    .answer-button{
      display: block;
      text-align: center;
      cursor: pointer;
      width:102px;
      height:38px;
      line-height: 38px;
      background:rgba(226,226,226,1);
      border-radius:6px;
      border:2px solid rgba(255,255,255,1);
      font-size:13px;
      font-family:MicrosoftYaHei;
      color:rgba(0,0,0,0.8);
    }
  }
}