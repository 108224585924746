.title-card-list{
    width: 100%;
    margin-bottom: 34px;
    position: relative;
    .title-card-list-title{
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #345777;
        height: 28px;
        line-height: 28px;
        margin-bottom: 14px;
    }
    .title-list{
        display: flex;
        margin-bottom: 0;
    }
    .card-list-style{
        width: 96px;
        height: 33px;
        line-height: 33px;
        position: relative;
        background: #E9EBED;
        box-shadow: 2px -2px 10px 1px rgba(136,136,136,0.05);
        border-radius: 6px 6px 0 0;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        margin-right: 30px;
        text-align: center;
        cursor: pointer;
    }
    .choice-card-list{
        background: #FFFFFF;
        &:before{
            display: block;
            content: '';
            width: 100%;
            height: 2px;
            background: #1890FF;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .title-card-content{
        background: #FFFFFF;
        box-shadow: 0 3px 10px 1px rgba(0,0,0,0.10);
        border-radius: 0 3px 3px 3px;
        padding: 20px 24px;
    }
    .choice-radio{
        label{
            margin-right: 60px;
        }
        margin-bottom: 30px;
    }
    .show-content{

    }
}