@mixin font-base($color:#333333, $size:16px, $lineHeight:24px, $weight:400) {
    font: {
        size: $size;
        family: SourceHanSansCN-Regular,
            SourceHanSansCN;
        weight: $weight;
    }

    color:$color;
    line-height:$lineHeight;
}

@mixin style-base {
    h3 {
        margin: 0;
        padding: 0;
        @include font-base;
    }
}

@mixin title {
    color: #6D6D6D;
    margin-left: 16px;
    margin-bottom: 16px;
}

@mixin model {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 24px 16px;
    margin-bottom: 32px;
}

@mixin table-base {
    table {
        width: 100%;
        overflow: auto;

        thead {
            th {
                @include font-base($size:14px, $lineHeight:21px, $color:#32323D, $weight:600);
                height: 50px;
                background: #F0F4FA;
                text-align: center;
            }
        }

        tbody {
            td {
                @include font-base($size:14px, $lineHeight:21px, $color:#32323D);
                border-bottom: 1px solid #F1F1F3;
                text-align: center;
                height: 49px;

                &.empty {
                    border: none
                }
            }
        }
    }
}

.enterhistory {
    @include style-base;
    @include font-base;

    .history-baseinfo {
        h3 {
            @include title();
        }

        .history-baseinfo-tb {
            @include model();

            table {
                width: 100%;

                tr {
                    td {
                        border: 1px solid #C8D3FF;
                        height: 40px;
                        width: 305px;
                        padding: 0 5px;

                        &.baseinfo-tb-title {
                            width: 130px;
                            background-color: #F0F4FA;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .history-line {
        h3 {
            @include title();
        }

        .history-line-tb {
            @include model();

            @include table-base();
        }
    }

    .history-other {
        .history-other-box {
            width: calc(50% - 8px);
            min-height: 72px;
            background: #FFFFFF;
            border-radius: 4px;
            padding: 16px;
            display: table;
            margin-bottom: 16px;

            &.box-realcontrol {
                width: 100%;
            }

            &>span {
                display: table-cell;
                min-height: 40px;
                border: 1px solid #C8D3FF;

                &.box-title {
                    color: #6D6D6D;
                    width: 130px;
                    border-right: none;
                    background-color: #F0F4FA;
                    text-align: center;
                    vertical-align: middle;
                }

                &.box-content {
                    width: calc(100% - 130px);
                    padding: 5px;
                    vertical-align: middle;
                }
            }
        }

        .history-other-relative {
            display: flex;
            justify-content: space-between;
        }
    }
}