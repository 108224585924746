.advanced-search-content {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.18);
    border-radius: 2px;
    padding: 20px 15px;
    margin-bottom: 20px;
    margin-top: -10px;
}
.advanced-search-wrapper {
    padding: 15px 0;
    margin-top: -10px;
    background-color: unset;
}
.advanced-search {
    .main-search-filter-item {
        font-size: 16px;
        display: flex;
        .filter-item-label {
            color: #161717;
            width: 82px;
            text-align: right;
            flex-shrink: 0;
            margin-top: 4px;
        }
        .filter-item-list {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            .filter-item-card-more {
                color:#576CB4;
                &::after {
                        content: '';
                        display: inline-block;
                        width: 10px;
                        height: 12px;
                        margin-left: 4px;
                        background: url(../../../assets/svg/moreImg.svg);
                    }
                }
        }
        .filter-item-card {
            color: #606972;
            padding: 0 10px;
            height: 32px;
            line-height: 32px;
            margin-bottom: 14px;
            cursor: pointer;
            border-radius: 4px;
            &:hover {
              background: #EAF0FF;
              color: #576CB4;
            }
            &.selected {
              background: #EAF0FF;
              color: #576CB4;
            }
            &:not(:last-child) {
              margin-right: 10px;
            }
        }
        .filter-item-current-selected {
            background: #EAF0FF;
            color: #576CB4;
            padding: 0 10px;
            height: 32px;
            line-height: 32px;
            margin-bottom: 14px;
            border-radius: 4px;
            position: relative;
            pointer-events:none;
            &:not(:last-child) {
                margin-right: 15px;
            }
            &::after {
                content: '';
                border: 1px solid #DEE0E3;
                border-radius: 50%;
                cursor: pointer;
                top: -5px;
                right: -5px;
                width: 15px;
                height: 15px;
                display: inline-block;
                position: absolute;
                pointer-events:auto;
                background: url(../../../assets/svg/closeImg.svg);
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        .filter-item-clear-selected {
            margin-top: 4px;
            margin-left: 20px;
            color: #576CB4;
            cursor: pointer;
        }
        .del-icon {
            position: relative;
            padding-right: 20px;
            &::after {
              content: 'x';
              position: absolute;
              right: 8px;
              top: -2px;
              font-size: 20px;
              border-radius: 50%;
              display: none;
            }
            &:hover::after {
              display: block;
            }
        }
    }
    .current-selected-wrapper {
        position: relative;
        .pack {
            position: absolute;
            right: 0;
            top: 0;
            color: #576CB4;
            cursor: pointer;
        }
        .pack-up {
            &::after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 12px;
                margin-left: 4px;
                background: url(../../../assets/svg/moreImg.svg);
                transform: rotate(-90deg);
            }
        }
        .pack-down {
            &::after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 12px;
                margin-left: 4px;
                background: url(../../../assets/svg/moreImg.svg);
                transform: rotate(90deg);
            }
        }
    }    
}