.qa-module-index {
  display: flex;
  min-width: 1200px;
  padding-bottom: 10px;
  padding-left: 157px;
  padding-top: 10px;
  &.no-keyword {
    justify-content: center;
    padding-left: 0;
    padding-bottom: 100px;
  }

  .search-leftblock {
    width: 720px;
    margin-right: 20px;
  }

  .search-leftblock-fn {
    &.leftfn-loading {
      height: 70px;
      background-color: #fff;
      margin-bottom: 16px;
    }
  }

  .search-rightblock {
    // width: 420px;
    padding-right: 20px;

    .rightlist-loading {
      background: #fff;
      border-radius: 4px;
      height: 50px;
    }
  }
}
